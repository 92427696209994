import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFetch } from "../hooks";
import { formatNumber } from "../utils";
import { Cover } from "../components";
import { useCallback, useEffect, useState } from "react";
import { getUserCourses } from "../services";
import { AddToCartButton } from "../components/Cart/AddToCartButton";

const Courses = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));
  const [activeCourses, setActiveCourses] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const { docs: courses, isLoading } = useFetch("courses");

  const isBuyed = useCallback((id) => userCourses.includes(id), [userCourses]);

  useEffect(() => {
    const getCoursesFromUser = async () => {
      try {
        const coursesFromDB = await getUserCourses(user.id);
        setUserCourses(coursesFromDB);
      } catch (err) {
        console.log(err.message);
      }
    };

    if (user?.id) getCoursesFromUser();
  }, [user?.id]);

  useEffect(() => {
    if (courses) {
      const filteredCourses = courses.filter(
        (course) =>
          isBuyed(course.id) || (course.isActive && !course.isFromMembership)
      );

      setActiveCourses(
        filteredCourses.sort(
          (a, b) => Number(a?.position) - Number(b?.position)
        )
      );
    }
  }, [courses, isBuyed]);

  return (
    <Container fluid id="courses">
      <Cover text="Cursos" />
      <Row className="mt-5">
        <Col md={{ span: 10, offset: 1 }}>
          <h3 className="text-center text-purple-l fw-bold mt-2 fs-2">
            Cursos
          </h3>
          <Row xs={1} md={3}>
            {!isLoading && (
              <>
                {activeCourses.map((course) => (
                  <Col
                    md={4}
                    key={course.id}
                    className="d-flex align-items-stretch justify-content-center"
                  >
                    <Card className="border-0 rounded-4 overflow-hidden my-5 mx-0 w-100">
                      <Link to={`/cursos/${course?.customUrl}`}>
                        <Card.Img
                          variant="top"
                          src={course.cover}
                          className="rounded-0 course-image"
                        />
                      </Link>
                      <Card.Body>
                        <Card.Title className="text-purple-l fw-bold fs-4 text-capitalize text-center">
                          <Link
                            to={`/cursos/${course?.customUrl}`}
                            className="text-purple-l text-decoration-none"
                          >
                            {course.name}
                          </Link>
                        </Card.Title>
                      </Card.Body>
                      <Card.Footer className="border-0 d-flex justify-content-center overflow-hidden fs-5 px-3 pb-3 flex-wrap">
                        {!isBuyed(course.id) && (
                          <div className="d-flex flex-wrap mb-3 px-3">
                            {Number(course.discount) > 0 ? (
                              <>
                                <p className="fs-5 mb-0 align-self-center text-decoration-line-through mx-2 text-red">
                                  {formatNumber(course.price)}
                                </p>
                                <p className="text-purple-l fw-bold mt-auto mb-auto">
                                  {formatNumber(
                                    (Number(course.price) *
                                      (100 - Number(course.discount))) /
                                      100
                                  )}{" "}
                                  USD
                                </p>
                              </>
                            ) : (
                              <p className="text-purple-l fw-bold mt-auto mb-auto">
                                {formatNumber(course.price)} USD
                              </p>
                            )}
                          </div>
                        )}
                        {isBuyed(course.id) ? (
                          <Link
                            to={`/cursos/${course?.customUrl}`}
                            className={`btn rounded-pill purple-gradient border-0 text-white fw-bold px-5 pointer text-decoration-none py-2 m-auto`}
                          >
                            Ver Curso
                          </Link>
                        ) : (
                          <div className="m-auto">
                            <AddToCartButton item={course} />
                          </div>
                        )}
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </Col>
      </Row>
      {/* <Row className="mb-5 d-flex justify-content-center">
        <Button className="rounded-pill bg-purple-l border-0 text-white fw-bold px-5 pointer w-auto">
          Ver Más
        </Button>
      </Row> */}
    </Container>
  );
};

export { Courses };
