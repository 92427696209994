import { Col, Container, Row } from "react-bootstrap";

const TermsContent = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 11, offset: 1 }} className="text-content">
          <h3 className="mb-1 fw-bold">Términos y Condiciones</h3>
          <h5>Leenit Borges Arte</h5>
          <p>
            Agradecemos tu visita a nuestra tienda en línea de LEENITH BORGES
            ARTE hacer cualquier compra en LEENITH BORGES ARTE, estás aceptando
            los siguientes términos y condiciones.
          </p>
          <p>
            Al visitar www.leenithborges-arte.com quedas sujeto(a) a los
            lineamientos, derechos y restricciones aquí mencionadas. lEENITH
            BORGES ARTE está representado por una Persona Física debidamente
            establecida y registrada ante las normas del SAT y del comercio del
            estado. (en adelante "LEENITH BORGES ARTE") se reserva el derecho de
            modificar el presente documento en el momento y bajo las
            circunstancias que más le convengan, siendo estos cambios vigentes
            en el momento mismo de su publicación.
          </p>
          <p>
            Aceptación de términos legales: con el objetivo de ofrecer un
            ambiente seguro y agradable para nuestros clientes y visitantes,
            hemos establecido reglas y términos bajo los cuales se delimitan las
            áreas de responsabilidad y derechos, tanto de LEENITH BORGES ARTE
            como de nuestros visitantes. Si vas a realizar una compra a través
            de www.leenithborges-arte.com es de suma importancia conocer la
            siguiente información.
          </p>
          <h4>Formas de Pago</h4>
          <p>
            www.leenithborges-arte.com y/o LEENITH BORGES ARTE, se reserva el
            derecho de solicitar documentos oficiales a sus clientes, como medio
            de validación al proceso de adquisición de productos a través de su
            sitio en Internet.
          </p>
          <p>
            El número de orden que se asigna al realizar la transacción en el
            sitio de Internet de LEENITH BORGES ARTE no implica la aceptación de
            la transacción. En caso de tener algún problema con tu orden te será
            comunicado por correo electrónico o vía telefónica.
          </p>
          <h4>Disponibilidad de Mercancías y Existencias</h4>
          <p>
            Toda nuestra mercancía está sujeta a existencias, por lo que el
            tiempo de entrega puede variar previo aviso de nuestra parte, o
            incluso podrá proceder la cancelación de la orden y la devolución de
            sus cargos en caso de no ser un producto resurtible.
          </p>
          <h4>Precios</h4>
          <p>
            Los precios publicados en el sitio de Internet de lEENITH BORGES
            ARTE son exclusivos para este canal y pueden cambiar sin previo
            aviso. Si por alguna razón el precio se encuentra en $ 0.00 ó $
            0.01, te pedimos que nos llames por teléfono al número (844) 355
            1833 ó nos envíes un correo contacto@leenithborges-arte.com para
            darte el precio correcto del artículo.
          </p>
          <p>
            Por ninguna razón se entenderá que éstos no tengan precio o se
            regalen. Todos los pedidos que se realicen bajo esta situación serán
            cancelados sin previo aviso.
          </p>
          <h4>Especificaciones de los Productos</h4>
          <p>
            Las características y especificaciones de los productos pueden
            cambiar sin previo aviso. Y la fotografía puede no corresponder a
            éstas, sino ser de un producto similar representado de manera
            ilustrativa.
          </p>
          <h4>Envío de los Productos</h4>
          <p>
            El tiempo de entrega inicia a partir de la confirmación del pago por
            parte del banco, el cual te será notificado por correo electrónico
            en un periodo máximo de 24 horas. Al momento de la entrega en tu
            domicilio, le será solicitada a la persona que reciba la mercancía
            una copia de la identificación oficial (credencial de elector,
            pasaporte, forma migratoria FM2) para comprobar la identidad de la
            persona que recibe.
          </p>
          <p>
            LEENITH BORGES ARTE, a través de Fedex se compromete a entregarte la
            mercancía en el tiempo establecido y en función a la programación
            disponible y la accesibilidad del destino. En circunstancias
            especiales, LEENITH BORGES ARTE realizará la entrega a través de
            compañías transportistas y los tiempos de entrega pueden variar, por
            lo que será recomendable revisar las políticas de envío o contactar
            al personal de venta para solicitar información más concreta.
          </p>
          <p>
            Al momento de la recepción del producto, se te entregará, junto con
            la mercancía, una hoja de salida de almacén y una guía. La factura
            será entregada vía correo electrónico a la dirección electrónica que
            nos hayas brindado.
          </p>
          <h4>Empaque</h4>
          <p>
            Toda la mercancía será empacada sin cargo para el cliente, en caso
            de realizar pedidos especiales o de un volumen mayor se cotiza por
            separado el envio y empaque de los productos. Al igual que en envíos
            internacionales, se tiene que realizar una cotización de envío por
            separado de acuerdo al país al que se envíe.
          </p>
          <h4>Restricciones</h4>
          <p>
            Si tienes alguna duda o deseas cancelar una compra que aún no hayas
            recibido, te agradeceremos que nos llames al número (844) 355 1833.
            También estamos a tu disposición en contacto@leenithborges-arte.com.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsContent;
export { TermsContent };
