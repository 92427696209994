import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Login, SignIn } from "../components";

const Auth = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const { search } = useLocation();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) navigate("/");
    });

    if (new URLSearchParams(search).get("redirect")) setIsLogin(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLogin ? (
    <Login setIsLogin={setIsLogin} />
  ) : (
    <SignIn setIsLogin={setIsLogin} />
  );
};

export { Auth };
