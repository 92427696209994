import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { CustomToast, Loader } from "../components";
import { Header, Navbar } from "../components/Admin";
import { isAdmin } from "../utils";
import { AppContext } from "../store/AppContext";
import { useToastConfig } from "../hooks";

const Admin = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const config = {
    toastData: useToastConfig(),
  };

  useEffect(() => {
    if (!isAdmin) navigate("/");
    setIsLoading(false);
  }, [navigate]);

  if (isLoading) return <Loader />;

  return (
    <AppContext.Provider value={config}>
      <Container fluid id="admin">
        <Row className="position-relative">
          <Navbar />
          <Col md={10} className="px-0">
            <Header />
            <Outlet />
          </Col>
        </Row>
        <CustomToast />
      </Container>
    </AppContext.Provider>
  );
};

export default Admin;
export { Admin };
