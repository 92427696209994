import { useState } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import { TiArrowLeft, TiDelete } from "react-icons/ti";
import { Link } from "react-router-dom";
import { Cover } from "../components";
import { formatNumber } from "../utils";
import { useCart } from "../hooks";

const Cart = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));
  const { items, removeItem } = useCart();

  const getTotal = () => {
    return items.reduce((acc, item) => {
      return acc + (Number(item.price) * (100 - Number(item.discount))) / 100;
    }, 0);
  };

  return (
    <Container fluid id="cart">
      <Cover text="Carrito" />
      {items.length ? (
        <Row>
          <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
            <Table>
              <thead>
                <tr>
                  <th>Producto:</th>
                  <th>Nombre:</th>
                  <th>Cantidad:</th>
                  <th>Precio:</th>
                  <th>Descuento:</th>
                  <th>Costo Total:</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <img
                        src={item.cover}
                        className="img-fluid"
                        width={80}
                        height={50}
                        alt=""
                      />
                    </td>
                    <td className="py-5 text-capitalize">{item.name}</td>
                    <td className="py-5">1</td>
                    <td className="py-5">{formatNumber(item.price)}</td>
                    <td className="py-5">{item.discount} %</td>
                    <td className="py-5">
                      {formatNumber(
                        (Number(item.price) * (100 - Number(item.discount))) /
                          100
                      )}
                    </td>
                    <td className="py-5">
                      <TiDelete
                        size={20}
                        className="text-red ms-2 pointer"
                        onClick={() => removeItem(item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <p className="text-end px-5 py-4 fw-bold border-bottom w-100">
              Costo Total: {formatNumber(getTotal())}
            </p>
          </Col>
        </Row>
      ) : (
        <Row className="py-5 d-flex align-items-center">
          <p className="py-5 text-center fs-5 fw-bold">
            Aun no tienes nada en tu carrito. <br />
            <Link to="/cursos">
              Explora nuestra tienda y encuentra el curso que buscas.
            </Link>
          </p>
        </Row>
      )}
      <Row>
        <Col
          lg={{ span: 10, offset: 1 }}
          xl={{ span: 8, offset: 2 }}
          className="d-flex justify-content-between mt-3 mb-5"
        >
          <Link to="/cursos">
            <Button className="bg-purple-m border-0 pe-4 rounded-pill">
              <TiArrowLeft size={24} />
              Volver a la tienda
            </Button>
          </Link>
          {user ? (
            <>
              {!!items.length && (
                <Link to="/pago">
                  <Button variant="info" className="border-0 px-5 rounded-pill">
                    Pagar
                  </Button>
                </Link>
              )}
            </>
          ) : (
            <div className="d-flex align-items-end flex-column">
              <Link to="/auth">
                <Button variant="info" className="border-0 px-5 rounded-pill">
                  Iniciar Sesión
                </Button>
              </Link>
              <p className="text-purple-m mt-3">
                Para adquirir un curso Inicia Sesión o Crea una cuenta
              </p>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Cart;
export { Cart };
