import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const InProcess = () => {
  const navigate = useNavigate();

  return (
    <Container fluid id="checkout">
      <Row className="bg-gray">
        <Col
          md={{ span: 4, offset: 2 }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <h3 className="text-purple-l">
            Tu pago aun no se ha recibido, una vez que realices tu pago, revisa
            el status de tu pedido en tu perfil en la sección de pedidos.
          </h3>
          <Button
            variant="info"
            className="border-0 px-5 rounded-pill my-5"
            onClick={() => navigate("/perfil?pedidos=true")}
          >
            Ver Perfil
          </Button>
        </Col>
        <Col>
          <img src="./carousel-1.jpg" className="img-fluid" alt="success-pay" />
        </Col>
      </Row>
    </Container>
  );
};

export default InProcess;
export { InProcess };
