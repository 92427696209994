import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../components";
import {
  addDocInCollection,
  getDocFromCollection,
  updateDocInCollection,
  uploadImage,
} from "../services";
import { AppContext } from "../store/AppContext";
import { VideoNumber } from "../components/Challenge/VideoNumber";

const ChallengeForm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState("general");
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const { toastData } = useContext(AppContext);
  const { showToast } = toastData;
  const [challenge, setChallenge] = useState({
    name: "",
    author: "",
    duration: "",
    position: "",
    customUrl: "",
    discount: "",
    price: "",
    isLive: false,
    description: "",
    summary: "",
    cover: "",
    videoId: "",
    vimeoVideoId: "",
    vimeoVideoId2: "",
    vimeoVideoId3: "",
    vimeoVideoAppId: "",
    vimeoVideoAppId2: "",
    vimeoVideoAppId3: "",
    facebookLink: "",
    // hotmartUrl: "",
    // productId: "",
    requirements: "",
    material: "",
    language: "",
    level: "",
    lifeTime: true,
    isActive: true,
  });

  useEffect(() => {
    if (search) {
      const id = new URLSearchParams(search).get("id");

      getDocFromCollection("challenges", id)
        .then((doc) => {
          setIsEdit(true);
          setChallenge(doc);
        })
        .catch((err) => console.log(err));
    }
    setIsLoading(false);
  }, [search]);

  const handleCoverImgFile = async () => {
    try {
      // Save cover image and get url
      if (challenge.cover && typeof challenge.cover !== "string") {
        const url = await uploadImage(challenge.cover);
        challenge.cover = url;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const validateGeneral = () => {
    const {
      name,
      isLive,
      author,
      customUrl,
      duration,
      // discount,
      price,
      description,
      summary,
      requirements,
      cover,
      videoId,
      vimeoVideoId,
      vimeoVideoAppId,
      // hotmartUrl,
      // productId,
    } = challenge;

    if (!isLive && (!summary || !requirements)) {
      return false;
    }
    if (
      name &&
      author &&
      customUrl &&
      duration &&
      // discount &&
      price &&
      description &&
      cover &&
      videoId &&
      vimeoVideoId &&
      vimeoVideoAppId
      // hotmartUrl &&
      // productId
    ) {
      return true;
    }

    return false;
  };

  const handleChallenge = async () => {
    setIsLoading(true);

    try {
      const isValid = validateGeneral();

      if (!isValid) {
        showToast();
        return;
      }

      await handleCoverImgFile();

      if (challenge.isLive) {
        delete challenge.summary;
        delete challenge.material;
        delete challenge.requirements;
        delete challenge.facebookLink;
      }

      isEdit
        ? await updateDocInCollection("challenges", challenge.id, challenge)
        : await addDocInCollection("challenges", challenge);

      navigate("/admin/retos");
    } catch (error) {
      console.log(error, "error handling the challenge");
    } finally {
      setIsLoading(false);
      console.log(challenge);
    }
  };

  if (isLoading) return <Loader />;

  const challengeRequired = !challenge.isLive ? (
    <span className="text-danger">*</span>
  ) : (
    ""
  );

  return (
    <Container fluid className="my-4" id="challengeForm">
      <Row className="py-2 mx-5">
        <p className="mb-3 fs-3 text-purple-m fw-bold">
          {isEdit ? "Editar" : "Crear"} {challenge.isLive ? "Live" : "Reto"}
        </p>
        <Tabs
          id="challenge-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="fw-bold"
        >
          <Tab eventKey="general" title="1. Información General" disabled>
            <Row>
              <Col md={{ span: 4 }}>
                <div className="mt-4">
                  <label className="form-label fw-bold" htmlFor="isLive">
                    Tipo
                  </label>
                  <Form.Select
                    aria-label="Selecciona el tipo"
                    className="form-control rounded-pill"
                    id="isLive"
                    name="isLive"
                    value={challenge?.isLive ? "1" : "0"}
                    onChange={({ target }) => {
                      console.log(target.value);
                      setChallenge({
                        ...challenge,
                        isLive: Number(target.value) ? true : false,
                      });
                    }}
                  >
                    <option value="0">Reto</option>
                    <option value="1">Live</option>
                  </Form.Select>
                </div>
                <div className="mt-4">
                  <label className="form-label fw-bold" htmlFor="name">
                    Nombre<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control rounded-pill"
                    name="name"
                    value={challenge.name}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, name: target.value })
                    }
                    placeholder={`Escribe el nombre del ${
                      challenge.isLive ? "live" : "reto"
                    }`}
                  />
                </div>
                <div className=" mt-4">
                  <label className="form-label fw-bold" htmlFor="customUrl">
                    Url personalizada<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customUrl"
                    className="form-control rounded-pill"
                    name="customUrl"
                    value={challenge.customUrl}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, customUrl: target.value })
                    }
                    placeholder="url-ejemplo"
                  />
                </div>
                <div className=" mt-4">
                  <label className="form-label fw-bold" htmlFor="author">
                    Quien lo imparte?<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="author"
                    className="form-control rounded-pill"
                    name="author"
                    value={challenge.author}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, author: target.value })
                    }
                    placeholder="Escribe el nombre del tutor"
                  />
                </div>
                <div className="mt-4">
                  <label className="form-label fw-bold" htmlFor="position">
                    Posición en la lista
                  </label>
                  <input
                    type="number"
                    id="position"
                    className="form-control rounded-pill"
                    name="position"
                    value={challenge.position}
                    onChange={({ target }) =>
                      setChallenge({
                        ...challenge,
                        position: parseFloat(target.value),
                      })
                    }
                    placeholder="Posición en la lista"
                  />
                </div>
                <div className="d-flex gap-2  mt-4">
                  <div className="w-50">
                    <label className="form-label fw-bold" htmlFor="duration">
                      Duración<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="duration"
                      className="form-control rounded-pill"
                      name="duration"
                      value={challenge.duration}
                      onChange={({ target }) =>
                        setChallenge({
                          ...challenge,
                          duration: parseFloat(target.value),
                        })
                      }
                      placeholder="Duración en minutos"
                    />
                  </div>
                  <div className="w-50">
                    <label className="form-label fw-bold" htmlFor="price">
                      Precio<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="price"
                      className="form-control rounded-pill"
                      name="price"
                      value={challenge.price}
                      onChange={({ target }) =>
                        setChallenge({
                          ...challenge,
                          price: parseFloat(target.value),
                        })
                      }
                      placeholder={`Precio del ${
                        challenge.isLive ? "live" : "reto"
                      }`}
                    />
                  </div>
                </div>
                <div className=" mt-4">
                  <label className="form-label fw-bold" htmlFor="discount">
                    Descuento <small>(10, 20, 30)</small>
                  </label>
                  <input
                    type="number"
                    id="discount"
                    className="form-control rounded-pill"
                    name="discount"
                    value={challenge.discount}
                    onChange={({ target }) =>
                      setChallenge({
                        ...challenge,
                        discount: Number(target.value || 0),
                      })
                    }
                    placeholder={`Descuento para el ${
                      challenge.isLive ? "live" : "reto"
                    }`}
                  />
                </div>
                <div className="mt-4">
                  <label className="form-label fw-bold" htmlFor="language">
                    Idioma
                  </label>
                  <input
                    type="text"
                    id="language"
                    className="form-control rounded-pill"
                    name="language"
                    value={challenge.language}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, language: target.value })
                    }
                    placeholder={`Escribe el idioma de tu ${
                      challenge.isLive ? "live" : "reto"
                    }`}
                  />
                </div>
                <div className="mt-4">
                  <label className="form-label fw-bold" htmlFor="level">
                    Nivel
                  </label>
                  <input
                    type="text"
                    id="level"
                    className="form-control rounded-pill"
                    name="level"
                    value={challenge.level}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, level: target.value })
                    }
                    placeholder={`Escribe el nivel de tu ${
                      challenge.isLive ? "live" : "reto"
                    }`}
                  />
                </div>
                <div className=" mt-4">
                  <label className="form-label fw-bold" htmlFor="description">
                    Descripción<span className="text-danger">*</span>
                  </label>
                  <textarea
                    id="description"
                    className="form-control rounded-4"
                    name="description"
                    rows={4}
                    value={challenge.description}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, description: target.value })
                    }
                    placeholder={`Descripcion del ${
                      challenge.isLive ? "live" : "reto"
                    }`}
                  />
                </div>
                {!!!challenge.isLive && (
                  <>
                    <div className=" mt-4">
                      <label className="form-label fw-bold" htmlFor="summary">
                        Resumen{challengeRequired}
                      </label>
                      <textarea
                        id="summary"
                        className="form-control rounded-4"
                        name="summary"
                        rows={4}
                        value={challenge?.summary}
                        onChange={({ target }) =>
                          setChallenge({ ...challenge, summary: target.value })
                        }
                        placeholder="Resumen del reto"
                      />
                    </div>
                    <div className=" mt-4">
                      <label
                        className="form-label fw-bold"
                        htmlFor="requirements"
                      >
                        Materiales{challengeRequired}
                      </label>
                      <textarea
                        id="requirements"
                        className="form-control rounded-4"
                        name="requirements"
                        rows={4}
                        value={challenge?.requirements}
                        onChange={({ target }) =>
                          setChallenge({
                            ...challenge,
                            requirements: target.value,
                          })
                        }
                        placeholder="Materiales del reto"
                      />
                    </div>
                  </>
                )}
              </Col>
              <Col md={{ span: 5, offset: 2 }}>
                {/* <div className="mt-4 w-75">
                  <label className="form-label fw-bold" htmlFor="productId">
                    Id del Producto (Hotmart)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="productId"
                    className="form-control rounded-pill"
                    name="productId"
                    value={challenge.productId}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, productId: target.value })
                    }
                    placeholder="2208753"
                  />
                </div> */}
                {/* <div className="mt-4 w-75">
                  <label className="form-label fw-bold" htmlFor="hotmartUrl">
                    Link de Pago (Hotmart)<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="hotmartUrl"
                    className="form-control rounded-pill"
                    name="hotmartUrl"
                    value={challenge.hotmartUrl}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, hotmartUrl: target.value })
                    }
                    placeholder="https://pay.hotmart.com/C81308152B?off=rzcs8u6e"
                  />
                </div> */}
                {!!!challenge.isLive && (
                  <div className="mt-4 w-75">
                    <label
                      className="form-label fw-bold"
                      htmlFor="facebookLink"
                    >
                      Facebook (Link)
                    </label>
                    <input
                      type="text"
                      id="facebookLink"
                      className="form-control rounded-pill"
                      name="facebookLink"
                      value={challenge.facebookLink}
                      onChange={({ target }) =>
                        setChallenge({
                          ...challenge,
                          facebookLink: target.value,
                        })
                      }
                      placeholder="https://t.me/+xlUZHAV5cw9jY2Qx"
                    />
                  </div>
                )}
                <div className="mt-4 w-75">
                  <label className="form-label fw-bold" htmlFor="video-id">
                    Video Introductorio (YouTube id)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="video-id"
                    className="form-control rounded-pill"
                    name="videoId"
                    value={challenge.videoId}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, videoId: target.value })
                    }
                    placeholder="QRO8vCJJxAo"
                  />
                </div>
                <div className="mt-4 w-75">
                  <label
                    className="form-label fw-bold"
                    htmlFor="vimeo-video-id"
                  >
                    Video del {challenge.isLive ? "Live" : "Reto"} (Vimeo id)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="video-id"
                    className="form-control rounded-pill placeholder-low-opacity"
                    name="vimeoVideoId"
                    value={challenge.vimeoVideoId}
                    onChange={({ target }) =>
                      setChallenge({ ...challenge, vimeoVideoId: target.value })
                    }
                    placeholder="410670337?h=921f6f989f"
                  />
                </div>
                <div className="mt-4 w-75">
                  <label
                    className="form-label fw-bold"
                    htmlFor="vimeo-video-app-id"
                  >
                    Video del {challenge.isLive ? "Live" : "Reto"} para App
                    Movil (Vimeo id)<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="video-app-id"
                    className="form-control rounded-pill placeholder-low-opacity"
                    name="vimeoVideoAppId"
                    value={challenge.vimeoVideoAppId}
                    onChange={({ target }) =>
                      setChallenge({
                        ...challenge,
                        vimeoVideoAppId: target.value,
                      })
                    }
                    placeholder="410670337?h=921f6f989f"
                  />
                </div>
                <div className="mt-4 w-75">
                  <p className="fw-bold">
                    Imagen de Portada (400px x 300px)
                    <span className="text-danger">*</span>
                  </p>
                  {isEdit && (
                    <img
                      src={challenge.cover}
                      className="img-fluid mb-4"
                      alt="cover"
                      width={100}
                      height={40}
                    />
                  )}
                  <input
                    type="file"
                    id="cover-img"
                    name="cover"
                    onChange={(e) => {
                      setChallenge({ ...challenge, cover: e.target.files[0] });
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
                {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => {
                  return (
                    <VideoNumber
                      challenge={challenge}
                      setChallenge={setChallenge}
                      number={number}
                      key={number}
                    />
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 2, offset: 7 }}>
                <Button
                  className="bg-purple-m rounded-pill fw-bold px-3 border-none my-5"
                  onClick={handleChallenge}
                >
                  {isEdit ? "Editar" : "Crear"}{" "}
                  {challenge.isLive ? "Live" : "Reto"}
                </Button>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Row>
    </Container>
  );
};

export default ChallengeForm;
export { ChallengeForm };
