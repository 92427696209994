import { createBrowserRouter } from "react-router-dom";
import {
  About,
  Admin,
  App,
  Auth,
  Cart,
  CheckOut,
  Challenge,
  Challenges,
  Contact,
  Course,
  CourseForm,
  Courses,
  CoursesList,
  Dashboard,
  ErrorPage,
  Faq,
  Home,
  MemberInfo,
  MemberList,
  // MembershipList,
  Memberships,
  Profile,
  Terms,
  ChallengeList,
  ChallengeForm,
  Sales,
  InProcess,
} from "../containers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/auth",
        element: <Auth />,
      },
      {
        path: "/contacto",
        element: <Contact />,
      },
      {
        path: "/cursos",
        element: <Courses />,
      },
      {
        path: "/retos",
        element: <Challenges />,
      },
      {
        path: "/sobre-mi",
        element: <About />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/suscripciones",
        element: <Memberships />,
      },
      {
        path: "/terminos-condiciones",
        element: <Terms />,
      },
      {
        path: "/cursos/:name",
        element: <Course />,
      },
      {
        path: "/retos/:name",
        element: <Challenge />,
      },
      {
        path: "/perfil",
        element: <Profile />,
      },
      {
        path: "/carrito",
        element: <Cart />,
      },
      {
        path: "/pago",
        element: <CheckOut />,
      },
      {
        path: "/pago-en-proceso",
        element: <InProcess />,
      },
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "cursos",
        element: <CoursesList />,
      },
      {
        path: "cursos/curso",
        element: <CourseForm />,
      },
      {
        path: "retos",
        element: <ChallengeList />,
      },
      {
        path: "retos/reto",
        element: <ChallengeForm />,
      },
      {
        path: "miembros",
        element: <MemberList />,
      },
      {
        path: "miembros/:id",
        element: <MemberInfo />,
      },
      {
        path: "ventas",
        element: <Sales />,
      },
      // {
      //   path: "suscripciones",
      //   element: <MembershipList />,
      // },
    ],
  },
]);

export default router;
