import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Sections, Showcase, Testimonials } from "../components/Admin";
import { Loader } from "../components";
import { TiArrowLeft, TiArrowRight } from "react-icons/ti";
import {
  addDocInCollection,
  getDocFromCollection,
  updateDocInCollection,
  uploadImage,
} from "../services";

const CourseForm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState("general");
  const [isLoading, setIsLoading] = useState(true);
  const [showShowcaseForm, setShowShowcaseForm] = useState(false);
  const [showTestimonialForm, setShowTestimonialForm] = useState(false);
  const [showSectionForm, setShowSectionForm] = useState(false);
  const [showChapterForm, setShowChapterForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [course, setCourse] = useState({
    name: "",
    author: "",
    position: "",
    customUrl: "",
    isFromMembership: false,
    duration: "",
    discount: "",
    price: "",
    description: "",
    summary: "",
    cover: "",
    videoId: "",
    telegramLink: "",
    // hotmartUrl: "",
    // productId: "",
    materialesLink: "",
    testimonials: [],
    sections: [],
    showcase: [],
    requirements: [],
    language: "",
    level: "",
    lessons: "",
    lifeTime: true,
    isActive: true,
    benefit1: "",
    benefit2: "",
    benefit3: "",
    benefit4: "",
  });

  const [newExample, setNewExample] = useState({ name: "", image: "" });
  const [newTestimonial, setNewTestimonial] = useState({ user: "", image: "" });
  const [newSection, setNewSection] = useState({ name: "", chapters: [] });
  const [sectionIdToEdit, setSectionIdToEdit] = useState();
  const [chapterIdToEdit, setChapterIdToEdit] = useState();
  const [newChapter, setNewChapter] = useState({
    name: "",
    duration: "",
    videoId: "",
    videoAppId: "",
  });

  useEffect(() => {
    if (search) {
      const id = new URLSearchParams(search).get("id");

      getDocFromCollection("courses", id)
        .then((doc) => {
          setIsEdit(true);
          setCourse(doc);
        })
        .catch((err) => console.log(err));
    }
    setIsLoading(false);
  }, [search]);

  const handleCoverImgFile = async () => {
    try {
      // Save cover image and get url
      if (course.cover && typeof course.cover !== "string") {
        const url = await uploadImage(course.cover);
        course.cover = url;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleShowcaseFiles = async () => {
    try {
      // Save images from showcase and get urls
      for (let i = 0; i < course.showcase.length; i++) {
        const el = course.showcase[i];

        if (el.image && typeof el.image !== "string") {
          const url = await uploadImage(el.image);
          el.image = url;
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleTestimonialsFiles = async () => {
    try {
      // Save images from testimonials and get urls
      for (let i = 0; i < course.testimonials.length; i++) {
        const el = course.testimonials[i];

        if (el.image && typeof el.image !== "string") {
          const url = await uploadImage(el.image);
          el.image = url;
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  // const handleMaterialFiles = async () => {
  //   try {
  //     // Save pdfs from material and get urls
  //     if (course.material.pdf && course.material.pdf.length) {
  //       for (let i = 0; i < course.material.pdf.length; i++) {
  //         const el = course.material.pdf[i];

  //         if (typeof el !== "string") {
  //           const url = await uploadDoc(el);
  //           course.material.pdf[i] = url;
  //         }
  //       }
  //     }

  //     // Save images from material and get urls
  //     if (course.material.images && course.material.images.length) {
  //       for (let i = 0; i < course.material.images.length; i++) {
  //         const el = course.material.images[i];

  //         if (typeof el !== "string") {
  //           const url = await uploadImage(el);
  //           course.material.images[i] = url;
  //         }
  //       }
  //     }

  //     // Save audio from material and get urls
  //     if (course.material.audio && course.material.audio.length) {
  //       for (let i = 0; i < course.material.audio.length; i++) {
  //         const el = course.material.audio[i];

  //         if (typeof el !== "string") {
  //           const url = await uploadDoc(el);
  //           course.material.audio[i] = url;
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     throw new Error(error);
  //   }
  // };

  const handleCourse = async () => {
    setIsLoading(true);

    try {
      await handleCoverImgFile();
      await handleShowcaseFiles();

      if (!course?.isFromMembership) {
        await handleTestimonialsFiles();
      }
      // await handleMaterialFiles();

      isEdit
        ? await updateDocInCollection("courses", course.id, course)
        : await addDocInCollection("courses", course);

      navigate("/admin/cursos");
    } catch (error) {
      console.log(error, "error handling the course");
    } finally {
      setIsLoading(false);
    }
  };

  const addExample = () => {
    setCourse({
      ...course,
      showcase: [...course.showcase, { ...newExample }],
    });

    setShowShowcaseForm(false);

    setNewExample({
      name: "",
      image: "",
    });
  };

  const editExample = (index) => {
    setNewExample({ ...course.showcase[index] });

    setCourse({
      ...course,
      showcase: course.showcase.filter((item, i) => i !== index),
    });
  };

  const removeExample = (index) => {
    setCourse({
      ...course,
      showcase: course.showcase.filter((item, i) => i !== index),
    });
  };

  const addTestimonial = () => {
    setCourse({
      ...course,
      testimonials: [...course.testimonials, { ...newTestimonial }],
    });

    setShowTestimonialForm(false);

    setNewTestimonial({
      user: "",
      image: "",
    });
  };

  const editTestimonial = (index) => {
    setNewTestimonial({ ...course.testimonials[index] });

    setCourse({
      ...course,
      testimonials: course.testimonials.filter((item, i) => i !== index),
    });
  };

  const removeTestimonial = (index) => {
    setCourse({
      ...course,
      testimonials: course.testimonials.filter((item, i) => i !== index),
    });
  };

  const addChapter = () => {
    setNewSection({
      ...newSection,
      chapters: [...newSection.chapters, { ...newChapter }],
    });

    setShowChapterForm(false);

    setNewChapter({
      name: "",
      duration: "",
      videoId: "",
      videoAppId: "",
    });
  };

  const editChapter = (index) => {
    setNewChapter(newSection.chapters[index]);
    setChapterIdToEdit(index);
  };

  const updateChapter = () => {
    newSection.chapters.forEach((chapter, i) => {
      if (i === chapterIdToEdit) newSection.chapters[i] = { ...newChapter };
    });

    setShowChapterForm(false);

    setNewChapter({
      name: "",
      duration: "",
      videoId: "",
      videoAppId: "",
    });
  };

  const removeChapter = (index) => {
    setNewSection({
      ...newSection,
      chapters: newSection.chapters.filter((item, i) => i !== index),
    });
  };

  const addSection = () => {
    setCourse({ ...course, sections: [...course.sections, newSection] });
    setShowSectionForm(false);
    setNewSection({ name: "", chapters: [] });
  };

  const editSection = (index) => {
    setNewSection(course.sections[index]);
    setShowSectionForm(true);
    setSectionIdToEdit(index);
  };

  const updateSection = () => {
    course.sections.forEach((section, i) => {
      if (i === sectionIdToEdit) course.sections[i] = { ...newSection };
    });

    setShowSectionForm(false);
    setNewSection({ name: "", chapters: [] });

    setShowChapterForm(false);
    setNewChapter({
      name: "",
      duration: "",
      videoId: "",
      videoAppId: "",
    });
  };

  const removeSection = (index) => {
    setCourse({
      ...course,
      sections: course.sections.filter((item, i) => i !== index),
    });
  };

  const validateGeneralCourse = () => {
    const {
      name,
      author,
      customUrl,
      duration,
      discount,
      price,
      description,
      summary,
      cover,
      videoId,
      testimonials,
      showcase,
      isFromMembership,
      // hotmartUrl,
      // productId,
    } = course;

    if (isFromMembership) {
      if (
        name &&
        author &&
        customUrl &&
        description &&
        summary &&
        cover &&
        videoId &&
        price &&
        showcase.length
      ) {
        return false;
      }
    } else {
      if (
        name &&
        author &&
        customUrl &&
        description &&
        summary &&
        cover &&
        videoId &&
        // hotmartUrl &&
        // productId &&
        duration &&
        discount &&
        price &&
        testimonials.length &&
        showcase.length
      ) {
        return false;
      }
    }

    return true;
  };

  return (
    <Container fluid className="my-4" id="courseForm">
      <Row className="py-2 mx-5">
        <p className="mb-3 fs-3 text-purple-m fw-bold">
          {isEdit ? "Editar" : "Crear"} Curso
        </p>
        <Tabs
          id="course-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="fw-bold"
        >
          <Tab eventKey="general" title="1. Información General" disabled>
            <Row>
              <Col md={{ span: 4 }}>
                <div className="mt-4">
                  <label className="form-label fw-bold" htmlFor="name">
                    Nombre del curso
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control rounded-pill"
                    name="name"
                    value={course.name}
                    onChange={({ target }) =>
                      setCourse({ ...course, name: target.value })
                    }
                    placeholder="Escribe el nombre de tu curso"
                  />
                </div>
                <div className=" mt-4">
                  <label className="form-label fw-bold" htmlFor="customUrl">
                    Url personalizada
                  </label>
                  <input
                    type="text"
                    id="customUrl"
                    className="form-control rounded-pill"
                    name="customUrl"
                    value={course.customUrl}
                    onChange={({ target }) =>
                      setCourse({ ...course, customUrl: target.value })
                    }
                    placeholder="url-ejemplo"
                  />
                </div>
                <div className="mt-4">
                  <label
                    className="form-label fw-bold"
                    htmlFor="isFromMembership"
                  >
                    Es para suscripciones?
                  </label>
                  <input
                    type="checkbox"
                    id="isFromMembership"
                    className="form-check-input mx-8"
                    name="isFromMembership"
                    checked={course.isFromMembership}
                    onChange={({ target }) =>
                      setCourse({ ...course, isFromMembership: target.checked })
                    }
                  />
                </div>
                <div className="mt-4">
                  <label className="form-label fw-bold" htmlFor="author">
                    Quien lo imparte?
                  </label>
                  <input
                    type="text"
                    id="author"
                    className="form-control rounded-pill"
                    name="author"
                    value={course.author}
                    onChange={({ target }) =>
                      setCourse({ ...course, author: target.value })
                    }
                    placeholder="Escribe el nombre del tutor"
                  />
                </div>
                <div className="mt-4">
                  <label className="form-label fw-bold" htmlFor="position">
                    Posición en la lista
                  </label>
                  <input
                    type="number"
                    id="position"
                    className="form-control rounded-pill"
                    name="position"
                    value={course.position}
                    onChange={({ target }) =>
                      setCourse({
                        ...course,
                        position: parseFloat(target.value),
                      })
                    }
                    placeholder="Posición en la lista"
                  />
                </div>
                <div className="d-flex gap-2  mt-4">
                  {!course?.isFromMembership && (
                    <div className="w-50">
                      <label className="form-label fw-bold" htmlFor="duration">
                        Duración del curso
                      </label>
                      <input
                        type="number"
                        id="duration"
                        className="form-control rounded-pill"
                        name="duration"
                        value={course.duration}
                        onChange={({ target }) =>
                          setCourse({
                            ...course,
                            duration: parseFloat(target.value),
                          })
                        }
                        placeholder="Duración en minutos"
                      />
                    </div>
                  )}
                  <div className="w-50">
                    <label className="form-label fw-bold" htmlFor="price">
                      Precio
                    </label>
                    <input
                      type="number"
                      id="price"
                      className="form-control rounded-pill"
                      name="price"
                      value={course.price}
                      onChange={({ target }) =>
                        setCourse({
                          ...course,
                          price: parseFloat(target.value),
                        })
                      }
                      placeholder="Precio del curso"
                    />
                  </div>
                </div>
                {!course?.isFromMembership && (
                  <>
                    <div className=" mt-4">
                      <label className="form-label fw-bold" htmlFor="discount">
                        Descuento <small>(10, 20, 30)</small>
                      </label>
                      <input
                        type="number"
                        id="discount"
                        className="form-control rounded-pill"
                        name="discount"
                        value={course.discount}
                        onChange={({ target }) =>
                          setCourse({
                            ...course,
                            discount: parseFloat(target.value),
                          })
                        }
                        placeholder="Descuento para el curso"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label fw-bold" htmlFor="lessons">
                        Número de lecciones
                      </label>
                      <input
                        type="text"
                        id="lessons"
                        className="form-control rounded-pill"
                        name="lessons"
                        value={course.lessons}
                        onChange={({ target }) =>
                          setCourse({ ...course, lessons: target.value })
                        }
                        placeholder="Escribe el número de lecciones de tu curso"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label fw-bold" htmlFor="language">
                        Idioma del curso
                      </label>
                      <input
                        type="text"
                        id="language"
                        className="form-control rounded-pill"
                        name="language"
                        value={course.language}
                        onChange={({ target }) =>
                          setCourse({ ...course, language: target.value })
                        }
                        placeholder="Escribe el idioma de tu curso"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label fw-bold" htmlFor="level">
                        Nivel del curso
                      </label>
                      <input
                        type="text"
                        id="level"
                        className="form-control rounded-pill"
                        name="level"
                        value={course.level}
                        onChange={({ target }) =>
                          setCourse({ ...course, level: target.value })
                        }
                        placeholder="Escribe el nivel de tu curso"
                      />
                    </div>
                  </>
                )}
                <div className=" mt-4">
                  <label className="form-label fw-bold" htmlFor="summary">
                    Resumen
                  </label>
                  <textarea
                    id="summary"
                    className="form-control rounded-4"
                    name="summary"
                    rows={5}
                    value={course.summary}
                    onChange={({ target }) =>
                      setCourse({ ...course, summary: target.value })
                    }
                    placeholder="Resumen de tu curso"
                  />
                </div>
                <div className=" mt-4">
                  <label className="form-label fw-bold" htmlFor="description">
                    Descripción
                  </label>
                  <textarea
                    id="description"
                    className="form-control rounded-4"
                    name="description"
                    rows={4}
                    value={course.description}
                    onChange={({ target }) =>
                      setCourse({ ...course, description: target.value })
                    }
                    placeholder="Descripción del curso"
                  />
                </div>
                {!course?.isFromMembership && (
                  <>
                    <p className="text-purple-m mt-4 fw-bold fs-5">
                      Beneficios Extras
                    </p>
                    <div className="mt-4">
                      <label className="form-label fw-bold" htmlFor="benefit1">
                        Beneficio 1 (Opcional)
                      </label>
                      <input
                        type="text"
                        id="benefit1"
                        className="form-control rounded-pill"
                        name="benefit1"
                        value={course.benefit1}
                        onChange={({ target }) =>
                          setCourse({ ...course, benefit1: target.value })
                        }
                        placeholder="Escribe un beneficio extra de tu curso"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label fw-bold" htmlFor="benefit2">
                        Beneficio 2 (Opcional)
                      </label>
                      <input
                        type="text"
                        id="benefit2"
                        className="form-control rounded-pill"
                        name="benefit2"
                        value={course.benefit2}
                        onChange={({ target }) =>
                          setCourse({ ...course, benefit2: target.value })
                        }
                        placeholder="Escribe un beneficio extra de tu curso"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label fw-bold" htmlFor="benefit3">
                        Beneficio 3 (Opcional)
                      </label>
                      <input
                        type="text"
                        id="benefit3"
                        className="form-control rounded-pill"
                        name="benefit3"
                        value={course.benefit3}
                        onChange={({ target }) =>
                          setCourse({ ...course, benefit3: target.value })
                        }
                        placeholder="Escribe un beneficio extra de tu curso"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label fw-bold" htmlFor="benefit4">
                        Beneficio 4 (Opcional)
                      </label>
                      <input
                        type="text"
                        id="benefit4"
                        className="form-control rounded-pill"
                        name="benefit4"
                        value={course.benefit4}
                        onChange={({ target }) =>
                          setCourse({ ...course, benefit4: target.value })
                        }
                        placeholder="Escribe un beneficio extra de tu curso"
                      />
                    </div>
                  </>
                )}
              </Col>
              <Col md={{ span: 5, offset: 2 }}>
                {/* <div className="mt-4 w-75">
                  <label className="form-label fw-bold" htmlFor="productId">
                    Id del Producto (Hotmart)
                  </label>
                  <input
                    type="text"
                    id="productId"
                    className="form-control rounded-pill"
                    name="productId"
                    value={course.productId}
                    onChange={({ target }) =>
                      setCourse({ ...course, productId: target.value })
                    }
                    placeholder="2208753"
                  />
                </div> */}
                {/* <div className="mt-4 w-75">
                  <label className="form-label fw-bold" htmlFor="hotmartUrl">
                    Link de Pago (Hotmart)
                  </label>
                  <input
                    type="text"
                    id="hotmartUrl"
                    className="form-control rounded-pill"
                    name="hotmartUrl"
                    value={course.hotmartUrl}
                    onChange={({ target }) =>
                      setCourse({ ...course, hotmartUrl: target.value })
                    }
                    placeholder="https://pay.hotmart.com/C81308152B?off=rzcs8u6e"
                  />
                </div> */}
                {!course?.isFromMembership && (
                  <div className="mt-4 w-75">
                    <label
                      className="form-label fw-bold"
                      htmlFor="telegramLink"
                    >
                      Telegram (Link)
                    </label>
                    <input
                      type="text"
                      id="telegramLink"
                      className="form-control rounded-pill"
                      name="telegramLink"
                      value={course.telegramLink}
                      onChange={({ target }) =>
                        setCourse({ ...course, telegramLink: target.value })
                      }
                      placeholder="https://t.me/+xlUZHAV5cw9jY2Qx"
                    />
                  </div>
                )}
                <div className="mt-4 w-75">
                  <label
                    className="form-label fw-bold"
                    htmlFor="materialesLink"
                  >
                    Materiales (Link)
                  </label>
                  <input
                    type="text"
                    id="materialesLink"
                    className="form-control rounded-pill"
                    name="materialesLink"
                    value={course.materialesLink}
                    onChange={({ target }) =>
                      setCourse({ ...course, materialesLink: target.value })
                    }
                    placeholder="ACTUALIZAR LINK PLACEHOLDER"
                  />
                </div>
                <div className="mt-4 w-75">
                  <label className="form-label fw-bold" htmlFor="video-id">
                    Video Introductorio (YouTube id)
                  </label>
                  <input
                    type="text"
                    id="video-id"
                    className="form-control rounded-pill"
                    name="videoId"
                    value={course.videoId}
                    onChange={({ target }) =>
                      setCourse({ ...course, videoId: target.value })
                    }
                    placeholder="QRO8vCJJxAo"
                  />
                </div>
                <div className="mt-4 w-75">
                  <p className="fw-bold">Imagen de Portada (400px x 300px)</p>
                  {isEdit && (
                    <img
                      src={course.cover}
                      className="img-fluid mb-4"
                      alt="cover"
                      width={100}
                      height={40}
                    />
                  )}
                  <input
                    type="file"
                    id="cover-img"
                    name="cover"
                    onChange={(e) => {
                      setCourse({ ...course, cover: e.target.files[0] });
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
                <Showcase
                  showShowcaseForm={showShowcaseForm}
                  setShowShowcaseForm={setShowShowcaseForm}
                  showcase={course.showcase}
                  newExample={newExample}
                  setNewExample={setNewExample}
                  addExample={addExample}
                  editExample={editExample}
                  removeExample={removeExample}
                />
                {!course?.isFromMembership && (
                  <Testimonials
                    showShowcaseForm={showShowcaseForm}
                    testimonials={course.testimonials}
                    newTestimonial={newTestimonial}
                    setNewTestimonial={setNewTestimonial}
                    showTestimonialForm={showTestimonialForm}
                    setShowTestimonialForm={setShowTestimonialForm}
                    addTestimonial={addTestimonial}
                    editTestimonial={editTestimonial}
                    removeTestimonial={removeTestimonial}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 2, offset: 9 }}>
                <Button
                  className="bg-purple-m rounded-pill fw-bold px-3 border-none my-5"
                  disabled={validateGeneralCourse()}
                  onClick={() => setKey("contenido")}
                >
                  Siguiente <TiArrowRight size={32} />
                </Button>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="contenido" title="2. Contenido" disabled>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Sections
                  showSectionForm={showSectionForm}
                  setShowSectionForm={setShowSectionForm}
                  newSection={newSection}
                  setNewSection={setNewSection}
                  showChapterForm={showChapterForm}
                  setShowChapterForm={setShowChapterForm}
                  newChapter={newChapter}
                  setNewChapter={setNewChapter}
                  addChapter={addChapter}
                  editChapter={editChapter}
                  updateChapter={updateChapter}
                  removeChapter={removeChapter}
                  addSection={addSection}
                  editSection={editSection}
                  updateSection={updateSection}
                  removeSection={removeSection}
                  sections={course.sections}
                />
                <Row>
                  <Col md={{ span: 2, offset: 1 }}>
                    <Button
                      className="bg-purple-m rounded-pill fw-bold pe-4 border-none my-5"
                      onClick={() => setKey("general")}
                    >
                      <TiArrowLeft size={32} />
                      Anterior
                    </Button>
                  </Col>
                  <Col md={{ span: 2, offset: 7 }}>
                    {/* <Button
                  className="bg-purple-m rounded-pill fw-bold px-3 border-none my-5"
                  disabled={!course?.sections?.length}
                  onClick={() => setKey("material")}
                >
                  Siguiente
                </Button> */}
                    <Button
                      className="bg-purple-m rounded-pill fw-bold px-3 border-none my-5"
                      onClick={handleCourse}
                    >
                      {isEdit ? "Editar" : "Crear"} Curso
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Tab>
          {/* <Tab eventKey="material" title="3. Material Dinámico" disabled>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Row>
                  <Col>
                    <div className="w-75 mt-4">
                      <p className="fw-bold mb-0 fs-5 text-purple-m">PDF</p>
                      <input
                        type="file"
                        accept=".pdf"
                        multiple
                        onChange={(e) => {
                          setCourse({
                            ...course,
                            material: {
                              ...course.material,
                              pdf: [...e.target.files],
                            },
                          });
                        }}
                      />
                      <div className="mt-4 w-50">
                        {course.material?.pdf?.map((doc, i) => (
                          <div key={i}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <TiDocument size={32} />
                                <p className="text-center">{i + 1}</p>
                              </div>
                              <span>
                                <TiDelete
                                  size={20}
                                  className="text-red ms-2 pointer"
                                  onClick={() => {
                                    setCourse({
                                      ...course,
                                      material: {
                                        ...course.material,
                                        pdf: course.material.pdf.filter(
                                          (item, index) => i !== index
                                        ),
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="w-75 mt-4">
                      <p className="fw-bold mb-0 fs-5 text-purple-m">
                        Imágenes
                      </p>
                      <input
                        type="file"
                        accept="image/png, image/jpg"
                        multiple
                        onChange={(e) => {
                          setCourse({
                            ...course,
                            material: {
                              ...course.material,
                              images: [...e.target.files],
                            },
                          });
                        }}
                      />
                      <div className="mt-4 w-50">
                        {course.material?.images?.map((image, i) => (
                          <div key={i}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <TiImage size={32} />
                                <p className="text-center">{i + 1}</p>
                              </div>
                              <span>
                                <TiDelete
                                  size={20}
                                  className="text-red ms-2 pointer"
                                  onClick={() => {
                                    setCourse({
                                      ...course,
                                      material: {
                                        ...course.material,
                                        images: course.material.images.filter(
                                          (item, index) => i !== index
                                        ),
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="w-75 mt-4">
                      <p className="fw-bold mb-0 fs-5 text-purple-m">Audios</p>
                      <input
                        type="file"
                        accept="audio/*"
                        multiple
                        onChange={(e) => {
                          setCourse({
                            ...course,
                            material: {
                              ...course.material,
                              audio: [...e.target.files],
                            },
                          });
                        }}
                      />
                      <div className="mt-4 w-50">
                        {course.material?.audio?.map((audio, i) => (
                          <div key={i}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <TiMediaPlay size={32} />
                                <p className="text-center">{i + 1}</p>
                              </div>
                              <span>
                                <TiDelete
                                  size={20}
                                  className="text-red ms-2 pointer"
                                  onClick={() => {
                                    setCourse({
                                      ...course,
                                      material: {
                                        ...course.material,
                                        audio: course.material.audio.filter(
                                          (item, index) => i !== index
                                        ),
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-5">
                  <Col md={{ span: 1, offset: 1 }}>
                    <Button
                      className="bg-purple-m rounded-pill fw-bold px-3 border-none my-5"
                      onClick={() => setKey("contenido")}
                    >
                      Anterior
                    </Button>
                  </Col>
                  <Col md={{ span: 2, offset: 7 }}>
                    <Button
                      className="bg-purple-m rounded-pill fw-bold px-3 border-none my-5"
                      onClick={handleCourse}
                    >
                      {isEdit ? "Editar" : "Crear"} Curso
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Tab> */}
        </Tabs>
      </Row>
    </Container>
  );
};

export default CourseForm;
export { CourseForm };
