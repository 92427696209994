import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Cover } from "../components/Cover";

const sections = [
  {
    title: "Mi carrera",
    eventKey: "career",
  },
  {
    title: "Mi Historia",
    eventKey: "history",
  },
  {
    title: "Mis próximos pasos",
    eventKey: "next-steps",
  },
  {
    title: "Notas sobre mi",
    eventKey: "sobre-mi",
  },
];

const About = () => {
  return (
    <Container fluid id="about">
      <Cover text="Sobre mi" image="/banner-sobre-mi.png" />
      <Row>
        <Col className="bg-gray rounded-5 px-4" md={{ span: 5, offset: 1 }}>
          <img
            src="./logo-dorado.png"
            className="img-fluid"
            alt="logo dorado"
          />
          <h3 className="mb-3 fw-bold">Pintora Profesional</h3>
          <p>
            Maestra de Arte en técnicas modernas y novedosas de Pintura. Ayudo a
            las personas a descubrir y potenciar su lado artístico a través de
            clases en línea, presenciales y asesorías personalizadas. Mi
            especialidad es el Arte Moderno, he desarrollado mi estilo
            particular diversificando y transformado técnicas clásicas llevadas
            a la actualidad de una manera elegante.
          </p>
          <p>
            Para conseguirlo me he apoyado en la innovación constante
            invirtiendo muchas horas cada semana en mi estudio probando
            materiales, mezclándolos entre ellos a través del ensayo y error
            hasta conseguir resultados Espectaculares.
          </p>
          <p>
            Liderando a mi equipo de trabajo que aportan un valor incalculable a
            la visión y para que esto pueda suceder día con día.
          </p>
        </Col>
        <Col className="rounded-5 px-4" md={5}>
          <img src="/photo-1.png" alt="leenith" className="img-fluid" />
        </Col>
      </Row>
      <Tab.Container id="left-tabs-container" defaultActiveKey="career">
        <Row className="my-5">
          <Col md={12}>
            <Nav className="justify-content-center gap-4">
              {sections.map((section) => (
                <Nav.Item key={section.eventKey}>
                  <Nav.Link
                    eventKey={section.eventKey}
                    className="text-black bg-white nav-link rounded-pill"
                  >
                    {section.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col className="px-0 pb-5" xs={12}>
            <Tab.Content>
              <Tab.Pane eventKey="career">
                <Row className="my-5 px-3 mx-0">
                  <Col xs={12} md={8} xl={{ span: 6, offset: 1 }}>
                    <img
                      src="/photo-2.jpg"
                      id="career-picture"
                      alt="leenith"
                      className="img-fluid float-start pe-3 pb-3 rounded-5"
                    />
                    <h3 className="fw-bold" id="career-title">
                      Un poco de mi carrera
                    </h3>
                    <p>
                      Soy Pintora Profesional y Profesora de Arte, y a pesar de
                      que me estoy graduada de Ingeniera Industrial, mi título
                      luce muy bien guardado pues nunca ejercí ni un minuto de
                      esa profesión, la Pasión por la Pintura es lo que me
                      impulsa día con día.
                    </p>
                    <p>
                      Con esos proyectos me compré mi carro sin préstamos y
                      junto con mi esposo (novio en ese entonces) pudimos pagar
                      nuestra Boda, todo esto con sólo 23 años.En el 2016 dejé
                      mi zona de confort (familia, amigos y Negocio) y me vine a
                      México junto con mi esposo, donde residimos actualmente,
                      impregnándome de la cultura y el arte Mexicano, esto me
                      ayudo en mi carrera profesional para desarrollarme y
                      enriquecer aún más mis técnicas de pintura.
                    </p>
                  </Col>
                  <Col xs={12} md={4} xl={4} id="career-secondary">
                    <p>
                      Luego de varios años impartiendo mi conocimiento me pasé a
                      crear cursos online y hoy cuento con más de 10 cursos, en
                      donde enseño mis técnicas desde “0” sin reserva, sin
                      esconder nada y sobre todo explicando detalladamente cada
                      una de ellas.
                    </p>
                    <p>
                      Aprendí a pintar de forma auto-didacta desde que tengo uso
                      de razón y me frustraba al ver que muchos Artistas con
                      técnicas espectaculares de Pintura y muchos años de
                      trayectoria no compartían sus conocimientos y eran muy
                      celosos con ellos (Lo cual ahora entiendo y no los Juzgo),
                      pero yo tenía una visión diferente quería enseñar sin
                      recelo todo lo que había aprendido desde niña, por eso
                      empecé a impartir clases en mi ciudad desde el 2013 para
                      ayudar a otros artistas o apasionados por el arte con las
                      técnicas que había desarrollado hasta ese entonces.
                    </p>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="history">
                <Row className="my-5">
                  <Col
                    xs={12}
                    xl={{ span: 10, offset: 1 }}
                    className="bg-gray rounded-5 p-5"
                  >
                    <h3 className="fw-bold" id="history-title">
                      Mi historia
                    </h3>
                    <img
                      src="/photo-3.png"
                      id="history-picture"
                      alt="leenith"
                      className="img-fluid float-end ps-3 pb-3 rounded-5"
                    />
                    <p>
                      Nací el 28 de Enero del 1990, en Venezuela y mi padres
                      decidieron llamarme como mi mama (Leenith).
                    </p>
                    <p>
                      Mi historia con el arte inicio siendo muy pequeña, jugando
                      con los materiales del estudio de mi mama y dañando unos
                      cuantos pinceles antes de aprender a usarlos
                      correctamente, pinte toda mi vida y siempre lo disfrute
                      muchísimo, ayudaba en el negocio familiar (una tienda de
                      arte y manualidades) también me ofrecía en cada proyecto
                      que implicara usar mi creatividad incluso sin paga alguna
                      solo por el placer de hacerlo. En el año 2012 decidí que
                      era momento de dedicarme tiempo completo a crear mi propio
                      estilo y a emprender mi sueño de vivir de lo que amo.
                    </p>
                    <p>
                      Desde ese momento no he parado, pinto a diario y sigo
                      encontrando más maneras de ver mis ideas en un lienzo, me
                      enamore de este mundo de tal forma que decidí compartirlo
                      con otros y así tiempo después con mucho trabajo,
                      compromiso y ganas de dar lo mejor de mí funde Leenith
                      Borges Academia un lugar que primero existió en mis sueños
                      y que hoy es una gran familia de gente que ha encontrado
                      en la pintura un placer, un oficio o un hobbies. Hoy puedo
                      decir que soy el resultado de creer apasionadamente en
                      vivir de lo que más amo hacer y que siempre habrá más por
                      alcanzar, mas por dar a otros y más por que agradecer a
                      Dios.
                    </p>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="next-steps">
                <Row className="my-5 px-3">
                  <Col xs={12} md={{ span: 10, offset: 1 }}>
                    <h3 className="fw-bold" id="career-title">
                      Mis próximos pasos
                    </h3>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }} className="my-4">
                    <p>
                      No descarto estudiar una Maestría en Arte e Historia del
                      Arte. Voy a seguir innovando dentro de la industria y por
                      eso a finales del 2021 espero lanzar un nuevo proyecto que
                      llevamos concibiendo hace meses del cual quiero darte un
                      pequeño adelanto “Emprender para Artistas”.
                    </p>
                    <p>
                      Esta es mi historia sincera, cercana y real. Muchos dicen
                      que tuve suerte, pero en realidad tengo mucho sueño y he
                      trabajado muy duro para conseguir cada éxito de mi carrera
                      profesional y personal.
                    </p>
                  </Col>
                  <Col md={5} className="my-4">
                    <p>
                      He tardado varios años para ver mejores resultados,
                      cambiar muchas noches de diversión por noches de trabajo,
                      cambiar viajes de placer por ahorros y caprichos por
                      inversiones tecnológicas. Seguir soñando en grande.
                    </p>
                    <p>
                      Gracias a esas decisiones hoy puedo decir que estoy en el
                      camino de vivir la vida que siempre quise. Acciones que
                      fueron sacrificios en años anteriores y hoy me reafirman
                      que con pasión, dedicación y paciencia todo es posible. Mi
                      trabajo es y seguirá siendo mi hobby preferido y nunca me
                      cansaré de aprender, compartir y ayudar a otros.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <img
                    src="/photo-4.jpg"
                    alt="leenith"
                    className="img-fluid px-0"
                  />
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="sobre-mi">
                <Row className="my-5 bg-gray rounded-5 p-4 mx-5">
                  <Col
                    xs={12}
                    lg={6}
                    className="rounded-5 py-2 d-flex justify-content-center"
                  >
                    <img
                      src="/about-heraldo.gif"
                      id="about-heraldo"
                      alt="leenith"
                      className="img-fluid rounded-5"
                    />
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className="py-2 d-flex flex-column justify-content-around"
                  >
                    <div>
                      <h3 className="fw-bold" id="career-title">
                        EL HERALDO SALTILLO
                      </h3>
                      <p>
                        “Sé que es hacer lo que no me gusta, como me pasó con mi
                        carrera universitaria, me di cuenta que no era lo mío,
                        por eso abrí mi academia, donde fluyo, me relajo, me
                        cambia el estado de ánimo, es donde puedo sacar mi
                        personalidad, creo que por eso mis alumnos les gusta
                        regresar, porque aquí buscamos que ellos también lo
                        hagan”
                      </p>
                    </div>
                    <div>
                      <a
                        href="https://www.elheraldodesaltillo.mx/2021/02/05/el-arte-de-pintar-un-espacio-para-conectar-con-uno-mismo/"
                        target={"_blank"}
                        className="nav-link active bg-white rounded-pill px-3 py-2 d-inline"
                        rel="noreferrer"
                      >
                        Seguir Leyendo
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row className="my-5 bg-gray rounded-5 p-4 mx-5">
                  <Col
                    xs={12}
                    lg={6}
                    className="rounded-5 py-2 d-flex justify-content-center"
                  >
                    <img
                      src="/about-diario.gif"
                      id="about-diario"
                      alt="leenith"
                      className="img-fluid rounded-5"
                    />
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className="py-2 d-flex flex-column justify-content-around"
                  >
                    <div>
                      <h3 className="fw-bold" id="career-title">
                        EL DIARIO DE COAHUILA
                      </h3>
                      <p>
                        “Desde siempre me ha gustado la cultura de México, es un
                        país rico en tradiciones. Aquí en Saltillo, he
                        continuado mi preparación en diplomados que se imparten
                        en la Universidad Autónoma de Coahuila”.
                      </p>
                    </div>
                    <div>
                      <a
                        href="https://eldiariodecoahuila.com.mx/2020/11/09/leenith-borges-y-su-amor-por-la-pintura/"
                        target={"_blank"}
                        className="nav-link active bg-white rounded-pill px-3 py-2 d-inline"
                        rel="noreferrer"
                      >
                        Seguir Leyendo
                      </a>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export { About };
