import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Loader } from "../components";
import { useFetch } from "../hooks";
import { formatNumber } from "../utils";
import { TiTrash } from "react-icons/ti";
import { deleteDocInCollection } from "../services";

const Sales = () => {
  const { docs: orders, isLoading } = useFetch("orders");
  const [ordersToDisplay, setOrdersToDisplay] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOrdersToDisplay(
      orders.sort(
        (objA, objB) => Number(objB.createdAt) - Number(objA.createdAt)
      )
    );
  }, [orders]);

  const handleSearch = ({ target }) => {
    const { value } = target;

    const ordersFiltered = orders.filter(
      (order) =>
        order?.product?.name?.toLowerCase().includes(value.toLowerCase()) ||
        order?.type?.includes(value) ||
        order?.status?.includes(value) ||
        order?.user?.email?.toLowerCase().includes(value.toLowerCase())
    );

    setOrdersToDisplay(
      ordersFiltered.sort(
        (objA, objB) => Number(objB.createdAt) - Number(objA.createdAt)
      )
    );
  };

  const handleDeleteOrder = async (id) => {
    setLoading(true);

    try {
      await deleteDocInCollection("orders", id);
      setOrdersToDisplay(ordersToDisplay.filter((o) => o.id !== id));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading || loading) return <Loader />;

  return (
    <Container fluid>
      <Row className="mt-5 mb-3">
        <Col md={{ span: 4, offset: 7 }} lg={{ span: 3, offset: 8 }}>
          <div className="d-flex align-items-end justify-content-end">
            <label className="form-label fw-bold me-3" htmlFor="search">
              Buscar:
            </label>
            <input
              type="text"
              id="search"
              className="form-control rounded-pill"
              name="search"
              onChange={handleSearch}
              placeholder="Buscar por producto, tipo o status"
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={{ span: 10, offset: 1 }} className="">
          <p className="bg-purple-m text-white py-3 ps-4 mb-0 fw-bold">
            Ventas
          </p>
          <Table striped className="border border-green">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Fecha</th>
                <th>Venta</th>
                <th>Tipo</th>
                <th>Miembro</th>
                <th>Status</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                ordersToDisplay.map((order) => (
                  <tr key={order.id} className="py-4 align-middle">
                    <td className="text-capitalize">{order?.product?.name}</td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td className="text-capitalize">
                      {formatNumber(order?.product?.price)}
                    </td>
                    <td className="text-capitalize">{order.type}</td>
                    <td>{order?.user?.email}</td>
                    <td>
                      {order.status === "cancelled" ? (
                        <span className="text-danger">Cancelado</span>
                      ) : order.status === "pending" ? (
                        <span className="text-warning">Verificando</span>
                      ) : (
                        <span className="text-success">Confirmado</span>
                      )}
                    </td>
                    <td>
                      {order.status !== "confirmed" && (
                        <TiTrash
                          size={32}
                          className="text-danger pointer"
                          onClick={() => handleDeleteOrder(order.id)}
                        />
                      )}
                    </td>
                    {/* <td>
                    <Link to={`/admin/miembros/${order.id}`}>
                      <Button
                        className="py-1 px-5 rounded-pill"
                        variant="secondary"
                      >
                        Ver Miembro
                      </Button>
                    </Link>
                  </td> */}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
export default Sales;
export { Sales };
