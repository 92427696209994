import { useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import { AppContext } from "../store/AppContext";

function CustomToast() {
  const { toastData } = useContext(AppContext);
  const {
    show,
    setShow,
    mode = "danger",
    message = "Oh no algo salió mal, intentalo de nuevo!",
  } = toastData;

  if (!show) return null;

  return (
    <Row id="custom-toast" className="z-1">
      <Col xs={6}>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          bg={mode}
          autohide
        >
          <Toast.Body className="text-white">{message}</Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}

export default CustomToast;
export { CustomToast };
