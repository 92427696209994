import { useContext, useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../store/AppContext";
import { addDocInCollection } from "../services";
import { Col, Container, Row } from "react-bootstrap";

const SignIn = ({ setIsLogin }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState("");
  const { toastData } = useContext(AppContext);
  const { showToast } = toastData;

  const handleNameField = ({ target }) => {
    const name = target.value;

    setName(name);
  };

  const handleEmailField = ({ target }) => {
    const email = target.value;

    setEmail(email);
  };

  const handlePasswordField = ({ target }) => {
    const pass = target.value;

    setPassword(pass);
  };

  const handleTermsField = ({ target }) => {
    const checked = target.checked;

    setTerms(checked);
  };

  const registerUser = async (e) => {
    e.preventDefault();
    // Validate not empty info and terms checked
    if (!name || !email || !password || !terms) {
      return setError("Todos los campos son requeridos");
    }

    // Clean last error
    setError("");

    try {
      // Register User in Firebase
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const document = {
        name,
        email,
        phone: "",
        isAdmin: false,
        createdAt: Date.now(),
        userId: user.uid,
        courses: [],
      };

      const id = await addDocInCollection("users", document);

      localStorage.setItem("userDetails", JSON.stringify({ id, ...document }));

      // await getHotmartToken();

      showToast("success", "Registro exitoso");
      navigate("/"); // redirect user to Home
    } catch (error) {
      showToast();
      const errorMessage = error.message;

      if (errorMessage.includes("invalid-email"))
        return setError("Email invalido");
      if (errorMessage.includes("weak-password")) {
        return setError("Contraseña insegura (mínimo 6 caracteres)");
      }

      setError("Algo salió mal, intentalo de nuevo.");
    }
  };

  return (
    <Container id="signin">
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 0 }}>
          <h1 className="fs-4 text-purple-l fw-bold mb-4">
            Si deseas comprar cursos es necesario contar con una cuenta en la
            plataforma, por favor crear una cuenta aquí abajo:
          </h1>
          <form>
            <div>
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="name"
              >
                Nombre Completo
              </label>
              <input
                type="text"
                id="name"
                className="form-control rounded-pill"
                name="name"
                placeholder="Escribe tu nombre"
                onChange={handleNameField}
              />
            </div>
            <div className="my-3">
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="form-control rounded-pill"
                name="email"
                onChange={handleEmailField}
                placeholder="Escribe tu email"
              />
            </div>
            <div className="my-3">
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="password"
              >
                Contraseña
              </label>
              <input
                type="password"
                id="password"
                className="form-control rounded-pill"
                name="password"
                onChange={(e) => handlePasswordField(e)}
                placeholder="Escribe tu contraseña"
              />
            </div>
            <div className="form-check mt-4 mb-3">
              <input
                className="form-check-input rounded-circle"
                type="checkbox"
                value={terms}
                onChange={handleTermsField}
                id="terms"
                name="terms"
              />
              <label className="form-check-label" htmlFor="terms">
                Acepto los Terminos Y Condiciones
              </label>
            </div>

            {error && <p className="text-red">* {error}</p>}

            <button
              className="btn btn-large bg-purple-m text-white rounded-pill px-5 mt-1 mb-3"
              onClick={registerUser}
            >
              Crear
            </button>
          </form>
          <p>
            Ya tienes una cuenta?{" "}
            <span
              className="text-blue pointer"
              onClick={() => setIsLogin(true)}
            >
              Inicia sesion
            </span>
          </p>
        </Col>

        <Col
          xs={12}
          md={{ span: 10, offset: 1 }}
          lg={{ span: 7, offset: 0 }}
          className="d-flex justify-content-center"
        >
          <img src="/signin.jpg" className="img-fluid login-img" alt="signin" />
        </Col>
      </Row>
    </Container>
  );
};

export { SignIn };
