export const VideoNumber = ({ number, challenge, setChallenge, isValid }) => {
  return (
    <>
      <div className="mt-4 w-75">
        <label
          className="form-label fw-bold"
          htmlFor={`vimeo-video-id${number}`}
        >
          Video {number} del {challenge.isLive ? "Live" : "Reto"} (Vimeo id)
        </label>
        <input
          type="text"
          id={`vimeo-video-id${number}`}
          className="form-control rounded-pill placeholder-low-opacity"
          name={`vimeoVideoId${number}`}
          value={challenge[`vimeoVideoId${number}`]}
          onChange={({ target }) =>
            setChallenge({
              ...challenge,
              [`vimeoVideoId${number}`]: target.value,
            })
          }
          placeholder="410670337?h=921f6f989f"
        />
      </div>
      <div className="mt-4 w-75">
        <label
          className="form-label fw-bold"
          htmlFor={`vimeo-video-app-id${number}`}
        >
          Video {number} del Reto (App Móvil)
        </label>
        <input
          type="text"
          id={`vimeo-video-app-id${number}`}
          className="form-control rounded-pill placeholder-low-opacity"
          name={`vimeoVideoAppId${number}`}
          value={challenge[`vimeoVideoAppId${number}`]}
          onChange={({ target }) =>
            setChallenge({
              ...challenge,
              [`vimeoVideoAppId${number}`]: target.value,
            })
          }
          placeholder="410670337?h=921f6f989f"
        />
      </div>
    </>
  );
};
