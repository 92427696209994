import { getAuth, updatePassword } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { TiUser } from "react-icons/ti";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Cover, Loader } from "../components";
import {
  getDocFromCollection,
  getUserCourses,
  getUserOrders,
  updateDocInCollection,
} from "../services";
import { AppContext } from "../store/AppContext";
import { formatDate, formatNumber, validateMembership } from "../utils";
import { getFunctions, httpsCallable } from "firebase/functions";

const Profile = () => {
  const functions = getFunctions();
  const createSession = httpsCallable(functions, "createSession");
  // const getHistoryUserFromHotmart = httpsCallable(
  //   functions,
  //   "getHistoryUserFromHotmart"
  // );
  const { search } = useLocation();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [orders, setOrders] = useState([]);
  const [ordersConfirmed, setOrdersConfirmed] = useState([]);
  // const [ordersInprocess, setOrdersInProcess] = useState([]);
  // const [shouldVerify, setShouldVerify] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [showProfile, setShowProfile] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidMembership, setIsValidMembership] = useState(false);
  const { toastData } = useContext(AppContext);
  const { showToast } = toastData;
  const navigate = useNavigate();

  // const challenges = [
  //   {
  //     startDate: "20 de Febrero del 2023",
  //   },
  // ];

  // const nextChallenge = { startDate: "3 de Abril del 2023" };

  useEffect(() => {
    const getUserFromDB = async (id) => {
      try {
        const userFromDB = await getDocFromCollection("users", id);
        const isValid = await validateMembership(id);
        setIsValidMembership(isValid);
        setCurrentUser(userFromDB);
        setPhone(userFromDB.phone ?? "");
      } catch (err) {
        console.log(err.message);
      }
    };

    const user = JSON.parse(localStorage.getItem("userDetails"));
    if (!user) {
      navigate("/auth");
      return;
    }

    getUserFromDB(user.id);

    if (new URLSearchParams(search).get("pedidos")) setShowProfile(false);

    const getOrdersFromUser = async () => {
      setIsLoading(true);

      try {
        const ordersFromDB = await getUserOrders(user.id);

        const ordersConf = ordersFromDB.filter(
          (order) => order.status === "confirmed"
        );

        // const ordersNotConf = ordersFromDB.filter(
        //   (order) => order.status === "pending"
        // );

        // if (ordersNotConf.length) setShouldVerify(true);

        setOrders(ordersFromDB);
        setOrdersConfirmed(ordersConf);
        // setOrdersInProcess(ordersNotConf);
      } catch (err) {
        console.log(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    // const verifyInHotmart = async () => {
    //   try {
    //     const apiToken = JSON.parse(localStorage.getItem("apiToken"));

    //     // get history from Hotmart
    //     const { data } = await getHistoryUserFromHotmart({
    //       email: user?.email,
    //       token: apiToken?.access_token,
    //     });

    //     if (data.error === "token_expired" || !apiToken.access_token) {
    //       await getHotmartToken();
    //       console.log("TOKEN EXPIRADO => Creando otro");
    //       verifyInHotmart();
    //       return;
    //     }

    //     const completedIds = data.items
    //       .filter(
    //         (ord) =>
    //           ord.purchase.status === "COMPLETE" ||
    //           ord.purchase.status === "APPROVED"
    //       )
    //       .map((ord) => ord.product.id.toString());

    //     // compare user history with orders in process only
    //     for (let i = 0; i < ordersInprocess.length; i++) {
    //       const ord = ordersInprocess[i];

    //       if (completedIds.includes(ord.product.productId)) {
    //         const coursesFromDB = await getUserCourses(user.id);
    //         const userToUpdate = {
    //           ...user,
    //           courses: [...coursesFromDB, ord.product.id],
    //         };

    //         await updateDocInCollection("users", user.id, userToUpdate);
    //         userToUpdate.courses = [];
    //         localStorage.setItem("userDetails", JSON.stringify(userToUpdate));
    //         setCurrentUser(userToUpdate);

    //         const ordCompleted = { ...ord, status: "confirmed" };
    //         await updateDocInCollection("orders", ord.id, ordCompleted);
    //         setOrdersConfirmed([...ordersConfirmed, ord]);
    //         setOrdersInProcess(
    //           ordersInprocess.filter((ordr) => ordr.id !== ord.id)
    //         );
    //       }
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    getOrdersFromUser();
    // if (shouldVerify) verifyInHotmart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleNameField = ({ target }) => {
    const name = target.value;

    setName(name);
  };

  const handlePasswordField = ({ target }) => {
    const pass = target.value;

    setPassword(pass);
  };

  const handlePhoneField = ({ target }) => {
    const phone = target.value.replace(/[^0-9]/g, "");

    setPhone(phone);
  };

  const updateProfiles = async () => {
    try {
      let shouldUpdateUser = false;

      let document = {
        ...currentUser,
        membership: { ...currentUser.membership },
      };

      // Update phone
      if (phone && phone !== currentUser.phone) {
        document.phone = phone;
        shouldUpdateUser = true;
      }

      // Update name
      if (name && name !== currentUser.name) {
        document.name = name;
        shouldUpdateUser = true;
      }

      if (shouldUpdateUser) {
        const courses = await getUserCourses(currentUser.id);
        document.courses = [...courses];
        await updateDocInCollection("users", document.id, document);
        document.courses = [];
        setCurrentUser(document);
        localStorage.setItem("userDetails", JSON.stringify(document));
      }

      // Update password
      if (password) {
        const userFromFB = getAuth().currentUser;
        await updatePassword(userFromFB, password);
      }

      showToast("success", "Actualizaste tu informacion.");
    } catch (err) {
      showToast();
    }
  };

  const handleSubscription = async () => {
    try {
      if (!currentUser?.membership) return;

      const { data } = await createSession({
        id: currentUser?.membership?.default_price,
      });

      if (data?.error || currentUser?.membership?.id.includes("membership"))
        navigate("/suscripciones");

      localStorage.setItem(
        "membership",
        JSON.stringify(currentUser?.membership)
      );
      window.open(data?.session?.url, "_self");
    } catch (err) {
      console.log(err);
    }
  };

  const isActive = "bg-purple-m text-white";
  const startDate = currentUser?.membership?.startDate?.seconds
    ? new Date(
        currentUser?.membership?.startDate?.seconds * 1000 +
          currentUser?.membership?.startDate?.nanoseconds / 1000000
      )
    : new Date(currentUser?.membership?.startDate);

  const endDate = formatDate(currentUser?.membership?.endDate);

  if (isLoading) return <Loader />;

  return (
    <Container fluid id="profile">
      <Cover text="Mi Perfil" />
      <Row className="py-4 d-flex justify-content-center">
        <Col
          xs={{ span: 10 }}
          md={5}
          xxl={{ span: 4, offset: 0 }}
          className="my-4"
        >
          <Card style={{ width: "100%" }} className="rounded-5">
            <Card.Img variant="top" src="/profile-cover.png" />
            <Card.Body className="p-0">
              <div className="d-flex p-3">
                <TiUser size={120} />
                <div className="d-flex flex-column justify-content-center ms-3">
                  <Card.Title className="fw-bold text-capitalize">
                    {currentUser?.name}
                  </Card.Title>
                  <Card.Text>
                    Se unió desde el{" "}
                    {formatDate(currentUser?.createdAt)?.toLocaleDateString()}
                  </Card.Text>
                </div>
              </div>
              <p
                className={`${
                  showProfile ? isActive : ""
                } ps-5 py-3 mb-0 pointer fw-bold`}
                onClick={() => setShowProfile(true)}
              >
                Mi Perfil
              </p>
              <p
                className={`${
                  showProfile ? "" : isActive
                } rounded-5 rounded-top-0 ps-5 py-3 mb-0 pointer fw-bold`}
                onClick={() => setShowProfile(false)}
              >
                Mis Pedidos
              </p>
            </Card.Body>
          </Card>
        </Col>
        {showProfile ? (
          <Col
            md={7}
            lg={6}
            className="d-flex justify-content-center my-4 gap-2"
          >
            <Col
              md={{ span: 5, offset: 1 }}
              lg={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
            >
              <div>
                <label
                  className="form-label text-purple-l fw-bold"
                  htmlFor="name"
                >
                  Nombre Completo
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control rounded-pill"
                  name="name"
                  defaultValue={currentUser?.name}
                  placeholder="Escribe tu nombre"
                  onChange={handleNameField}
                />
              </div>
              <div className="my-3">
                <label
                  className="form-label text-purple-l fw-bold"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control rounded-pill"
                  name="email"
                  defaultValue={currentUser?.email}
                  disabled
                />
              </div>
            </Col>
            <Col
              md={{ span: 5, offset: 1 }}
              lg={{ span: 4, offset: 1 }}
              xxl={{ span: 3, offset: 1 }}
            >
              <div>
                <label
                  className="form-label text-purple-l fw-bold"
                  htmlFor="phone"
                >
                  Telefono
                </label>
                <input
                  type="text"
                  id="phone"
                  className="form-control rounded-pill"
                  value={phone}
                  name="phone"
                  onChange={handlePhoneField}
                  placeholder="Escribe tu telefono"
                />
              </div>
              <div className="my-3">
                <label
                  className="form-label text-purple-l fw-bold"
                  htmlFor="password"
                >
                  Cambiar Contraseña
                </label>
                <input
                  type="password"
                  id="password"
                  className="form-control rounded-pill"
                  name="password"
                  onChange={(e) => handlePasswordField(e)}
                  placeholder="Escribe tu nueva contraseña"
                />
              </div>
              <button
                className="btn btn-large bg-purple-l float-end text-white rounded-pill px-5 mt-4"
                onClick={updateProfiles}
              >
                Actualizar
              </button>
            </Col>
          </Col>
        ) : (
          <Col md={7} lg={6} className="my-4">
            <Tabs
              defaultActiveKey="cursos"
              id="profile-tabs"
              className="fw-bold"
            >
              <Tab eventKey="cursos" title="Pedidos">
                {orders.length < 1 && (
                  <div className="d-flex align-items-center justify-content-center mt-5">
                    <p className="text-purple-m fw-bold fs-5 mt-5 px-5">
                      Aun no cuentas con ningun curso, busca en nuestra{" "}
                      <Link to="/cursos" className="text-purple-l pb-1">
                        biblioteca
                      </Link>{" "}
                      alguno de tu agrado.
                    </p>
                  </div>
                )}
                {/* {!!ordersInprocess.length && (
                  <>
                    <div className="d-flex justify-content-between my-4">
                      <h5 className="text-purple-m fw-bold">
                        Pedidos en Verificación
                      </h5>
                    </div>
                    <Table bordered className="mb-5">
                      <thead>
                        <tr>
                          <th className="ps-2 w-33">Mis pedidos</th>
                          <th className="ps-2">Tipo</th>
                          <th className="ps-2">Compra</th>
                          <th className="ps-2">Costo</th>
                          <th className="ps-2">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordersInprocess.map((order) => (
                          <tr key={order?.id}>
                            <td className="w-33 text-capitalize">
                              {order?.product?.name}
                            </td>
                            <td className="text-capitalize">{order?.type}</td>
                            <td>
                              {new Date(order?.createdAt).toLocaleDateString()}
                            </td>
                            <td>{formatNumber(order?.product?.price)}</td>
                            <td className="text-warning fw-bold text-capitalize">
                              Verificando
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <hr />
                  </>
                )} */}
                {!!ordersConfirmed.length && (
                  <>
                    <div className="d-flex align-items-end justify-content-between">
                      <h5 className="text-purple-m fw-bold mt-5">
                        Pedidos Verificados
                      </h5>
                      <div>
                        <Link
                          to="/cursos"
                          className={`btn rounded-pill purple-gradient border-0 text-white fw-bold px-5 pointer text-decoration-none py-2 me-2`}
                          onClick={() => {
                            setTimeout(() => {
                              window.scrollTo({
                                top: 800,
                                behavior: "smooth",
                              });
                            }, 300);
                          }}
                        >
                          Ir a Cursos
                        </Link>
                        <Link
                          to="/retos"
                          className={`btn rounded-pill purple-gradient border-0 text-white fw-bold px-5 pointer text-decoration-none py-2`}
                        >
                          Ir a Retos
                        </Link>
                      </div>
                    </div>
                    <Table bordered className="mt-4">
                      <thead>
                        <tr>
                          <th className="ps-2 w-33">Mis pedidos</th>
                          <th className="ps-2">Tipo</th>
                          <th className="ps-2">Compra</th>
                          <th className="ps-2">Costo</th>
                          <th className="ps-2">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordersConfirmed.map((order) => (
                          <tr key={order?.id}>
                            <td className="w-33 text-capitalize">
                              {order?.product?.name}
                            </td>
                            <td className="text-capitalize">{order?.type}</td>
                            <td>
                              {formatDate(
                                order?.createdAt
                              )?.toLocaleDateString()}
                            </td>
                            <td>{formatNumber(order?.product?.price)}</td>
                            <td className="text-success fw-bold text-capitalize">
                              Confirmado
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
              </Tab>
              <Tab eventKey="membresia" title="Suscripción">
                <Row className="mx-0 border-bottom border-secondary">
                  <Col
                    md={4}
                    className="border-end border-secondary d-flex align-items-center py-2 ps-4"
                  >
                    Suscripción
                  </Col>
                  <Col
                    md={8}
                    className="d-flex justify-content-between pt-3 bg-gray"
                  >
                    {isValidMembership && (
                      <>
                        <p className="fw-bold">
                          Proximo pago:{" "}
                          <span className="fw-normal ps-3">
                            {endDate.toLocaleDateString()}
                          </span>
                        </p>
                        <p>{formatNumber(currentUser?.membership?.price)}</p>
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="mx-0">
                  <Col
                    md={4}
                    className="border-end border-secondary d-flex flex-column p-4 pb-0"
                  >
                    <h2 className="fs-2 fw-bold">
                      {isValidMembership ? "Activa" : "No Activa"}
                    </h2>
                    {isValidMembership && (
                      <p>{startDate.toLocaleDateString()}</p>
                    )}

                    {endDate && endDate < new Date() && (
                      <Button
                        variant="info"
                        onClick={() => handleSubscription()}
                        className="text-white mb-4"
                      >
                        Renovar Suscripción
                      </Button>
                    )}
                    <p className="py-3">
                      Disfruta de el acceso a todo el contenido exclusivo de
                      esta Área qué Leenith Borges Arte tiene para tí.
                    </p>
                    <Button
                      variant="info"
                      onClick={() => navigate("/suscripciones")}
                      className="text-white"
                    >
                      {isValidMembership
                        ? "Ir a Suscripciones"
                        : "Conseguir Suscripción"}
                    </Button>
                    {isValidMembership && (
                      <p className="mt-3 fs-small fw-lighter text-center">
                        ¿Quieres cancelar tu suscripción? Contáctanos por
                        whatsapp
                      </p>
                    )}
                  </Col>
                  <Col md={8} className="pt-4 ps-0">
                    <h5 className="ps-4 mb-4 fw-bold">Pagos anteriores</h5>
                    {ordersConfirmed
                      .filter((i) => i.type === "membresia")
                      .map((item) => (
                        <p
                          key={item.id}
                          className="border-bottom border-secondary ps-4"
                        >
                          {new Date(item?.createdAt)?.toLocaleDateString()}{" "}
                          <span className="ms-5">
                            {formatNumber(item?.product?.price)}
                          </span>
                        </p>
                      ))}
                  </Col>
                </Row>
              </Tab>
              {/* <Tab eventKey="reto" title="Reto">
                <Row className="mx-0 border-bottom border-secondary">
                  <Col
                    md={4}
                    className="border-end border-secondary d-flex align-items-center py-3 ps-4"
                  >
                    Reto
                  </Col>
                  <Col md={8} className="bg-gray"></Col>
                </Row>
                <Row className="mx-0">
                  <Col className="p-4 pb-0">
                    <h2 className="fs-2 fw-bold">
                      {challenges.length
                        ? "!Tenemos un reto para ti!"
                        : "No hay retos"}
                    </h2>
                    {challenges.length ? (
                      <>
                        <p>
                          Proxima fecha: <span>{challenges[0].startDate}</span>
                        </p>
                        <Button variant="info" className="text-white">
                          Ver más información
                        </Button>
                      </>
                    ) : (
                      <p>
                        Espera nuestro nuevo reto el {nextChallenge.startDate}
                      </p>
                    )}
                  </Col>
                </Row>
              </Tab> */}
            </Tabs>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export { Profile };
