import { Col, Container, Row } from "react-bootstrap";

const PrivacyContent = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 11, offset: 1 }} className="text-content">
          <h3 className="mb-1 fw-bold">Avisos de Privacidad</h3>
          <h5>Leenit Borges Arte</h5>
          <p>
            La seguridad de la información de nuestros usuarios es una prioridad
            para nuestra empresa.
          </p>
          <p>
            El 5 de julio de 2010 se publicó en el Diario Oficial de la
            Federación la Ley Federal de Protección de Datos Personales en
            Posesión de Particulares (en lo sucesivo la “Ley”), cuyas
            disposiciones claramente coadyuvan con nuestro objetivo de proteger
            tus datos personales. Puedes acceder al contenido de la Ley a través
            de los portales que el Gobierno Federal, por conducto de la
            Secretaría de Gobernación y la Cámara de Diputados del H. Congreso
            de la Unión tienen en internet en las siguientes direcciones:
            http://www.ordenjuridico.gob.mx y http://www.diputados.gob.mx
          </p>
          <p>
            En virtud de lo antes expuesto, hacemos de su conocimiento que
            Leenith Borges Arte, es responsable del tratamiento de sus datos
            personales, buscando en todo momento su correcto uso, asegurando así
            la privacidad, integridad, confidencialidad y el derecho a la
            autodeterminación informativa de sus datos.
          </p>
          <p>Responsable de la Protección de tus Datos Personales</p>
          <p>Obtención de tus Datos Personales</p>
          <p>
            Para dar cumplimiento a lo dispuesto en la Ley y para las
            finalidades previstas en el presente Aviso de Privacidad, te
            informamos que podemos recabar tus datos personales de las
            siguientes maneras:
          </p>
          <p>
            De forma directa cuando tú nos los proporciones Cuando visites
            nuestra página de internet www.leenitharte.com.mx, o Cuando
            obtenemos información a través de otros medios permitidos por la
            legislación vigente aplicable. Lo anterior de conformidad con la
            siguiente información que se les solicitará: Los datos personales
            que podemos recabar de forma directa, son aquellos que tú mismo nos
            proporciones (ya sea de forma oral o escrita) como nombre, número de
            teléfono, domicilio completo, datos de pago, cuenta bancaria, número
            de tarjeta de crédito (dato que no se guarda, simplemente lo usa el
            procesador de pagos) y dirección IP (con la finalidad de poder ver
            si una venta es verídica o no, se usa para ver si alguien trato de
            hacer fraude anteriormente con esa IP) correo electrónico.
          </p>
          <p>
            Los datos personales que podemos recabar cuando visitas nuestra
            página de internet son: nombre, dirección de correo electrónico,
            domicilio completo (de facturación y de envío), teléfono, datos de
            pago, tarjeta de crédito (dato que no se guarda, simplemente lo usa
            el procesador de pagos), y dirección IP (con la finalidad de poder
            ver si una venta es verídica o no, se usa para ver si alguien trato
            de hacer fraude anteriormente con esa IP).
          </p>
          <p>
            Los datos personales que podemos obtener por otros medios permitidos
            por la legislación vigente aplicable o resoluciones de alguna
            autoridad competente facultada para dichos efectos, tales como
            nombre, el lugar y puesto de trabajo, dirección de correo
            electrónico, datos relacionadas con la fuente (empresa) de tu
            trabajo.
          </p>
          <p>Finalidad y Utilización de tus Datos Personales.</p>
          <p>
            Los datos personales que se recaben conforme a lo establecido en el
            numeral anterior se utilizarán únicamente para los siguientes fines:
            Comercializar productos y servicios ofrecidos por Leenith Borges
            Arte
          </p>
          <p>
            Ofrecer información sobre cambios, nuevos productos y/o servicios a
            nuestros clientes.
          </p>
          <p>
            Realizar campañas por medio electrónico de promociones de nuevos
            productos y servicios a nuestros clientes.
          </p>
          <p>Evaluar la calidad de nuestros productos y/o servicios.</p>
          <p>
            Que exista la posibilidad de tener una relación de manera directa y
            personal, con la finalidad de acreditar la identificación del
            titular
          </p>
          <p>Tratamiento de los Datos Personales Sensibles</p>
          <p>
            Leenith Borges Arte no recaba datos personales sensibles (tal como
            datos de origen racial o étnico, estado de salud presente y futuro,
            información genética, creencias religiosas, filosófica morales,
            afiliación sindical, opiniones políticas, preferencia sexual, tal
            como están definidos en la fracción VI del artículo 3º de la Ley),
            sin embargo, si por cualquier acción, hecho o circunstancia
            llegáramos a obtener datos personales de esta índole, Leenith Borges
            Arte se compromete a que dichos datos serán tratados bajo los
            estándares y medidas más estrictas de seguridad que garanticen su
            confidencialidad.
          </p>
          <p>Limitación del Uso o Divulgación de los Datos Personales</p>
          <p>
            Leenith Borges Arte no utilizará tus datos personales para fines
            distintos a los establecidos en el numeral (3) del presente Aviso de
            Privacidad. Asimismo, Leenith Borges Arte no comunicará, compartirá
            ni de ninguna forma transferirá los datos personales recabados, ya
            sea dentro o fuera del territorio nacional, a terceras personas.
          </p>
          <p>
            Derechos de Acceso, Rectificación, Cancelación y Oposición relativos
            a los Datos Personales
          </p>
          <p>
            Tendrás el derecho de acceder a tus datos personales que Leenith
            Borges Arte hubiera recabado en todo momento, siempre que sean días
            y horas laborales a través de previa solicitud que realice
            directamente a Leenith Borges Arte, a través del siguiente correo
            electrónico contacto@leenitharte.com.mx. Asimismo tendrás el derecho
            de rectificarlos en caso de que tus datos personales estén
            incompletos o sean inexactos, así como cancelarlos de nuestros
            registros en cualquier momento, excepto en casos establecidos en el
            artículo 26 de la Ley. Finalmente, podrás oponerte al tratamiento de
            tus datos personales para fines específicos.
          </p>
          <p>
            Revocación de la Autorización para el Tratamiento de Datos
            Personales
          </p>
          <p>
            En términos de lo dispuesto por el artículo 8º de la Ley, podrás
            revocar en todo momento la autorización que nos otorgaste para el
            tratamiento de tus datos personales, mediante el envío de un correo
            electrónico a la dirección: contacto@leenitharte.com.mx en atención
            a tienda en línea, que contenga la siguiente información: a) el
            nombre y domicilio para comunicarte la respuesta de la solicitud, b)
            los documentos suficientes y necesarios que acrediten la identidad
            del titular de los datos personales o en su caso de tu representante
            legal con las facultades suficientes y necesarias para los efectos
            en cuestión, c) la descripción clara y precisa de los datos
            personales respecto de los cuales se requiere la cancelación, y d)
            cualquier otro documento o elemento que facilite la localización de
            los datos personales sujetos a la cancelación antes mencionada.
          </p>
          <p>
            Leenith Borges Arte deberá responder a la solicitud descrita en los
            dos párrafos anteriores en un plazo que no podrá exceder de 20 días
            naturales contados a partir de que se reciba dicha solicitud. En el
            supuesto que la solicitud del interesado cumpla con todos los
            requisitos y, por ende resultare procedente la misma, Leenith Borges
            Arte contará con un plazo que no excederá de 15 días contados a
            partir de la fecha de comunicación de la respuesta señalada con
            anterioridad para hacer efectiva la solicitud.
          </p>
          <p>Modificaciones al Aviso de Privacidad</p>
          <p>
            Leenith Borges Arte se reserva el derecho de realizar cualquier tipo
            de modificación o actualización al presente Aviso de Privacidad, con
            la finalidad de dar cabal cumplimiento a las nuevas disposiciones
            legales, a nuevas políticas internas o a requerimientos de cualquier
            autoridad competente. Estas modificaciones y/o actualizaciones lo
            podrás consultar a través de la página de internet
            www.denique.com.mx
          </p>
          <p>Aceptación</p>
          <p>
            El presente Aviso de Privacidad está sujeto a los Términos y
            Condiciones de nuestra página de internet www.leenitharte.com.mx, el
            cual establece un acuerdo legal entre el usuario y la empresa. Si el
            usuario utiliza nuestros servicios, significa que ha leído,
            entendido y acordado los términos antes expuestos.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyContent;
export { PrivacyContent };
