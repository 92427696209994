import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useCart, useFetch } from "../../hooks";

const CheckoutCard = ({ handleSuccessPayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { items } = useCart();
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));
  const [usdPrice, setUsdPrice] = useState(18);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { docs: currencies, isLoading: currenciesLoader } =
    useFetch("currencies");

  const functions = getFunctions();
  const createPaymentIntent = httpsCallable(functions, "createPaymentIntent");

  useEffect(() => {
    if (!currenciesLoader && currencies.length) {
      setUsdPrice(Number(currencies.find((cur) => cur.name === "USD")?.price));
    }
  }, [currenciesLoader, currencies]);

  const getTotal = () => {
    return items.reduce((acc, item) => {
      return acc + (Number(item.price) * (100 - Number(item.discount))) / 100;
    }, 0);
  };

  const handleCardInfoSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      setError("");

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        return setError(error.message);
      } else {
        const response = await createPaymentIntent({
          id: paymentMethod.id,
          amount: (getTotal() * Number(usdPrice) * 100).toFixed(),
          user,
          items,
        });

        if (response.data.error)
          return setError(
            "Ocurrió un error al procesar el pago, verifica tus datos e intenta nuevamente."
          );

        if (response.data.paymentIntent) handleSuccessPayment();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleCardInfoSubmit}>
      <Row>
        <h5 className="py-3 mt-4 ps-3 rounded-3 fs-6 fw-bold text-purple-m bg-gray">
          Pago con Tarjeta
        </h5>

        <Col md={{ span: 12 }} className="py-5">
          <CardElement />
          {error && <p className="mt-5 text-red">* {error}</p>}
        </Col>

        <Col md={{ span: 6 }} className="py-3">
          <div>
            {isLoading ? (
              <div className="d-flex align-items-center">
                <Spinner animation="border" role="status" variant="info" />
                <span className="px-3">Procesando...</span>
              </div>
            ) : (
              <Button
                variant="info"
                className="border-0 px-5 rounded-pill"
                type="submit"
              >
                Continuar
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default CheckoutCard;
export { CheckoutCard };
