import { Col, Container, Row, Accordion } from "react-bootstrap";
import { Cover, Question } from "../components";

const Faq = () => {
  return (
    <>
      <Container fluid id="faq">
        <Cover text="FAQ" />
        <Row className="my-5">
          <Col md={{ span: 10, offset: 1 }}>
            <h3 className="text-center text-purple-l fw-bold fs-2">
              Preguntas Frecuentes
            </h3>
            <Accordion>
              <Accordion.Item eventKey="0" className="mt-4">
                <Accordion.Header>¿El curso es presencial?</Accordion.Header>
                <Accordion.Body>
                  No, este es un programa online, es decir la capacitación está
                  en videos grabados en formato HD, dentro de un área de
                  miembros a la cual tendrás acceso inmediato y para toda la
                  vida una vez realizado el pago.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mt-4">
                <Accordion.Header>¿Tienen soporte para dudas?</Accordion.Header>
                <Accordion.Body>
                  Si por supuesto, tenemos un grupo de Telegram solo para
                  alumnos del curso, donde damos asesoría y respondemos
                  preguntas sobre cualquier parte del proceso del curso, también
                  debajo de cada clase tenemos un área de preguntas tipo foro,
                  para que nos dejes tus dudas.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="mt-4">
                <Accordion.Header>
                  ¿Hasta cuando puedo ingresar al programa?
                </Accordion.Header>
                <Accordion.Body>
                  Los cupos se están agotando rápido, estamos seguros que
                  cerraremos inscripciones el día de Mañana
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mt-4">
                <Accordion.Header>
                  ¿Cuál es el precio de la moneda local?
                </Accordion.Header>
                <Accordion.Body>
                  Al darle clic en el botón REGISTRARSE (inscribirte, según el
                  botón) serás redirigido a una página donde aparecerá el valor
                  del curso en tu moneda local (moneda de tu País) de manera
                  automática
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="mt-4">
                <Accordion.Header>
                  ¿Cuanto tiempo podré ver las clases?
                </Accordion.Header>
                <Accordion.Body>
                  El acceso es para toda la vida, puedes ingresar desde el lugar
                  en el que te encuentres y a la hora que desees realizar la
                  capacitación o ver el curso.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="mt-4">
                <Accordion.Header>
                  ¿Tendré Certificado al finalizar?
                </Accordion.Header>
                <Accordion.Body>
                  Si claro, al finalizar todas las clases, recibirás un
                  certificado descargable de haber concluido exitosamente
                  nuestro programa, lo puedes imprimir y ponerlo en un cuadro si
                  deseas.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="mt-4">
                <Accordion.Header>
                  ¿Tendré que pagar algo adicional?
                </Accordion.Header>
                <Accordion.Body>
                  No, ese es el valor final a pagar, no existen pagos
                  recurrentes, es un único pago para toda la vida e incluyen las
                  nuevas actualizaciones gratis que se suban al programa
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" className="mt-4">
                <Accordion.Header>
                  ¿Qué formas de pago aceptan?
                </Accordion.Header>
                <Accordion.Body>
                  Al dar clic en el botón REGISTRARSE (inscribirte, según el
                  botón) serás redirigido a una página donde aparecerán todos
                  los métodos de pago que aceptamos
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Question />
    </>
  );
};

export { Faq };
