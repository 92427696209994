import { Col, Row } from "react-bootstrap";
import {
  TiSocialFacebookCircular,
  TiSocialInstagram,
  TiSocialYoutube,
} from "react-icons/ti";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <Row className="w-100" id="footer-row">
        <Col
          xs={12}
          sm={{ span: 4, offset: 1 }}
          lg={{ span: 3, offset: 2 }}
          id="menu"
          className="mt-3"
        >
          <h3 className="brand-text text-start px-2">Menu</h3>
          <div id="menu-items" className="gap-3 px-2">
            <div className="w-100 d-flex flex-column gap-3">
              <Link to="/">Home</Link>
              <Link to="/cursos">Cursos</Link>
              <Link to="/retos">Retos/Live</Link>
              <a
                href="https://leenithborgesarte.mitiendanube.com/"
                target="_blank"
                rel="noreferrer"
              >
                Materiales
              </a>
            </div>
            <div className="w-100 d-flex flex-column gap-3">
              <Link to="/sobre-mi">Sobre mi</Link>
              {/* <Link to="/suscripciones">Suscripciones</Link> */}
              <Link to="/contacto">Contacto</Link>
              <Link to="/faq">FAQ</Link>
            </div>
          </div>
        </Col>

        <Col xs={7} sm={3} lg={3} className="mt-3">
          <h3 className="brand-text px-2">Mi perfil</h3>
          <div className="gap-3 px-2 d-flex flex-column">
            <Link to="/perfil?pedidos=true">Mis cursos</Link>
            <Link to="/perfil?pedidos=true">Mis compras</Link>
          </div>
        </Col>

        <Col xs={4} sm={3} className="mt-3">
          <div>
            <a
              href="https://www.facebook.com/cuadrosleenithborges"
              target="_blank"
              rel="noreferrer"
            >
              <TiSocialFacebookCircular />
            </a>
            <a
              href="https://www.instagram.com/leenithborges/"
              target="_blank"
              rel="noreferrer"
            >
              <TiSocialInstagram />
            </a>
            <a
              href="https://www.youtube.com/channel/UCYLf1em1NKejW9-3ciN33jA"
              target="_blank"
              rel="noreferrer"
              className="ms-1"
            >
              <TiSocialYoutube />
            </a>
            <a
              href="https://t.me/+xlUZHAV5cw9jY2Qx"
              target="_blank"
              rel="noreferrer"
              className="ms-1"
            >
              <FaTelegramPlane />
            </a>
          </div>
          <Link to="/terminos-condiciones">Terminos y condiciones</Link>
        </Col>
        <Col xs={12}>
          <div className="text-center mt-4">
            <a
              href="https://zenithsystems.com.mx/"
              target="_blank"
              rel="noreferrer"
              id="zenith-link"
            >
              Desarrollado por Zenith Systems 2023.
            </a>
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export { Footer };
