import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Cover, Loader, Player } from "../components";
import { getDocFromCollectionByName, getUserCourses } from "../services";
import {
  formatNumber,
  formatMinutes,
  formatMinutesToTime,
  firstCharUpperCase,
} from "../utils";
import { TiInputChecked } from "react-icons/ti";
import { AddToCartButton } from "../components/Cart/AddToCartButton";
/* import { useFetch } from "../hooks";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const recommendedCourse = {
  id: "JHYBwC6sT1EFnwLMtLhu",
  name: "Técnica Acuarela",
  image: "/photo-1.png",
  duration: 60,
  level: "Dificíl",
  sections: 4,
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1200, min: 576 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
}; */

const Course = () => {
  const params = useParams();
  const navigate = useNavigate();
  /* const { docs: recommendedCourses } = useFetch("courses"); */

  const [course, setCourse] = useState({});
  // const [isPending, setIsPending] = useState(false);
  const [courseBuyed, setCourseBuyed] = useState(false);
  const [videoToShow, setVideoToShow] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // const [isInCart, setIsInCart] = useState(false);
  // const { cart } = useContext(AppContext);
  // const { addItem, checkItemInCheckout } = cart;
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));
  const [currentCourses] = useState(
    JSON.parse(localStorage.getItem("currentCourses"))
  );
  const [currentSection, setCurrentSection] = useState(0);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [activeKey, setActiveKey] = useState();

  const isValidMembership = user?.membership?.isActive;

  useEffect(() => {
    const controller = new AbortController();

    const fetchCourse = async () => {
      setIsLoading(true);

      try {
        const course = await getDocFromCollectionByName("courses", params.name);
        setCourse(course);
        if (courseBuyed && currentCourses == null) {
          localStorage.setItem(
            "currentCourses",
            JSON.stringify({
              [course.id]: {
                currentSection: 0,
                currentChapter: 0,
              },
            })
          );
          setVideoToShow(course?.sections[0]?.chapters[0]?.videoId);
          setCurrentSection(0);
          setCurrentChapter(0);
          setActiveKey(course.sections[0].name);
        } else if (courseBuyed && !currentCourses[course.id]) {
          localStorage.setItem(
            "currentCourses",
            JSON.stringify({
              ...currentCourses,
              [course.id]: {
                currentSection: 0,
                currentChapter: 0,
              },
            })
          );
          setCurrentSection(0);
          setCurrentChapter(0);
          setActiveKey(course.sections[0].name);
          setVideoToShow(course?.sections[0]?.chapters[0]?.videoId);
        } else if (courseBuyed) {
          const { currentSection, currentChapter } = currentCourses[course.id];
          setCurrentSection(currentSection);
          setCurrentChapter(currentChapter);
          setVideoToShow(
            course?.sections[currentSection]?.chapters[currentChapter]?.videoId
          );
          setActiveKey(course.sections[currentSection].name);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    const getCoursesFromUser = async () => {
      setIsLoading(true);

      try {
        const courses = await getUserCourses(user.id);
        if (
          courses.includes(course?.id) ||
          (course.isFromMembership && isValidMembership)
        )
          setCourseBuyed(true);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    // const getOrdersFromUser = async () => {
    //   setIsLoading(true);

    //   try {
    //     const ordersFromDB = await getUserOrders(user.id);
    //     const ids = ordersFromDB
    //       .filter((ord) => ord.status === "pending")
    //       .map((ord) => ord.product.id);
    //     if (ids.includes(course.id)) setIsPending(true);
    //   } catch (err) {
    //     console.log(err.message);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };

    params.name && params.name !== "undefined"
      ? fetchCourse()
      : navigate("/cursos");

    if (user && user.id) {
      getCoursesFromUser();
      // getOrdersFromUser();
    }

    return () => controller.abort();

    // if (!courseBuyed) setIsInCart(checkItemInCheckout(params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.name,
    user,
    courseBuyed,
    course?.id,
    currentCourses,
    navigate,
    isValidMembership,
  ]);

  // const createOrder = async () => {
  //   setIsLoading(true);

  //   try {
  //     const { id, name, price, discount, productId, cover } = course;

  //     const newOrder = {
  //       type: "curso",
  //       product: {
  //         id,
  //         name,
  //         price: (Number(price) * (100 - Number(discount))) / 100,
  //         productId,
  //         cover,
  //       },
  //       user: {
  //         id: user.id,
  //         email: user.email,
  //       },
  //       status: "confirmed",
  //       createdAt: Date.now(),
  //     };

  //     await addDocInCollection("orders", newOrder);
  //     navigate("/pago");
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const setCurrentVideo = (section, chapter) => {
    localStorage.setItem(
      "currentCourses",
      JSON.stringify({
        ...currentCourses,
        [course.id]: {
          currentSection: section,
          currentChapter: chapter,
        },
      })
    );
    setCurrentSection(section);
    setCurrentChapter(chapter);
    setActiveKey(course.sections[section].name);
    setVideoToShow(course?.sections[section]?.chapters[chapter]?.videoId);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleVideoNavigation = (direction) => {
    if (direction === "next") {
      if (course.sections[currentSection]?.chapters[currentChapter + 1]) {
        setCurrentVideo(currentSection, currentChapter + 1);
      } else if (course.sections[currentSection + 1].chapters[0]) {
        setCurrentVideo(currentSection + 1, 0);
      }
    } else if (direction === "prev") {
      if (currentSection === 0 && currentChapter === 0) return;
      if (course.sections[currentSection]?.chapters[currentChapter - 1]) {
        setCurrentVideo(currentSection, currentChapter - 1);
      } else if (course.sections[currentSection - 1]?.chapters[0]) {
        setCurrentVideo(
          currentSection - 1,
          course.sections[currentSection - 1].chapters.length - 1
        );
      }
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <h1>Algo malo sucedio...</h1>;

  return (
    <>
      <Container fluid id="course-page">
        {!courseBuyed && <Cover text="Cursos" />}
        <Row className="my-5">
          <Col lg={{ span: 8, offset: 0 }} xxl={{ span: 5, offset: 2 }}>
            <h3 className="text-purple-l fw-bold fs-2 mb-5 text-capitalize text-center">
              {course.name}
            </h3>
            {courseBuyed ? (
              <>
                <Player videoId={videoToShow} />
                <div className="d-flex justify-content-between my-5">
                  <div
                    className="d-flex align-items-center pointer"
                    onClick={() => {
                      handleVideoNavigation("prev");
                    }}
                  >
                    <img src="/video-temp.png" width={120} height={60} alt="" />
                    <p className="my-0 ms-3">Anterior</p>
                  </div>
                  <div
                    className="d-flex align-items-center pointer"
                    onClick={() => {
                      handleVideoNavigation("next");
                    }}
                  >
                    <p className="my-0 me-3">Siguiente</p>
                    <img src="/video-temp.png" width={120} height={60} alt="" />
                  </div>
                </div>
                <hr />
              </>
            ) : (
              <div className="mx-4">
                <iframe
                  width="100%"
                  height="415"
                  className="rounded-4"
                  src={`https://www.youtube.com/embed/${course.videoId}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </Col>
          <Col lg={4} xxl={3} className="mt-5">
            {!courseBuyed ? (
              <div className="border border-dark rounded-4 py-5 mx-4 mt-5">
                <div className="d-flex justify-content-center flex-wrap">
                  <p className="fw-bold fs-3 mb-0 align-self-center">
                    {formatNumber(
                      (Number(course.price) * (100 - Number(course.discount))) /
                        100
                    )}
                  </p>
                  {Number(course.discount) > 0 && (
                    <p className="fs-5 mb-0 align-self-center text-decoration-line-through mx-2">
                      {formatNumber(course.price)}
                    </p>
                  )}
                </div>
                <div className="fs-5">
                  {Number(course.discount) > 0 && (
                    <p className="text-center text-red fw-bold">
                      Descuento {course.discount} %
                    </p>
                  )}
                  <div className="d-grid gap-2 justify-content-center">
                    <AddToCartButton item={course} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="border border-dark rounded-4 py-2 mx-4 mt-5">
                <Accordion activeKey={activeKey} alwaysOpen>
                  {course.sections.map((section, indexSection) => (
                    <Accordion.Item
                      key={section.name}
                      eventKey={section.name}
                      onClick={() => {
                        setActiveKey(section.name);
                      }}
                    >
                      <Accordion.Header>
                        <div>
                          <p className="mb-0 fw-bold text-capitalize">
                            {section.name}
                          </p>
                          <p className="mb-0 mt-2">
                            Capítulos: {section.chapters.length}
                          </p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {section.chapters.map((chapter, indexChapter) => (
                          <div
                            key={chapter.videoId}
                            id={chapter.videoId}
                            className={`${
                              videoToShow === chapter.videoId
                                ? "text-white bg-purple-m"
                                : "bg-gray"
                            } d-flex align-items-center justify-content-between my-2 px-5 py-4 rounded-3 fw-bold text-capitalize pointer`}
                            onClick={() =>
                              setCurrentVideo(indexSection, indexChapter)
                            }
                          >
                            {chapter.name}
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={8} xxl={{ span: 5, offset: 2 }}>
            <div className="border border-dark rounded-4 mx-4 p-3">
              <h4 className="fw-bold fs-3 text-capitalize">{course.name}</h4>
              <p className="my-4 wsp">
                {firstCharUpperCase(course.description)}
              </p>
            </div>
            {courseBuyed ? (
              <div className="border border-dark rounded-4 overflow-hidden m-4">
                <Tabs
                  defaultActiveKey="capitulos"
                  id="course-tabs"
                  className="fw-bold"
                >
                  <Tab eventKey="capitulos" title="Capítulos">
                    <Accordion>
                      {course.sections.map((section) => (
                        <Accordion.Item
                          key={section.name}
                          eventKey={section.name}
                        >
                          <Accordion.Header>
                            <div>
                              <p className="mb-0 fw-bold text-capitalize">
                                {section.name}
                              </p>
                              <p className="mb-0 mt-2">
                                Capítulos: {section.chapters.length}
                              </p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {section.chapters.map((chapter) => (
                              <div
                                key={chapter.videoId}
                                className="d-flex align-items-center justify-content-between my-2 px-5 py-4 bg-gray rounded-3"
                              >
                                <div className="fw-bold text-capitalize">
                                  {chapter.name}
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="me-5">
                                    {formatMinutesToTime(chapter.duration)}
                                  </div>
                                  <Button
                                    onClick={() => {
                                      setVideoToShow(chapter.videoId);
                                      window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }}
                                    className="rounded-5 purple-gradient border-0 text-white fw-bold px-4 pointer py-1"
                                  >
                                    Ver
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </Tab>
                  <Tab eventKey="material" title="Material de Apoyo">
                    <div className="d-flex flex-column align-items-center justify-content-center my-5 py-5">
                      {course?.materialesLink ? (
                        <>
                          <a
                            href={course?.materialesLink}
                            target="_blank"
                            rel="noreferrer"
                            className="fs-5 text-center pointer text-decoration-none"
                          >
                            Material
                          </a>
                          <a
                            href={course?.materialesLink}
                            target="_blank"
                            rel="noreferrer"
                            className="fs-5 text-center pointer text-decoration-none mt-5 purple-gradient text-white px-3 py-2 rounded-pill"
                          >
                            Ver material
                          </a>
                        </>
                      ) : (
                        <p className="fw-bold fs-5 text-center mb-0">
                          Muy pronto!
                        </p>
                      )}
                    </div>
                    {/* <div className="pt-2 px-4">
                      <p className="fw-bold fs-5 text-purple-m">PDF's</p>
                      {course.material?.pdf?.map((item, i) => (
                        // <a href={item} target="_blank" rel="noreferrer">
                        // </a>
                        <TiDocument key={i} size={80} />
                      ))}
                      <hr />
                    </div>
                    <div className="px-4">
                      <p className="fw-bold fs-5 text-purple-m">Imagenes</p>
                      {course.material?.images?.map((item, i) => (
                        <img
                          key={i}
                          src={item}
                          className="img-fluid"
                          width={100}
                          alt="material"
                        />
                      ))}
                      <hr />
                    </div>
                    <div className="py-2 px-4">
                      <p className="fw-bold fs-5 text-purple-m">Audios</p>
                      {course.material?.audio?.map((item, i) => (
                        <figure key={i}>
                          <figcaption>Audio {i + 1}:</figcaption>
                          <audio controls src={item}>
                            <a href={item}>Descargar audio</a>
                          </audio>
                        </figure>
                      ))}
                    </div> */}
                  </Tab>
                  {!course.isFromMembership && (
                    <Tab eventKey="telegram" title="Telegram">
                      <div className="px-4 my-5 py-5 d-flex flex-column align-items-center justify-content-center">
                        {course?.telegramLink ? (
                          <>
                            <a
                              href={course.telegramLink}
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-none"
                            >
                              <p className="text-center fs-5 mb-0">
                                Únete a nuestro grupo de Telegram
                              </p>
                            </a>
                            <a
                              href={course?.telegramLink}
                              target="_blank"
                              rel="noreferrer"
                              className="fs-5 text-center pointer text-decoration-none mt-5 purple-gradient text-white px-3 py-2 rounded-pill"
                            >
                              Ver Telegram
                            </a>
                          </>
                        ) : (
                          <p className="text-center fs-5">
                            Estamos creando el grupo en Telegram
                          </p>
                        )}
                      </div>
                    </Tab>
                  )}
                  {course.isFromMembership && (
                    <Tab eventKey="trabajos" title="Trabajos">
                      <div className="p-2 px-4">
                        <Row xs={1} md={2} xxl={3}>
                          {course.showcase.map((work, index) => (
                            <Col
                              md={6}
                              key={index}
                              className="d-flex align-items-stretch justify-content-center"
                            >
                              <Card className="border-0 rounded-0 overflow-hidden mt-2 mx-0 w-100">
                                <Card.Img
                                  variant="top"
                                  src={work.image}
                                  className="rounded-0 course-image"
                                />
                                <Card.Body className="pb-0 px-1">
                                  <Card.Title>
                                    <p className="fw-bold d-inline mb-0 fs-normal">
                                      {index + 1}
                                    </p>
                                    -{" "}
                                    <p className="d-inline mb-0 text-capitalize fs-normal">
                                      {work.name}
                                    </p>
                                  </Card.Title>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </div>
            ) : (
              <div className="border border-dark rounded-4 overflow-hidden m-4">
                <Tabs
                  defaultActiveKey="general"
                  id="course-tabs"
                  className="fw-bold"
                >
                  <Tab eventKey="general" title="General">
                    <div className="py-3 px-4">
                      <p className="fw-bold fs-5 text-capitalize">
                        {course.name} incluye:
                      </p>
                      <Row className="py-1">
                        <Col md={12}>
                          {course?.lessons && (
                            <BenefitItem
                              text={`${course?.lessons} Lecciones`}
                              image="sections.png"
                            />
                          )}
                          <BenefitItem
                            text={`${course.duration} Minutos`}
                            image="time.png"
                          />
                          <BenefitItem
                            text={
                              <span className="text-capitalize">
                                Nivel {course.level || "Intermedio"}
                              </span>
                            }
                            image="dificulty.png"
                          />
                          <BenefitItem
                            text={`${formatMinutes(
                              course.duration
                            )} de Pintura`}
                            image="art-icon.png"
                            width={18}
                            height={18}
                          />
                          <BenefitItem
                            text={
                              <span className="text-capitalize">
                                idioma: {course.language || "Español"}
                              </span>
                            }
                            image="language.png"
                          />
                          <BenefitItem
                            text={
                              course.lifeTime
                                ? "Curso con acceso ilimitado"
                                : ""
                            }
                            image="infinite.png"
                            height={12}
                          />
                          {course?.benefit1 && (
                            <div className="d-flex align-items-center my-2 text-capitalize">
                              <TiInputChecked size={24} />
                              {course?.benefit1}
                            </div>
                          )}
                          {course?.benefit2 && (
                            <div className="d-flex align-items-center my-2 text-capitalize">
                              <TiInputChecked size={24} />
                              {course?.benefit2}
                            </div>
                          )}
                          {course?.benefit3 && (
                            <div className="d-flex align-items-center my-2 text-capitalize">
                              <TiInputChecked size={24} />
                              {course?.benefit3}
                            </div>
                          )}
                          {course?.benefit4 && (
                            <div className="d-flex align-items-center my-2 text-capitalize">
                              <TiInputChecked size={24} />
                              {course?.benefit4}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <p className="fw-bold fs-5 mt-4">Resumen:</p>
                      <p className="py-1 wsp">
                        {firstCharUpperCase(course.summary)}
                      </p>
                    </div>
                  </Tab>
                  <Tab eventKey="contenido" title="Contenido">
                    <Accordion activeKey={activeKey} alwaysOpen>
                      {course.sections.map((section) => (
                        <Accordion.Item
                          key={section.name}
                          eventKey={section.name}
                        >
                          <Accordion.Header>
                            <div>
                              <p className="mb-0 fw-bold text-capitalize">
                                {section.name}
                              </p>
                              <p className="mb-0 mt-2">
                                Capítulos: {section.chapters.length}
                              </p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {section.chapters.map((chapter) => (
                              <div
                                key={chapter.name}
                                className="d-flex align-items-center justify-content-between my-2 px-5 py-4 bg-gray rounded-3"
                              >
                                <div className="fw-bold text-capitalize">
                                  {chapter.name}
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="me-5">
                                    {formatMinutesToTime(chapter.duration)}
                                  </div>
                                  {courseBuyed && (
                                    <Button
                                      disabled={!chapter.videoId}
                                      className="rounded-5 purple-gradient border-0 text-white fw-bold px-4 pointer py-1"
                                    >
                                      Ver
                                    </Button>
                                  )}
                                </div>
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </Tab>
                  <Tab eventKey="trabajos" title="Trabajos">
                    <div className="p-2 px-4">
                      <Row xs={1} md={2} xxl={3}>
                        {course.showcase.map((work, index) => (
                          <Col
                            md={6}
                            key={index}
                            className="d-flex align-items-stretch justify-content-center"
                          >
                            <Card className="border-0 rounded-0 overflow-hidden mt-2 mx-0 w-100">
                              <Card.Img
                                variant="top"
                                src={work.image}
                                className="rounded-0 course-image"
                              />
                              <Card.Body className="pb-0 px-1">
                                <Card.Title>
                                  <p className="fw-bold d-inline mb-0 fs-normal">
                                    {index + 1}
                                  </p>
                                  -{" "}
                                  <p className="d-inline mb-0 text-capitalize fs-normal">
                                    {work.name}
                                  </p>
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Tab>
                  <Tab eventKey="testimonios" title="Testimonios">
                    <div className="p-3">
                      <Row xs={1} md={2} xxl={3}>
                        {course.testimonials.map((testimonio, index) => (
                          <Col
                            md={6}
                            key={index}
                            className="d-flex align-items-stretch justify-content-center"
                          >
                            <Card className="border-0 rounded-0 overflow-hidden mt-2 mx-0 w-100">
                              <Card.Img
                                variant="top"
                                src={testimonio?.image}
                                className="rounded-0 course-image"
                              />
                              <Card.Body className="pb-0 px-1">
                                <Card.Title>
                                  <p className="fw-bold d-inline mb-0 fs-normal">
                                    {index + 1}
                                  </p>
                                  -{" "}
                                  <p className="d-inline mb-0 text-capitalize fs-normal">
                                    {testimonio?.user}
                                  </p>
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            )}
          </Col>
          <Col lg={4} xxl={3} className="mb-5">
            <div className="border border-dark rounded-4 py-4 mx-4 d-flex flex-column justify-content-center align-items-center">
              <img
                src="/artist-icon.png"
                alt="artista-leenith"
                className="img-fluid mb-3"
              />
              <div className="text-center fw-bold">
                <p className="mb-1">Clase Impartida por</p>
                <p className="mb-1 fs-3 text-capitalize">{course.author}</p>
              </div>
            </div>
            {/* {!courseBuyed && (
              <div className="border border-dark rounded-4 py-4 mx-4 mt-4 d-flex flex-column justify-content-center align-items-center">
                <p className="text-center fw-bold fs-5 my-3">
                  Recomendadas para ti:
                </p>
                <div className="w-100 px-4">
                  <Card className="border-0 rounded-4 overflow-hidden my-4 w-100">
                    <Card.Img
                      variant="top"
                      src={recommendedCourse.image}
                      className="rounded-0 course-image"
                    />
                    <Card.Body className="bg-gray p-3">
                      <div className="text-purple-l text-capitalize d-flex mb-3">
                        <img
                          src="/dificulty.png"
                          className="me-1 image-fluid align-self-center"
                          width={16}
                          height={16}
                          alt="recomended-course"
                        />
                        <p className="mb-0">
                          Dificultad: {recommendedCourse.level}
                        </p>
                      </div>
                      <Card.Title className="text-purple-l fw-bold text-capitalize pt-2">
                        {recommendedCourse.name}
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer className="border-0 d-flex justify-content-between overflow-hidden p-3 flex-wrap bg-gray">
                      <div className="text-purple-l text-capitalize d-flex mt-auto mb-auto">
                        <img
                          src="/time.png"
                          className="me-1 image-fluid align-self-center"
                          width={16}
                          height={16}
                          alt="recomended-course"
                        />
                        <p className="mb-0">
                          {formatMinutes(recommendedCourse.duration)}
                        </p>
                      </div>
                      <div className="text-purple-l text-capitalize d-flex mt-auto mb-auto">
                        <img
                          src="/sections.png"
                          className="me-1 image-fluid align-self-center"
                          width={16}
                          height={16}
                          alt="recomended-course"
                        />
                        <p className="mb-0">
                          {recommendedCourse.sections} Secciones
                        </p>
                      </div>
                    </Card.Footer>
                  </Card>
                </div>
              </div>
            )} */}
          </Col>
        </Row>
      </Container>
      {/* <Subscribe /> */}
    </>
  );
};

export { Course };

const BenefitItem = ({ text, image, height, width }) => {
  return (
    <div className="d-flex align-items-center my-2">
      <img
        src={`/${image}`}
        className="me-2"
        width={width ?? 18}
        height={height ?? 18}
        alt="art-icon"
      />
      {text}
    </div>
  );
};
