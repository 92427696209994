import { useContext } from "react";
import { CartContext } from "../store/CartContext";

const useCart = () => {
  const { items, setItems } = useContext(CartContext);

  const checkItemInCheckout = (id) => {
    const item = items?.filter((i) => i.id === id);
    return !!item.length;
  };

  const addItem = (newItem) => {
    if (!checkItemInCheckout(newItem.id)) {
      const cart = [];

      if (newItem?.metadata?.isMembership) {
        cart.push(...[...items, { ...newItem, ...newItem.metadata }]);
      } else {
        cart.push(...[...items, newItem]);
      }

      setItems(cart);
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  };

  const removeItem = (id) => {
    const cart = items?.filter((i) => i.id !== id);
    setItems(cart);
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  const clearCart = () => {
    setItems([]);
    localStorage.removeItem("cart");
  };

  return { items, addItem, checkItemInCheckout, removeItem, clearCart };
};

export { useCart };
