import {
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { Cover, Loader, ModalComponent } from "../components";
import { formatNumber, validateMembership } from "../utils";
import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";
import { useFetch } from "../hooks";

const benefits = [
  { text: "Clase nueva cada semana" },
  { text: "50 clases disponibles y contando" },
  {
    text: "Contenido Exclusivo",
  },
  {
    text: "Clases en vivo exclusivas para suscriptores",
  },
  { text: "Reseña de productos" },
  { text: "Lanzamientos Anticipados" },
  { text: "Descuentos Especiales" },
  { text: "Tras cámaras" },
  { text: "y Mucho mas..." },
];

const previews = [
  {
    videoID: "942397391",
    image: "welcome",
    eventKey: "preview-1",
    text: "Únete a la comunidad de amantes del arte mas grande de Latinoamérica, aprende técnicas de pintura y de arte moderno de una manera facil, practica y sencilla junto a Leenith Borges.",
  },
  {
    videoID: "942397252",
    image: "clase-nueva",
    eventKey: "preview-2",
    text: "Incia tu experiencia con la pintura y el arte, mejora tus técnicas, habilidades. Observa los videos y aprende a la hora que quieras en donde tu prefieras.",
  },
];

const Memberships = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const functions = getFunctions();
  const getProducts = httpsCallable(functions, "getProducts");
  const createSession = httpsCallable(functions, "createSession");
  const [memberships, setMemberships] = useState([]);
  const [isValidMembership, setIsValidMembership] = useState(false);
  const [courses, setCourses] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { docs: coursesFromDB } = useFetch("courses");

  useEffect(() => {
    const getMemberships = async () => {
      try {
        const isValid = await validateMembership();
        setIsValidMembership(isValid);
        if (isValid) return;

        if (user?.membership?.createdAt) {
          setModalShow(true);
        }

        const response = await getProducts();
        setMemberships(
          response.data
            .filter((item) => item.active)
            .sort((a, b) => a.metadata.price - b.metadata.price)
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getMemberships();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterMembershipCourses = async () => {
      try {
        const filteredCourses = coursesFromDB.filter(
          (course) => course.isActive && course.isFromMembership
        );

        setCourses(
          filteredCourses.sort(
            (a, b) => Number(a?.position) - Number(b?.position)
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isValidMembership) filterMembershipCourses();
  }, [coursesFromDB, isValidMembership]);

  const handleSubscription = async (membership) => {
    const mmship = membership
      ? membership
      : memberships.find(
          (membership) => membership.name === user.membership.name
        );

    const { data } = await createSession({ id: mmship?.default_price });
    if (data?.error) return;

    window.open(data?.session?.url, "_self");
    setModalShow(false);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Container fluid id="memberships">
        <Cover text="Suscripciones" />
        <Row className="my-5">
          <h3 className="text-center text-purple-l fw-bold fs-2">
            Area Exclusiva Suscriptores
          </h3>
        </Row>
        {isValidMembership ? (
          <Row className="mt-3">
            <Col md={{ span: 10, offset: 1 }}>
              <Row xs={1} md={3}>
                <>
                  {courses.map((course) => (
                    <Col
                      md={4}
                      key={course.id}
                      className="d-flex align-items-stretch justify-content-center"
                    >
                      <Card className="border-0 rounded-4 overflow-hidden mb-5 mx-0 w-100">
                        <Link to={`/cursos/${course?.customUrl}`}>
                          <Card.Img
                            variant="top"
                            src={course.cover}
                            className="rounded-0 course-image"
                          />
                        </Link>
                        <Card.Body>
                          <Card.Title className="text-purple-l fw-bold fs-4 text-capitalize text-center">
                            <Link
                              to={`/cursos/${course?.customUrl}`}
                              className="text-purple-l text-decoration-none"
                            >
                              {course.name}
                            </Link>
                          </Card.Title>
                        </Card.Body>
                        <Card.Footer className="border-0 d-flex justify-content-center overflow-hidden fs-5 px-3 pb-3 flex-wrap">
                          <Link
                            to={`/cursos/${course?.customUrl}`}
                            className={`btn rounded-pill purple-gradient border-0 text-white fw-bold px-5 pointer text-decoration-none py-2 m-auto`}
                          >
                            Ver Curso
                          </Link>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row className="mt-3">
            <Col md={{ span: 12 }} className="mb-5">
              <Tab.Container
                id="left-tabs-container"
                defaultActiveKey="preview-1"
                className="my-5"
              >
                <Row md={2} className="px-5">
                  <Col md={12} xxl={7}>
                    <Tab.Content>
                      {previews.map((preview) => (
                        <Tab.Pane
                          key={preview.eventKey}
                          eventKey={preview.eventKey}
                          className="border border-0 mb-5"
                        >
                          <div className="ratio ratio-16x9">
                            {/* <iframe
                              width="100%"
                              height="315"
                              className="rounded-5"
                              src={
                                "https://www.youtube.com/embed/" +
                                preview.videoID
                              }
                              title="YouTube video player"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                              allowFullScreen
                            ></iframe> */}
                            <iframe
                              src={`https://player.vimeo.com/video/${preview.videoID}?badge=0&autopause=0&player_id=0&app_id=58479`}
                              frameborder="0"
                              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                              className="rounded-5"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "315",
                              }}
                              title="Video 1 - Suscripcion"
                            ></iframe>
                          </div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                  <Col md={12} xxl={5}>
                    <Nav className="flex-column">
                      {previews.map((preview) => (
                        <div className="memberships-previews">
                          <Nav.Item
                            key={preview.eventKey}
                            className="d-flex justify-content-center align-items-center my-2"
                          >
                            <Nav.Link
                              eventKey={preview.eventKey}
                              className="text-black bg-white nav-link position-relative p-0"
                            >
                              <img
                                src="./play-icon.png"
                                className="rounded-circle position-absolute top-50 start-50 translate-middle bg-white"
                                width={36}
                                height={36}
                                alt="play-botton"
                              />
                              <img
                                src={`/${preview.image}.png`}
                                className="h-100 w-100 rounded-5 px-2"
                                alt="example"
                              />
                            </Nav.Link>
                          </Nav.Item>
                          <p className="ps-1 py-2">{preview.text}</p>
                        </div>
                      ))}
                    </Nav>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
            <Col xl={{ span: 8, offset: 2 }} className="my-5">
              <Row xs={1} md={2}>
                {memberships.map((membership) => (
                  <Col
                    key={membership.id}
                    className="d-flex align-items-stretch justify-content-center"
                  >
                    <Card className="rounded-4 overflow-hidden m-3 border-dark">
                      <Card.Body
                        className={`${membership?.metadata?.classname} text-center`}
                      >
                        <Card.Title className="fs-4">Suscripción</Card.Title>
                        <Card.Title className="fs-4">
                          {membership?.metadata?.type}
                        </Card.Title>
                        <Card.Text className="fs-2 mt-4 fw-bold">
                          {formatNumber(membership?.metadata?.price)} USD
                        </Card.Text>
                      </Card.Body>
                      <ListGroup className="border-0 px-4 py-3 rounded-0">
                        {benefits.map((benefit, index) => (
                          <ListGroup.Item key={index} className="py-3">
                            <img
                              src="acceso-a-videos.png"
                              className="me-2 image-fluid"
                              width={18}
                              height={18}
                              alt="art-icon"
                            />
                            {benefit.text}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                      <Card.Footer className="bg-white py-4 m-auto">
                        {user ? (
                          <Button
                            className="py-2 px-5 rounded-pill purple-gradient border-0"
                            onClick={() => handleSubscription(membership)}
                          >
                            Comprar Suscripción
                          </Button>
                        ) : (
                          <Link to="/auth">
                            <Button
                              variant="info"
                              className="border-0 px-5 rounded-pill"
                            >
                              Iniciar Sesión
                            </Button>
                          </Link>
                        )}
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )}
        <ModalComponent
          show={modalShow}
          onHide={() => setModalShow(false)}
          title="Disfruta de tu Suscripción"
        >
          <Row className="d-flex justify-content-center align p-3">
            <Col xs={12} lg={8}>
              <div className="mb-3 py-3">
                <h4>
                  Para continuar viendo tu contenido por favor actualiza tus
                  datos.
                </h4>
              </div>
            </Col>
            <Col xs={12} className="d-flex justify-content-center">
              <Button
                className="py-2 px-5 rounded-pill mt-3 purple-gradient border-0"
                onClick={() => handleSubscription(null)}
              >
                Actualizar
              </Button>
            </Col>
          </Row>
        </ModalComponent>
      </Container>
      {/* <Subscribe /> */}
    </>
  );
};

export { Memberships };
