import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";

const Question = () => {
  const functions = getFunctions();
  const askQuestion = httpsCallable(functions, "askQuestion");

  const [contact, setContact] = useState("");
  const [question, setQuestion] = useState("");
  const [error, setError] = useState("");
  const [sucess, setSuccess] = useState("");

  const handleContactField = ({ target }) => {
    const contact = target.value;

    setContact(contact);
  };

  const handleQuestionField = ({ target }) => {
    const question = target.value;

    setQuestion(question);
  };

  const askQuestionHandler = async (e) => {
    e.preventDefault();

    // Validate info not empty
    if (!contact || !question) {
      return setError("Todos los campos son requeridos.");
    }

    e.target.disabled = true;
    // Clean last error
    setError("");
    setSuccess("");

    //Ask question Api Handler
    try {
      await askQuestion({
        contact,
        question,
      });
      setSuccess("Pregunta enviada a Leenith.");
      e.target.disabled = false;
    } catch (err) {
      setError("Algo salió mal, intentalo de nuevo.");
      e.target.disabled = false;
    }
  };

  return (
    <Container fluid className="px-0 mx-0 purple-gradient">
      <Row className="mx-0">
        <Col
          md={{ span: 6, offset: 3 }}
          className="text-white text-center mt-5 mb-5"
        >
          <h3>¿Tu pregunta no está aquí?</h3>
          <p>Escribenos tu pregunta aqui</p>
          <input
            type="text"
            id="question"
            className="form-control rounded-pill"
            name="question"
            onChange={handleQuestionField}
            placeholder="Escribe tu pregunta"
            size={50}
          />
          <div className="d-flex mt-3">
            <input
              type="text"
              id="contact"
              className="form-control rounded-start-pill"
              name="contact"
              onChange={handleContactField}
              placeholder="Escribe tu correo o número telefónico"
              size={50}
            />
            <button
              className="btn bg-purple-l text-white rounded-end-pill px-5"
              onClick={askQuestionHandler}
            >
              Enviar
            </button>
          </div>
          {sucess && <p className="text-success text-start">{sucess}</p>}
          {error && <p className="text-red text-start">* {error}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export { Question };
