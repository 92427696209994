import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Loader } from "../components";
import { useFetch } from "../hooks";

const MemberList = () => {
  const { docs: users, isLoading } = useFetch("users");
  const [usersToDisplay, setUsersToDisplay] = useState([]);

  useEffect(() => {
    setUsersToDisplay(users);
  }, [users]);

  const handleSearch = ({ target }) => {
    const { value } = target;
    const usersFiltered = users.filter(
      (user) => user?.name?.includes(value) || user?.email?.includes(value)
    );

    setUsersToDisplay(usersFiltered);
  };

  if (isLoading) return <Loader />;

  return (
    <Container fluid>
      <Row className="mt-5 mb-3">
        <Col md={{ span: 4, offset: 7 }} lg={{ span: 3, offset: 8 }}>
          <div className="d-flex align-items-end justify-content-end">
            <label className="form-label fw-bold me-3" htmlFor="name">
              Buscar:
            </label>
            <input
              type="text"
              id="name"
              className="form-control rounded-pill"
              name="name"
              onChange={handleSearch}
              placeholder="Buscar por nombre o correo"
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={{ span: 10, offset: 1 }}>
          <p className="bg-purple-m text-white py-3 ps-4 mb-0 fw-bold">
            Lista de Miembros
          </p>
          <Table striped className="border border-green">
            <thead>
              <tr>
                <th>#No. Miembro</th>
                <th>Nombre</th>
                <th>Fecha Inscripción</th>
                <th>Correo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {usersToDisplay.map((user, index) => (
                <tr key={user.id} className="py-4 align-middle">
                  <td># {index + 1}</td>
                  <td className="text-capitalize">{user.name}</td>
                  <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                  <td>{user.email}</td>
                  <td>
                    <Link to={`/admin/miembros/${user.id}`}>
                      <Button
                        className="py-1 px-5 rounded-pill"
                        variant="secondary"
                      >
                        Ver Miembro
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
export default MemberList;
export { MemberList };
