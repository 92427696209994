import { useState } from "react";
import { Button } from "react-bootstrap";
import { TiDelete, TiMinus, TiPencil, TiPlus } from "react-icons/ti";

const Testimonials = (props) => {
  const {
    showTestimonialForm,
    setShowTestimonialForm,
    newTestimonial,
    setNewTestimonial,
    showShowcaseForm,
    testimonials,
    addTestimonial,
    editTestimonial,
    removeTestimonial,
  } = props;

  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className={`${showShowcaseForm ? "pt-5" : ""} mt-5 w-75`}>
      <div className="d-flex justify-content-between align-items-center text-purple-m mt-4">
        <p className="fw-bold mb-0 fs-5">Testimonios</p>
        {showTestimonialForm ? (
          <TiMinus
            className="fs-4 pointer"
            onClick={() => setShowTestimonialForm(false)}
          />
        ) : (
          <TiPlus
            className="fs-4 pointer"
            onClick={() => setShowTestimonialForm(true)}
          />
        )}
      </div>
      <div className="mt-4">
        {testimonials.map((testimonio, i) => (
          <div key={i}>
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 text-capitalize fw-bold">
                - {testimonio.user}
              </p>{" "}
              <span>
                <TiPencil
                  className="pointer"
                  onClick={() => {
                    setIsEdit(true);
                    setShowTestimonialForm(true);
                    editTestimonial(i);
                  }}
                />
                <TiDelete
                  size={20}
                  className="text-red ms-2 pointer"
                  onClick={() => removeTestimonial(i)}
                />
              </span>
            </div>
            <div>
              <img
                src={testimonio.image}
                className="img-fluid my-3"
                width={200}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
      {showTestimonialForm && (
        <div className="ps-4 mt-4">
          <div className="mt-4">
            <label className="form-label fw-bold" htmlFor="testimonial-user">
              Usuario
            </label>
            <input
              type="text"
              id="testimonial-user"
              className="form-control rounded-pill"
              name="testimonial-user"
              value={newTestimonial.user}
              onChange={({ target }) =>
                setNewTestimonial({
                  ...newTestimonial,
                  user: target.value,
                })
              }
              placeholder="Escribe el nombre del usuario"
            />
          </div>
          {!isEdit && (
            <div className="mt-4">
              <label className="form-label fw-bold" htmlFor="testimonial-image">
                Imagen
              </label>
              <input
                type="file"
                id="testimonial-image"
                className="form-control"
                name="testimonial-image"
                onChange={({ target }) =>
                  setNewTestimonial({
                    ...newTestimonial,
                    image: target.files[0],
                  })
                }
                placeholder="Agrega la imagen del testimonio"
              />
            </div>
          )}
          {newTestimonial.user && newTestimonial.image && (
            <Button
              className="bg-purple-m rounded-pill fw-bold px-3 border-none mt-4 float-end"
              onClick={() => {
                addTestimonial();
                setIsEdit(false);
              }}
            >
              {isEdit ? "Actualizar" : "Agregar"} Testimonio
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Testimonials;
export { Testimonials };
