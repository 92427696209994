import React, { useState } from "react";
import { Button, Col, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import { TiArrowLeft } from "react-icons/ti";
import { Link } from "react-router-dom";
import { formatNumber } from "../utils";
import { useCart } from "../hooks";
import { Cover } from "../components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  addDocInCollection,
  getUserCourses,
  updateDocInCollection,
} from "../services";
import CheckoutCard from "../components/Checkout/CheckoutCard";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckOut = () => {
  const [key, setKey] = useState("personal-data");
  const { items, clearCart } = useCart();
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));

  const getTotal = () => {
    return items.reduce((acc, item) => {
      return acc + (Number(item.price) * (100 - Number(item.discount))) / 100;
    }, 0);
  };

  const handleSuccessPayment = async () => {
    try {
      // get user courses from db
      const courses = await getUserCourses(user.id);

      //set course ids or membership to user
      items.forEach((item) => {
        if (!item?.isMembership) {
          courses.push(item.id);
        } else {
          user.membership = {
            id: item.id,
            isActive: true,
            name: item.name,
            price: item?.price ?? 0,
            durationInMonths: item.duration,
            startDate: new Date(),
            endDate: new Date(
              new Date().setMonth(new Date().getMonth() + Number(item.duration))
            ),
            createdAt: Date.now(),
          };
        }
      });

      const userToUpdate = {
        ...user,
        membership: { ...user?.membership },
        courses,
      };

      await updateDocInCollection("users", user.id, userToUpdate);
      localStorage.setItem("userDetails", JSON.stringify(userToUpdate));

      // add order to db
      await handleOrder();

      // move to next tab
      setKey("confirmation");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrder = async () => {
    try {
      items.map(async (item) => {
        const { id, name, price, discount, productId, cover } = item;

        const newOrder = {
          type: item?.sections?.length
            ? "curso"
            : item.isLive
            ? "live"
            : item.isMembership
            ? "membresia"
            : "reto",
          product: {
            id,
            name,
            price: (Number(price) * (100 - Number(discount))) / 100,
            productId: productId || id,
            cover,
          },
          user: {
            id: user.id,
            email: user.email,
          },
          status: "confirmed",
          createdAt: Date.now(),
        };

        await addDocInCollection("orders", newOrder);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: "es",
        currency: "mxn",
      }}
    >
      <Container fluid id="checkout">
        <Cover text="Pago" />
        <Row>
          <Col md={{ span: 8, offset: 1 }} xl={{ span: 6, offset: 2 }}>
            <Tabs id="checkout-tabs" activeKey={key} className="mb-3">
              <Tab
                eventKey="personal-data"
                title="1. Datos Personales"
                className="rounded-end"
              >
                <Row>
                  <h5 className="pt-3 mt-5 fw-bold">Datos Personales</h5>
                  <hr />
                  <Col md={12}>
                    <Col md={{ span: 6 }} className="py-3">
                      <div>
                        <label
                          className="form-label text-purple-l fw-bold"
                          htmlFor="name"
                        >
                          Nombre Completo
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="form-control rounded-pill"
                          defaultValue={user?.name}
                          placeholder="Escribe tu nombre"
                          readOnly
                        />
                      </div>
                      <div className="my-3">
                        <label
                          className="form-label text-purple-l fw-bold"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          placeholder="Escribe tu email"
                          className="form-control rounded-pill"
                          defaultValue={user?.email}
                          readOnly
                        />
                      </div>
                      <div>
                        <label
                          className="form-label text-purple-l fw-bold"
                          htmlFor="phone"
                        >
                          Telefono
                        </label>
                        <input
                          type="phone"
                          id="phone"
                          className="form-control rounded-pill"
                          defaultValue={user?.phone}
                          placeholder="Escribe tu telefono"
                          readOnly
                        />
                      </div>

                      <Button
                        variant="info"
                        className="border-0 px-5 my-5 rounded-pill"
                        onClick={() => setKey("pay")}
                      >
                        Continuar
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="pay" title="2. Pago">
                <CheckoutCard handleSuccessPayment={handleSuccessPayment} />
              </Tab>
              <Tab eventKey="confirmation" title="3. Confirmación">
                <Row className="bg-gray rounded-3 mt-5">
                  <Col className="d-flex justify-content-center align-items-center fs-5 fw-bold text-purple-l">
                    !Tu pago ha sido exitoso!
                  </Col>
                  <Col>
                    <img
                      src="./carousel-1.jpg"
                      className="img-fluid"
                      alt="success-pay"
                    />
                  </Col>
                </Row>
                <Button
                  variant="info"
                  className="border-0 px-5 rounded-pill my-5 float-end"
                  onClick={() => setKey("start-now")}
                >
                  !Empieza ahora!
                </Button>
              </Tab>
              <Tab eventKey="start-now" title="!Empieza ahora!">
                <Row>
                  <Col md={12}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Producto:</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <img
                                src={item.cover}
                                className="img-fluid"
                                width={80}
                                height={50}
                                alt=""
                              />
                            </td>
                            <td className="py-5 text-capitalize fw-bold">
                              {item.name}
                            </td>
                            <td className="py-5">
                              {item?.isMembership ? (
                                <Link to="/suscripciones">
                                  <Button
                                    className="bg-purple-m border-0 px-5 rounded-pill"
                                    onClick={() => clearCart()}
                                  >
                                    Ver contenido
                                  </Button>
                                </Link>
                              ) : (
                                <Link
                                  to={`/${
                                    item?.sections?.length ? "cursos" : "retos"
                                  }/${item?.customUrl}`}
                                >
                                  <Button
                                    className="bg-purple-m border-0 px-5 rounded-pill"
                                    onClick={() => clearCart()}
                                  >
                                    Empezar
                                  </Button>
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Link to="/cursos">
                      <Button
                        variant="info"
                        className="border-0 px-3 float-end me-2 my-4 rounded-pill"
                        onClick={() => clearCart()}
                      >
                        Seguir comprando
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
          {(key === "personal-data" || key === "pay") && (
            <Col xl={{ span: 2 }} className="mt-5">
              <p>Resumen del pedido</p>
              <p className="border-top border-secondary py-4 d-flex justify-content-between px-2">
                <span>Total:</span>
                <span className="fw-bold">{formatNumber(getTotal())}</span>
              </p>
            </Col>
          )}
        </Row>
        {(key === "personal-data" || key === "pay") && (
          <Row>
            <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
              <hr />
            </Col>
            <Col
              lg={{ span: 10, offset: 1 }}
              xl={{ span: 8, offset: 2 }}
              className="d-flex justify-content-between mt-3 mb-5"
            >
              {key === "personal-data" ? (
                <Link to="/carrito">
                  <Button className="bg-purple-m border-0 ps-4 pe-5 rounded-pill">
                    <TiArrowLeft size={24} />
                    Regresar
                  </Button>
                </Link>
              ) : (
                <Button
                  className="bg-purple-m border-0 ps-4 pe-5 rounded-pill"
                  onClick={() => setKey("personal-data")}
                >
                  <TiArrowLeft size={24} />
                  Regresar
                </Button>
              )}
              {/* {key === "pay" ? (
              <Button
                variant="info"
                className="border-0 px-5 rounded-pill"
                onClick={() => (user ? handlePayment() : navigate("/auth"))}
              >
                {user ? "Pagar" : "Inicia sesión"}
              </Button>
            ) : null} */}
            </Col>
          </Row>
        )}
      </Container>
    </Elements>
  );
};

export default CheckOut;
export { CheckOut };
