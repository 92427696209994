import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Cover } from "../components";
import { getFunctions, httpsCallable } from "firebase/functions";

const Contact = () => {
  const functions = getFunctions();
  const askQuestion = httpsCallable(functions, "askQuestion");

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [sucess, setSuccess] = useState("");

  const handleFirstNameField = ({ target }) => {
    const firstName = target.value;

    setFirstName(firstName);
  };

  const handleSecondName = ({ target }) => {
    const secondName = target.value;

    setSecondName(secondName);
  };

  const handlePhoneField = ({ target }) => {
    const phone = target.value;

    setPhone(phone);
  };

  const handleEmailField = ({ target }) => {
    const email = target.value;

    setEmail(email);
  };

  const handleMessageField = ({ target }) => {
    const message = target.value;

    setMessage(message);
  };

  const contactHandler = async (e) => {
    e.preventDefault();
    // Validate info not empty
    if (!firstName || !secondName || !phone || !email || !message) {
      return setError("Todos los campos son requeridos.");
    }

    e.target.disabled = true;

    // Clean last error
    setError("");
    setSuccess("");

    //Ask question Api Handler
    try {
      await askQuestion({
        contact: `${firstName} ${secondName} - Tel: ${phone} - Email: ${email}`,
        question: message,
      });
      setSuccess("Mensaje enviado a Leenith.");
      e.target.disabled = false;
    } catch (err) {
      setError("Algo salió mal, intentalo de nuevo.");
      e.target.disabled = false;
    }
  };

  return (
    <>
      <Container fluid id="contact">
        <Cover text="Contacto" />
        <Row className="mt-4">
          <h3 className="text-center text-purple-l fw-bold fs-2">
            Contáctate con nosotros
          </h3>
          <Col md={{ span: 7 }} className="mt-5 px-5">
            <form>
              <Row className="border border-dark py-4 rounded-5">
                <Col md={{ span: 4 }}>
                  <h5 className="text-purple-l fw-bold">Teléfonos:</h5>
                  <div>
                    <p>(844) 355 1833</p>
                    <p>(844) 143 7025</p>
                  </div>
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="text-purple-l fw-bold">E-Mail:</h5>
                  <p className="text-break">contacto@leenithborges-arte.com</p>
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="text-purple-l fw-bold">Dirección:</h5>
                  <p className="mb-0">Blvd Jose Maria Rodriguez # 492</p>
                  <p className="mb-0">Colonia Portal del Aragon CP 25209</p>
                  <p>Saltillo Coahuila de Zaragoza, Mexico</p>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={{ span: 9 }}>
                  <Row className="py-1">
                    <Col md={{ span: 6 }} className="mb-3">
                      <h5 className="text-purple-l fw-bold">Nombre:</h5>
                      <input
                        type="text"
                        id="name"
                        className="form-control rounded-pill border border-dark"
                        name="name"
                        placeholder="Tu nombre aquí"
                        onChange={handleFirstNameField}
                      />
                    </Col>
                    <Col md={{ span: 6 }} className="mb-3">
                      <h5 className="text-purple-l fw-bold">Apellido:</h5>
                      <input
                        type="text"
                        id="last_name"
                        className="form-control rounded-pill border border-dark"
                        name="last_name"
                        placeholder="Tu apellido aquí"
                        onChange={handleSecondName}
                      />
                    </Col>
                    <Col md={{ span: 6 }} className="mt-3">
                      <h5 className="text-purple-l fw-bold">Telefono:</h5>
                      <input
                        type="text"
                        id="phone"
                        className="form-control rounded-pill border border-dark"
                        name="phone"
                        placeholder="Tu teléfono aquí"
                        onChange={handlePhoneField}
                      />
                    </Col>
                    <Col md={{ span: 6 }} className="mt-3">
                      <h5 className="text-purple-l fw-bold">E-Mail:</h5>
                      <input
                        type="email"
                        id="email"
                        className="form-control rounded-pill border border-dark"
                        name="email"
                        placeholder="Tu e-mail aquí"
                        onChange={handleEmailField}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={{ span: 3 }} className="d-flex px-0">
                  <Row className="py-1 h-full w-full flex-grow-1">
                    <Col className="d-flex flex-column ps-3">
                      <h5 className="text-purple-l fw-bold">Mensaje:</h5>
                      <textarea
                        type="text"
                        id="message"
                        className="form-control rounded-5 border border-dark flex-grow-1 p-3"
                        name="message"
                        placeholder="Escribe tu mensaje aquí"
                        onChange={handleMessageField}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {sucess && <p className="text-success text-start">{sucess}</p>}
              {error && <p className="text-red text-start">* {error}</p>}
              <Row className="mt-4">
                <Col md={{ span: 12 }} className="d-flex justify-content-end">
                  <button
                    onClick={contactHandler}
                    className="btn bg-purple-m text-white rounded-pill px-5 my-2"
                  >
                    Enviar
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
          <Col md={5} className="d-flex flex-column justify-content-end">
            <img
              src="./carousel-1.jpg"
              className="img-fluid"
              alt="leenit contacto"
            />
          </Col>
        </Row>
      </Container>
      {/* <Subscribe /> */}
    </>
  );
};
export { Contact };
