import { getDocFromCollection } from "../services";

/**
 * Transform number to string with 2 decimal 0.00, add $ sign and comma
 * @param {number} number
 * @returns {string}
 */
export const formatNumber = (number) => {
  const num = Number(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return `$ ${num}`;
};

/**
 * Transform double number to percentage string
 * @param {number} number
 * @returns {string}
 */
export const formatPercentage = (number) => {
  return `${(number * 100).toFixed(0)}%`;
};

/**
 * Transform number of minutes to string with hours and minutes
 * @param {number} number
 * @returns {string}
 */
export const formatMinutes = (number) => {
  const hours = Math.floor(number / 60);
  const minutes = number % 60;
  return `${hours}h ${minutes}m`;
};

/**
 * Format minutes to string 00:00:00
 * @param {number} number of minutes
 * @returns {string}
 */
export const formatMinutesToTime = (number) => {
  const hours = Math.floor(number / 60);
  const minutes = number % 60;
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:00`;
};

/**
 * Format first char to uppercase in a sentence
 * @param {string} string
 * @returns {string}
 */
export const firstCharUpperCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Check for admin user
export const isAdmin = JSON.parse(localStorage.getItem("userDetails"))?.isAdmin;

// Format firebase date
export const formatDate = (date) => {
  if (!date) return null;

  const formattedDate = date?.seconds
    ? new Date(date?.seconds * 1000 + date?.nanoseconds / 1000000)
    : new Date(date);

  return formattedDate;
};

// Validate user from firestore
export const validateMembership = async (id) => {
  let isValid = false;

  const idToVeirfy = id || JSON.parse(localStorage.getItem("userDetails"))?.id;

  if (!idToVeirfy) isValid = false;

  const userFromDB =
    idToVeirfy && (await getDocFromCollection("users", idToVeirfy));

  if (!userFromDB?.name) isValid = false;

  if (userFromDB?.membership?.endDate) isValid = true;

  if (!userFromDB?.membership?.isActive) isValid = false;

  return isValid;
};
