import { useState } from "react";
import { Button } from "react-bootstrap";
import { TiDelete, TiMinus, TiPencil, TiPlus } from "react-icons/ti";

const Showcase = ({
  showShowcaseForm,
  setShowShowcaseForm,
  showcase,
  newExample,
  setNewExample,
  addExample,
  editExample,
  removeExample,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className="mt-5 w-75">
      <div className="d-flex justify-content-between align-items-center text-purple-m mt-4">
        <p className="fw-bold mb-0 fs-5">Ejemplos de Trabajos</p>
        {showShowcaseForm ? (
          <TiMinus
            className="fs-4 pointer"
            onClick={() => setShowShowcaseForm(false)}
          />
        ) : (
          <TiPlus
            className="fs-4 pointer"
            onClick={() => setShowShowcaseForm(true)}
          />
        )}
      </div>
      <div className="mt-4">
        {showcase.map((item, i) => (
          <div key={i}>
            <div className="d-flex justify-content-between align-items-center pt-3">
              <p className="mb-0 text-capitalize fw-bold">- {item.name}</p>
              <span>
                <TiPencil
                  className="pointer"
                  onClick={() => {
                    setIsEdit(true);
                    setShowShowcaseForm(true);
                    editExample(i);
                  }}
                />
                <TiDelete
                  size={20}
                  className="text-red ms-2 pointer"
                  onClick={() => removeExample(i)}
                />
              </span>
            </div>
            <div>
              <img
                src={item.image}
                className="img-fluid my-3"
                width={200}
                alt=""
              />
            </div>
            <hr />
          </div>
        ))}
      </div>
      {showShowcaseForm && (
        <div className="ps-4 mb-4">
          <div className="mt-4">
            <label className="form-label fw-bold" htmlFor="example-name">
              Nombre
            </label>
            <input
              type="text"
              id="example-name"
              className="form-control rounded-pill"
              name="example-name"
              value={newExample.name}
              onChange={({ target }) =>
                setNewExample({
                  ...newExample,
                  name: target.value,
                })
              }
              placeholder="Escribe el nombre del ejemplo"
            />
          </div>
          {!isEdit && (
            <div className="mt-4">
              <label className="form-label fw-bold" htmlFor="example-image">
                Imagen
              </label>
              <input
                type="file"
                id="example-image"
                className="form-control"
                name="example-image"
                onChange={({ target }) =>
                  setNewExample({
                    ...newExample,
                    image: target.files[0],
                  })
                }
                placeholder="Agrega la imagen del ejemplo"
              />
            </div>
          )}
          {newExample.name && newExample.image && (
            <Button
              className="bg-purple-m rounded-pill fw-bold px-3 border-none mt-4 float-end"
              onClick={() => {
                addExample();
                setIsEdit(false);
              }}
            >
              {isEdit ? "Actualizar" : "Agregar"} Ejemplo
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Showcase;
export { Showcase };
