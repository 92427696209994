import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFetch } from "../hooks";
import { formatNumber } from "../utils";
import { Cover } from "../components";
import { useEffect, useState } from "react";
import { getUserCourses } from "../services";
import { AddToCartButton } from "../components/Cart/AddToCartButton";

const Challenges = () => {
  const { docs: challenges, isLoading } = useFetch("challenges");
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));
  const [userCourses, setUserCourses] = useState([]);
  const [clases, setClases] = useState([]);
  const [lives, setLives] = useState([]);

  useEffect(() => {
    const clasesArray = challenges.filter(
      (clase) => !clase.isLive && clase.isActive
    );
    setClases(
      clasesArray.sort((a, b) => Number(a?.position) - Number(b?.position))
    );

    const livesArray = challenges.filter(
      (clase) => clase.isLive && clase.isActive
    );
    setLives(
      livesArray.sort((a, b) => Number(a?.position) - Number(b?.position))
    );
  }, [challenges]);

  useEffect(() => {
    const getCoursesFromUser = async () => {
      try {
        const coursesFromDB = await getUserCourses(user.id);
        setUserCourses(coursesFromDB);
      } catch (err) {
        console.log(err.message);
      }
    };

    if (user?.id) getCoursesFromUser();
  }, [user]);

  const isBuyed = (id) => userCourses.includes(id);

  if (!challenges?.length) {
    return (
      <Container fluid id="challenges">
        <Cover text="Retos / Lives" />
        <Row className="my-5 py-5 d-flex justify-content-center align-items-center">
          <p className="fw-bold fs-4 text-purple-m text-center my-5 py-5">
            Estamos creando Retos y Lives para ti, mantente atenta.
          </p>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid id="challenges">
      <Cover text="Retos / Lives" />
      {!!clases.length && (
        <Row className="mt-5">
          <Col md={{ span: 10, offset: 1 }}>
            <h3 className="text-purple-l text-center fw-bold mt-3 fs-2">
              Retos
            </h3>
            <Row xs={1} md={3}>
              {!isLoading && (
                <>
                  {clases.map((clase) => (
                    <Col
                      md={4}
                      key={clase.id}
                      className="d-flex align-items-stretch justify-content-center"
                    >
                      <Card className="border-0 rounded-4 overflow-hidden my-5 mx-0 w-100">
                        <Link to={`/retos/${clase.customUrl}`}>
                          <Card.Img
                            variant="top"
                            src={clase.cover}
                            className="rounded-0 course-image"
                          />
                        </Link>
                        <Card.Body>
                          <Card.Title className="text-purple-l fw-bold fs-4 text-capitalize text-center">
                            <Link
                              to={`/retos/${clase?.customUrl}`}
                              className="text-purple-l text-decoration-none"
                            >
                              {clase.name}
                            </Link>
                          </Card.Title>
                        </Card.Body>
                        <Card.Footer className="border-0 d-flex justify-content-center overflow-hidden fs-5 px-3 pb-3 flex-wrap">
                          {!isBuyed(clase.id) && (
                            <div className="d-flex flex-wrap mb-3 px-3">
                              {Number(clase.discount) > 0 ? (
                                <>
                                  <p className="fs-5 mb-0 align-self-center text-decoration-line-through mx-2 text-red">
                                    {formatNumber(clase.price)}
                                  </p>
                                  <p className="text-purple-l fw-bold mt-auto mb-auto">
                                    {formatNumber(
                                      (Number(clase.price) *
                                        (100 - Number(clase.discount))) /
                                        100
                                    )}{" "}
                                    USD
                                  </p>
                                </>
                              ) : (
                                <p className="text-purple-l fw-bold mt-auto mb-auto">
                                  {formatNumber(clase.price)} USD
                                </p>
                              )}
                            </div>
                          )}
                          {isBuyed(clase.id) ? (
                            <Link
                              to={`/retos/${clase?.customUrl}`}
                              className={`btn rounded-pill purple-gradient border-0 text-white fw-bold px-5 pointer text-decoration-none py-2 m-auto`}
                            >
                              Ver Reto
                            </Link>
                          ) : (
                            <div className="m-auto">
                              <AddToCartButton item={clase} />
                            </div>
                          )}
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </Col>
          {/* <Row className="mb-5 d-flex justify-content-center">
            <Button className="rounded-pill bg-purple-l border-0 text-white fw-bold px-5 pointer w-auto">
              Ver Más
            </Button>
          </Row> */}
        </Row>
      )}
      {!!lives.length && (
        <Row className="mt-5">
          <Col md={{ span: 10, offset: 1 }}>
            <h3 className="text-center text-purple-l fw-bold mt-3 fs-2">
              Lives
            </h3>
            <Row xs={1} md={3}>
              {!isLoading && (
                <>
                  {lives.map((live) => (
                    <Col
                      md={4}
                      key={live.id}
                      className="d-flex align-items-stretch justify-content-center"
                    >
                      <Card className="border-0 rounded-4 overflow-hidden my-5 mx-0 w-100">
                        <Link to={`/retos/${live?.customUrl}`}>
                          <Card.Img
                            variant="top"
                            src={live.cover}
                            className="rounded-0 course-image"
                          />
                        </Link>
                        <Card.Body>
                          <Card.Title className="text-purple-l fw-bold fs-4 text-capitalize text-center">
                            <Link
                              to={`/retos/${live?.customUrl}`}
                              className="text-purple-l text-decoration-none"
                            >
                              {live.name}
                            </Link>
                          </Card.Title>
                        </Card.Body>
                        <Card.Footer className="border-0 d-flex justify-content-center overflow-hidden fs-5 px-3 pb-3 flex-wrap">
                          {!isBuyed(live.id) && (
                            <div className="d-flex flex-wrap mb-3 px-3">
                              {Number(live.discount) > 0 ? (
                                <>
                                  <p className="fs-5 mb-0 align-self-center text-decoration-line-through mx-2 text-red">
                                    {formatNumber(live.price)}
                                  </p>
                                  <p className="text-purple-l fw-bold mt-auto mb-auto">
                                    {formatNumber(
                                      (Number(live.price) *
                                        (100 - Number(live.discount))) /
                                        100
                                    )}{" "}
                                    USD
                                  </p>
                                </>
                              ) : (
                                <p className="text-purple-l fw-bold mt-auto mb-auto">
                                  {formatNumber(live.price)} USD
                                </p>
                              )}
                            </div>
                          )}
                          {isBuyed(live.id) ? (
                            <Link
                              to={`/retos/${live?.customUrl}`}
                              className={`btn rounded-pill purple-gradient border-0 text-white fw-bold px-5 pointer text-decoration-none py-2 m-auto`}
                            >
                              Ver Live
                            </Link>
                          ) : (
                            <div className="m-auto">
                              <AddToCartButton item={live} />
                            </div>
                          )}
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </Col>
          {/* <Row className="mb-5 d-flex justify-content-center">
            <Button className="rounded-pill bg-purple-l border-0 text-white fw-bold px-5 pointer w-auto">
              Ver Más
            </Button>
          </Row> */}
        </Row>
      )}
    </Container>
  );
};

export { Challenges };
