import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import {
  BillingsContent,
  PaymentsContent,
  PrivacyContent,
  ReturnsContent,
  TermsContent,
} from "../components/Terms";
import { Cover } from "../components";

const sections = [
  {
    title: "Términos y Condiciones",
    eventKey: "terms",
  },
  {
    title: "Método de Pago",
    eventKey: "payment",
  },
  {
    title: "Avisos de Privacidad",
    eventKey: "privacy",
  },
  {
    title: "Cambio y Devoluciones",
    eventKey: "returns",
  },
  {
    title: "Facturación",
    eventKey: "billing",
  },
];

const Terms = () => {
  return (
    <>
      <Container fluid id="terms">
        <Cover text="Términos y Condiciones" />
        <Tab.Container
          id="left-tabs-container"
          defaultActiveKey="terms"
          className="my-5"
        >
          <Row>
            <Col md={{ span: 2, offset: 1 }}>
              <Nav className="flex-column">
                {sections.map((section) => (
                  <Nav.Item key={section.eventKey}>
                    <Nav.Link
                      eventKey={section.eventKey}
                      className="text-black bg-white nav-link rounded-start-pill"
                    >
                      {section.title}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col className="px-4 pb-5" md={8}>
              <Tab.Content>
                <Tab.Pane
                  eventKey="terms"
                  className="border border-0 border-dark border-start"
                >
                  <TermsContent />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="payment"
                  className="border border-0 border-dark border-start"
                >
                  <PaymentsContent />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="privacy"
                  className="border border-0 border-dark border-start"
                >
                  <PrivacyContent />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="returns"
                  className="border border-0 border-dark border-start"
                >
                  <ReturnsContent />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="billing"
                  className="border border-0 border-dark border-start"
                >
                  <BillingsContent />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
      {/* <Subscribe /> */}
    </>
  );
};

export { Terms };
