import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Loader, ModalComponent } from "../components";
import { useFetch } from "../hooks";
import { formatNumber } from "../utils";
import { TiPencil } from "react-icons/ti";
import { addDocInCollection, updateDocInCollection } from "../services";

const Dashboard = () => {
  const [modalShow, setModalShow] = useState(false);
  const [usdPrice, setUsdPrice] = useState(18);
  const { docs: courses, isLoading: coursesLoader } = useFetch("courses");
  const { docs: users, isLoading: usersLoader } = useFetch("users");
  const { docs: currencies, isLoading: currenciesLoader } =
    useFetch("currencies");

  useEffect(() => {
    if (!currenciesLoader && currencies.length) {
      setUsdPrice(Number(currencies.find((cur) => cur.name === "USD")?.price));
    }
    // eslint-disable-next-line
  }, [currenciesLoader, currencies]);

  const activeCoursesLength = () => courses.filter((c) => c.isActive).length;

  const handleUpdatePrice = async () => {
    try {
      if (!currencies.length) {
        const currency = {
          name: "USD",
          price: usdPrice,
        };

        await addDocInCollection("currencies", currency);
        setModalShow(false);
      } else {
        const currency = currencies.find((cur) => cur.name === "USD");

        const updatedCurrency = {
          ...currency,
          price: usdPrice,
        };

        await updateDocInCollection("currencies", currency.id, updatedCurrency);
        setModalShow(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (coursesLoader || usersLoader || currenciesLoader) return <Loader />;

  return (
    <Container fluid className="py-5">
      <Row>
        <Col
          md={{ span: 5, offset: 1 }}
          className="d-flex border border-purple-m rounded-5 py-4 text-center"
        >
          <Col className="border-end border-blue">
            <p className="fw-bold fs-5">Miembros Activos</p>
            <p className="fw-bold fs-1">{users.length}</p>
            <Link to="/admin/miembros">
              <Button className="py-1 px-5 rounded-pill" variant="secondary">
                Ver
              </Button>
            </Link>
          </Col>
          <Col>
            <p className="fw-bold fs-5">Cursos Activos</p>
            <p className="fw-bold fs-1">{activeCoursesLength()}</p>
            <Link to="/admin/cursos">
              <Button className="py-1 px-5 rounded-pill" variant="secondary">
                Ver
              </Button>
            </Link>
          </Col>
        </Col>
        <Col
          md={{ span: 4, offset: 1 }}
          className="border border-blue rounded-5 py-4 text-center"
        >
          <p className="fw-bold fs-5">USD</p>
          <p className="fw-bold fs-1">Precio Actual</p>
          <p className="fw-bold fs-1 my-0">
            {formatNumber(usdPrice)}
            <span>
              <TiPencil
                size={32}
                className="pointer ms-3 pb-2 text-purple-m"
                onClick={() => {
                  setModalShow(true);
                }}
              />
            </span>
          </p>
        </Col>
      </Row>
      <Row className="my-5">
        <Col md={{ span: 10, offset: 1 }} className="">
          <p className="bg-purple-m text-white py-3 ps-4 mb-0 fw-bold">
            Cursos Recientes
          </p>
          <Table striped className="border border-green">
            <thead>
              <tr>
                <th>#No. Curso</th>
                <th>Nombre</th>
                <th>Status</th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <tr key={course.id} className="py-4 align-middle">
                  <td># {index + 1}</td>
                  <td className="text-capitalize">{course.name}</td>
                  <td>
                    <span
                      className={`py-1 px-3 rounded-pill text-white ${
                        course.isActive ? "bg-success" : "bg-danger"
                      }`}
                    >
                      {course.isActive ? "Activo" : "Inactivo"}
                    </span>
                  </td>
                  <td>
                    <Link to={`cursos/curso?id=${course.id}`}>
                      <Button
                        className="py-1 px-5 rounded-pill"
                        variant="secondary"
                      >
                        Editar
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <ModalComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        title="Actualizar Precio USD"
      >
        <Row className="d-flex justify-content-center align p-3">
          <Col xs={12} lg={4}>
            <div className="mb-3">
              <label className="form-label text-purple-l fw-bold" htmlFor="usd">
                Nuevo Precio
              </label>
              <input
                type="number"
                id="usd"
                className="form-control rounded-pill"
                defaultValue={usdPrice}
                name="usd"
                onChange={(e) => setUsdPrice(+e.target.value)}
              />
            </div>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <Button
              className="py-1 px-5 rounded-pill mt-4 purple-gradient border-0"
              onClick={handleUpdatePrice}
            >
              Actualizar
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </Container>
  );
};

export default Dashboard;
export { Dashboard };
