import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const storage = getStorage();

// 'file' comes from the Blob or File API
const uploadImage = async (file) => {
  try {
    const imageId = Date.now();
    const imageRef = ref(storage, `images/${imageId}`);

    await uploadBytes(imageRef, file);
    const url = await getDownloadURL(imageRef);
    return url;
  } catch (err) {
    throw new Error(err);
  }
};

// 'file' comes from the Blob or File API
const uploadDoc = async (file) => {
  try {
    const docId = Date.now();
    const docRef = ref(storage, `docs/${docId}`);

    await uploadBytes(docRef, file);
    const url = await getDownloadURL(docRef);
    return url;
  } catch (err) {
    throw new Error(err);
  }
};

const getImageFromStorage = async (imagePath) => {
  const imageRef = ref(storage, imagePath);

  try {
    const url = await getDownloadURL(imageRef);
    return url;
  } catch (err) {
    throw new Error(err);
  }
};

export { getImageFromStorage, uploadDoc, uploadImage };
