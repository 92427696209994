import { useContext, useState } from "react";
import { TiArrowLeft } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { AppContext } from "../store/AppContext";
import { Col, Container, Row } from "react-bootstrap";
import { getUserDetails } from "../services";

const Login = ({ setIsLogin }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [showRecoveryPass, setShowRecoveryPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailReset, setEmailReset] = useState("");
  const [error, setError] = useState("");
  const { toastData } = useContext(AppContext);
  const { showToast } = toastData;

  const handleEmailField = ({ target }) => {
    const email = target.value;

    setEmail(email);
  };

  const handlePasswordField = ({ target }) => {
    const pass = target.value;

    setPassword(pass);
  };

  const handleEmailResetField = ({ target }) => {
    const email = target.value;

    setEmailReset(email);
  };

  const handleReset = (e) => {
    e.preventDefault();

    // Validate not empty info
    if (!emailReset) {
      return setError("El campo email es requerido.");
    }

    // Clean last error
    setError("");

    // Signin User
    sendPasswordResetEmail(auth, emailReset)
      .then(() => {
        setEmailReset("");
        showToast(
          "success",
          "Correo de reinicio de contraseña enviado, checa tu bandeja de spam."
        );
        setEmailReset("");
        setShowRecoveryPass(false);
      })
      .catch((error) => {
        showToast();
        const errorMessage = error.message;

        // Set error message in different cases
        if (errorMessage.includes("invalid-email"))
          return setError("Email invalido");
        if (errorMessage.includes("user-not-found"))
          return setError("Usuario no encontrado");

        setError("Algo salió mal, intentalo de nuevo."); // default error
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    // Validate not empty info
    if (!email || !password) {
      return setError("Todos los campos son requeridos");
    }

    // Clean last error
    setError("");

    // Signin User
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        getUserDetails(user.uid).then((userDetails) => {
          const userLS = {
            ...userDetails,
            courses: [],
          };
          localStorage.setItem("userDetails", JSON.stringify(userLS));
          // getHotmartToken();
          showToast("success", "Has iniciado sesion");
          userDetails.isAdmin ? navigate("/admin") : navigate("/");
        });
      })
      .catch((error) => {
        showToast();
        const errorMessage = error.message;

        // Set error message in different cases
        if (errorMessage.includes("invalid-email"))
          return setError("Email invalido");
        if (errorMessage.includes("user-not-found"))
          return setError("Usuario no encontrado");
        if (errorMessage.includes("wrong-password"))
          return setError("Contraseña invalida");

        setError("Algo salió mal, intentalo de nuevo."); // default error
      });
  };

  return (
    <Container fluid id="login">
      <Row>
        {showRecoveryPass ? (
          <>
            <Col xs={12} md={{ span: 5 }} xl={{ span: 5, offset: 0 }}>
              <Row>
                <Col className="mt-3 px-0 back-container" xs={12}>
                  <div
                    className="p-2 pointer"
                    onClick={() => {
                      setError("");
                      setShowRecoveryPass(false);
                    }}
                  >
                    <TiArrowLeft id="backIcon" />
                  </div>
                </Col>
                <Col xs={12}>
                  <form>
                    <div className="mt-5">
                      <label
                        className="form-label text-purple-l fw-bold mt-5"
                        htmlFor="recovery"
                      >
                        Recupera tu Contraseña
                      </label>
                      <input
                        type="text"
                        id="recovery"
                        className="form-control rounded-pill mt-2 mb-4"
                        name="recovery"
                        placeholder="Ingresa tu email"
                        onChange={handleEmailResetField}
                      />
                    </div>
                    {error && <p className="text-red">* {error}</p>}
                    <div className="mt-3">
                      <button
                        onClick={handleReset}
                        className="btn bg-purple-m text-white rounded-pill px-5 my-3"
                      >
                        Enviar
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={{ span: 7 }}
              className="d-flex justify-content-center"
            >
              <img
                src="/login.jpg"
                className="img-fluid forgot-img"
                alt="login"
              />
            </Col>
          </>
        ) : (
          <>
            <Col
              xs={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 5, offset: 0 }}
            >
              <h1 className="fs-4 text-purple-l fw-bold">Entra a tu cuenta</h1>
              <form>
                <div className="mt-4">
                  <label
                    className="form-label text-purple-l fw-bold"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control rounded-pill"
                    name="email"
                    onChange={handleEmailField}
                    placeholder="Escribe tu email"
                  />
                </div>
                <div className="my-3">
                  <label
                    className="form-label text-purple-l fw-bold"
                    htmlFor="password"
                  >
                    Contraseña
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="form-control rounded-pill"
                    name="password"
                    onChange={handlePasswordField}
                    placeholder="Escribe tu contraseña"
                  />
                </div>

                {error && <p className="text-red">* {error}</p>}
                <div className="d-flex">
                  {/* TO-DO create rememberme logic */}
                  <button
                    className="btn bg-purple-m text-white rounded-pill px-5 my-3"
                    onClick={handleLogin}
                  >
                    Entrar
                  </button>
                </div>
              </form>
              <div className="extra-options">
                <p
                  className="pointer text-blue"
                  onClick={() => {
                    setError("");
                    setShowRecoveryPass(true);
                  }}
                >
                  Olvide mi contraseña
                </p>
                {/* <div className="form-check my-3">
                  <input
                    className="form-check-input rounded-circle align-self-center py-0 my-0"
                    type="checkbox"
                    value=""
                    id="rememberme"
                  />
                  <label
                    className="form-check-label ms-1 align-self-center"
                    htmlFor="rememberme"
                  >
                    Recordarme
                  </label>
                </div> */}
                <p>
                  No tienes una cuenta?{" "}
                  <span
                    className="text-blue pointer"
                    onClick={() => setIsLogin(false)}
                  >
                    Crea una cuenta
                  </span>
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 7, offset: 0 }}
              className="d-flex justify-content-center"
            >
              <img
                src="/login.jpg"
                className="img-fluid login-img"
                alt="login"
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export { Login };
