import { Col, Row } from "react-bootstrap";

const Cover = ({ text, image }) => {
  return (
    <Row
      id="cover"
      className="d-flex align-items-center mb-5 position-relative"
    >
      <Col className="z-1">
        <h3 className="fs-1 fw-bold text-white text-center">{text}</h3>
      </Col>
      <img
        className="position-absolute top-0 h-100 w-100 px-0 -z-1"
        src={image ? image : "/banner-cursos.jpg"}
        id="cover-image"
        alt="cover"
      />
      <div className="position-absolute w-100 h-100 bg-black z-0 opacity-25" />
    </Row>
  );
};

export { Cover };
