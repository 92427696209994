import { useState, useEffect, useContext } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { TiUserOutline } from "react-icons/ti";
import { TbShoppingCart } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { AppContext } from "../store/AppContext";

const Header = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [showLogin, setShowLogin] = useState(true);
  const { toastData } = useContext(AppContext);
  const { showToast } = toastData;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) setShowLogin(false);
    });
  }, [auth]);

  const toggleMenu = () => {
    setOpen(false);
  };

  const logOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem("userDetails");
        showToast("success", "Has cerrado sesion");
        setShowLogin(true);
        navigate("/");
        setOpen(false);
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <Navbar key="lg" expand="lg" className="mb-3 fixed-top" id="header">
      <Container fluid className="px-0 mx-0">
        <Navbar.Brand id="logo">
          <Link to="/">
            <Container>
              <img src="/logo2.png" width="180" alt="" />
            </Container>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-lg"
          className="me-4"
          onClick={() => {
            setOpen(true);
          }}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
          placement="end"
          show={open}
        >
          <Offcanvas.Header
            closeButton
            onClick={() => {
              setOpen(false);
            }}
          ></Offcanvas.Header>
          <Offcanvas.Body id="offcanvas-body">
            <Nav>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "active navLinks" : "navLinks"
                }
                onClick={toggleMenu}
              >
                <p className="mb-0 align-self-center">Home</p>
              </NavLink>
              <NavLink
                to="/cursos"
                className={({ isActive }) =>
                  isActive ? "active navLinks" : "navLinks"
                }
                onClick={toggleMenu}
              >
                <p className="mb-0 align-self-center">Cursos</p>
              </NavLink>
              <NavLink
                to="/retos"
                className={({ isActive }) =>
                  isActive ? "active navLinks" : "navLinks"
                }
                onClick={toggleMenu}
              >
                <p className="mb-0 align-self-center">Retos / Live</p>
              </NavLink>
              <NavLink
                to="/suscripciones"
                className={({ isActive }) =>
                  isActive ? "active navLinks" : "navLinks"
                }
                onClick={toggleMenu}
              >
                <p className="mb-0 align-self-center">Suscripciones</p>
              </NavLink>
              <a
                className="navLinks"
                href="https://leenithborgesarte.mitiendanube.com/"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-0 align-self-center">Materiales</p>
              </a>
              <NavLink
                to="/sobre-mi"
                className={({ isActive }) =>
                  isActive ? "active navLinks" : "navLinks"
                }
                onClick={toggleMenu}
              >
                <p className="mb-0 align-self-center">Sobre mi</p>
              </NavLink>
              <NavLink
                to="/contacto"
                className={({ isActive }) =>
                  isActive ? "active navLinks" : "navLinks"
                }
                onClick={toggleMenu}
              >
                <p className="mb-0 align-self-center">Contacto</p>
              </NavLink>
              <NavLink
                to="/faq"
                className={({ isActive }) =>
                  isActive ? "active navLinks" : "navLinks"
                }
                onClick={toggleMenu}
              >
                <p className="mb-0 align-self-center">FAQ</p>
              </NavLink>
              <NavLink
                to="/carrito"
                className={({ isActive }) =>
                  isActive ? "active navLinks" : "navLinks"
                }
                onClick={toggleMenu}
              >
                <TbShoppingCart />
              </NavLink>

              <div>
                {showLogin ? (
                  <Link to="/auth" onClick={toggleMenu}>
                    <TiUserOutline />
                  </Link>
                ) : (
                  <>
                    <Link to="/perfil" onClick={toggleMenu}>
                      <TiUserOutline className="text-blue" />
                    </Link>
                    <FiLogOut className="pointer" onClick={logOut} />
                  </>
                )}
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export { Header };
