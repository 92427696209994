import { useEffect, useState } from "react";
import { getCollectionFromDB } from "../services";

const useFetch = (schema) => {
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const items = await getCollectionFromDB(schema);
        setError("");
        setDocs(items);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocs();
  }, [schema]);

  return { docs, isLoading, error };
};

export { useFetch };
