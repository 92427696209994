import Spinner from "react-bootstrap/Spinner";

const Loader = () => {
  return (
    <div className="loader w-full d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status" />
    </div>
  );
};

export { Loader };
