// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDR5WHY-mJ5dCcnpWiCeqOq08Bg_SLj0js",
  authDomain: "elearning-dev1.firebaseapp.com",
  projectId: "elearning-dev1",
  storageBucket: "elearning-dev1.appspot.com",
  messagingSenderId: "541913767901",
  appId: "1:541913767901:web:e65224c17dbe5e2e312034",
  measurementId: "G-GJG1TST7RZ",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initializa Firestore DB
export const db = getFirestore(firebaseApp);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(firebaseApp);

// Initialize firebase functions
export const functions = getFunctions(firebaseApp);

// Use Firebase Emulators in dev env only
if (process.env.NODE_ENV === "development") {
  const auth = getAuth(firebaseApp);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 5001);
  connectFunctionsEmulator(functions, "localhost", 5002);
}
