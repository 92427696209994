import { useEffect, useState } from "react";

const Header = () => {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userDetails"));
    setCurrentUser(user);
  }, []);

  return (
    <header className="pe-5" id="admin-header">
      <div className="d-flex text-white ms-auto">
        <div className="d-flex flex-column mt-auto mb-auto px-3">
          <p className="fw-bold my-0 pe-3 text-capitalize">
            {currentUser?.name}
          </p>
          <small>{currentUser?.email}</small>
        </div>
        <img
          src="/logo-small.png"
          className="img-fluid leenith-icon mt-auto mb-auto"
          alt=""
        />
      </div>
    </header>
  );
};

export default Header;
export { Header };
