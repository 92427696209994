import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Cover, Loader, Player } from "../components";
import { getDocFromCollectionByName, getUserCourses } from "../services";
import { formatNumber, formatMinutes, firstCharUpperCase } from "../utils";
import { AddToCartButton } from "../components/Cart/AddToCartButton";

const Challenge = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState({});
  // const [isPending, setIsPending] = useState(false);
  const [challengeBuyed, setChallengeBuyed] = useState(false);
  const [videos, setVideos] = useState("");
  const [videoToShow, setVideoToShow] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));

  useEffect(() => {
    const controller = new AbortController();

    const fetchChallenge = async () => {
      setIsLoading(true);

      try {
        const challenge = await getDocFromCollectionByName(
          "challenges",
          params.name
        );
        setChallenge(challenge);
        const videoArray = [challenge.vimeoVideoId];
        [2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((i) => {
          if (challenge[`vimeoVideoId${i}`]) {
            videoArray.push(challenge[`vimeoVideoId${i}`]);
          }
        });
        setVideos(videoArray);
        setVideoToShow(challenge.vimeoVideoId);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    const getChallengesFromUser = async () => {
      setIsLoading(true);

      try {
        const challenges = await getUserCourses(user.id);

        if (challenges.includes(challenge.id)) setChallengeBuyed(true);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    // const getOrdersFromUser = async () => {
    //   setIsLoading(true);

    //   try {
    //     const ordersFromDB = await getUserOrders(user.id);

    //     const ids = ordersFromDB
    //       .filter((ord) => ord.status === "pending")
    //       .map((ord) => ord?.product?.id);
    //     if (ids.includes(challenge.id)) setIsPending(true);
    //   } catch (err) {
    //     console.log(err.message);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };

    params.name && params.name !== "undefined"
      ? fetchChallenge()
      : navigate("/retos");

    if (user && user.id) {
      getChallengesFromUser();
      // getOrdersFromUser();
    }

    return () => controller.abort();
  }, [params.name, user, challengeBuyed, challenge?.id, navigate]);

  // const createOrder = async () => {
  //   setIsLoading(true);

  //   try {
  //     const { id, name, price, discount, productId, isLive, cover } = challenge;

  //     const newOrder = {
  //       type: isLive ? "live" : "reto",
  //       product: {
  //         id,
  //         name,
  //         price: (Number(price) * (100 - Number(discount))) / 100,
  //         productId,
  //         cover,
  //       },
  //       user: {
  //         id: user.id,
  //         email: user.email,
  //       },
  //       status: "confirmed",
  //       createdAt: Date.now(),
  //     };

  //     await addDocInCollection("orders", newOrder);
  //     navigate("/pago");
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  if (isLoading) return <Loader />;
  if (error) return <h1>Algo malo sucedio...</h1>;

  return (
    <>
      <Container fluid id="challenge-page">
        {!challengeBuyed && <Cover text={challenge.isLive ? "Live" : "Reto"} />}
        <Row className="my-5">
          <Col
            lg={{
              span: 8,
              offset: challengeBuyed && challenge.isLive ? 2 : 0,
            }}
            xxl={{
              span: 6,
              offset: challengeBuyed && challenge.isLive ? 3 : 2,
            }}
          >
            <h3 className="text-purple-l fw-bold fs-2 mb-5 text-capitalize text-center">
              {challenge.name}
            </h3>
            {challengeBuyed ? (
              <>
                <Player videoId={videoToShow} />
                <hr />
              </>
            ) : (
              <div className="mx-4">
                <iframe
                  width="100%"
                  height="415"
                  className="rounded-4"
                  src={`https://www.youtube.com/embed/${challenge.videoId}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </Col>
          {!challengeBuyed ? (
            <Col lg={4} xxl={3} className="mt-5">
              <div className="border border-dark rounded-4 py-5 mx-4 mt-5">
                <div className="d-flex justify-content-center flex-wrap">
                  <p className="fw-bold fs-3 mb-0 align-self-center">
                    {formatNumber(
                      (Number(challenge?.price) *
                        (100 - Number(challenge?.discount))) /
                        100
                    )}
                  </p>
                  {Number(challenge.discount) > 0 && (
                    <p className="fs-5 mb-0 align-self-center text-decoration-line-through mx-2">
                      {formatNumber(challenge.price)}
                    </p>
                  )}
                </div>
                <div className="fs-5">
                  {Number(challenge.discount) > 0 && (
                    <p className="text-center text-red fw-bold">
                      Descuento {challenge.discount} %
                    </p>
                  )}
                  <div className="d-grid gap-2 justify-content-center">
                    <AddToCartButton item={challenge} />
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            <Col lg={4} xxl={3} className="mt-5">
              <div className="border border-dark rounded-4 p-3 mx-4 mt-5">
                {videos.map((video, i) => (
                  <>
                    {video && (
                      <div
                        id={video}
                        className="d-flex align-items-center justify-content-between my-2 px-5 py-4 bg-gray rounded-3 fw-bold text-capitalize pointer"
                        onClick={() => setVideoToShow(video)}
                      >
                        Parte {i + 1}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col
            lg={{
              span: 8,
              offset: challengeBuyed && challenge.isLive ? 2 : 0,
            }}
            xxl={{
              span: 6,
              offset: challengeBuyed && challenge.isLive ? 3 : 2,
            }}
          >
            <div
              className={`border border-dark rounded-4 mx-4 p-3 ${
                challenge?.isLive ? "mb-5" : ""
              }`}
            >
              <h4 className="fw-bold fs-3 text-capitalize">
                {challenge.isLive ? "Live" : "Reto"} - {challenge.name}
              </h4>
              <p className="my-4 wsp">
                {firstCharUpperCase(challenge.description)}
              </p>
            </div>
            {!challenge?.isLive && (
              <div>
                {challengeBuyed ? (
                  <div className="border border-dark rounded-4 overflow-hidden m-4">
                    <Tabs
                      defaultActiveKey="facebook"
                      id="challenge-tabs"
                      className="fw-bold"
                    >
                      <Tab eventKey="facebook" title="Facebook Link">
                        <div className="p-2 px-4">
                          {challenge?.facebookLink ? (
                            <a
                              href={challenge.facebookLink}
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-none"
                            >
                              <p className="text-center fs-5 p-4">
                                Ir a Facebook
                              </p>
                            </a>
                          ) : (
                            <p className="text-center fs-5 p-4">Muy pronto!</p>
                          )}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                ) : (
                  <div className="border border-dark rounded-4 overflow-hidden m-4">
                    <Tabs
                      defaultActiveKey="general"
                      id="challenge-tabs"
                      className="fw-bold"
                    >
                      <Tab eventKey="general" title="General">
                        <div className="py-3 px-4">
                          <p className="fw-bold fs-5 text-capitalize">
                            {challenge.name} incluye:
                          </p>
                          <Row className="py-1">
                            <Col md={12}>
                              <BenefitItem
                                text={`${challenge.duration} Minutos`}
                                image="time.png"
                              />
                              <BenefitItem
                                text={
                                  <span className="text-capitalize">
                                    Nivel {challenge.level}
                                  </span>
                                }
                                image="dificulty.png"
                              />
                              <BenefitItem
                                text={`${formatMinutes(
                                  challenge.duration
                                )} de Pintura`}
                                image="art-icon.png"
                                width={18}
                                height={18}
                              />
                              <BenefitItem
                                text={
                                  <span className="text-capitalize">
                                    idioma: {challenge?.language || "Español"}
                                  </span>
                                }
                                image="language.png"
                              />
                            </Col>
                          </Row>
                          <p className="fw-bold mt-3 fs-5 text-capitalize">
                            Resumen:
                          </p>
                          <p className="my-3 wsp">{challenge.summary}</p>
                        </div>
                      </Tab>
                      <Tab eventKey="materiales" title="Materiales">
                        <div className="py-3 px-4">
                          <p className="fw-bold mt-3 fs-5 text-capitalize">
                            Materiales:
                          </p>
                          <p className="my-3 wsp">{challenge.requirements}</p>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {/* <Subscribe /> */}
    </>
  );
};

export { Challenge };

const BenefitItem = ({ text, image, height, width }) => {
  return (
    <div className="d-flex align-items-center my-2">
      <img
        src={`/${image}`}
        className="me-2"
        width={width ?? 18}
        height={height ?? 18}
        alt="art-icon"
      />
      {text}
    </div>
  );
};
