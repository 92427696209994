import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { formatMinutes } from "../utils";
import { useNavigate } from "react-router-dom";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useFetch } from "../hooks";

const Numeros = [
  {
    id: 1,
    title: 4.95,
    text: "Calificación promedio de nuestros cursos.",
    icon: "libro.gif",
  },
  {
    id: 2,
    title: "+ 4000",
    text: "Alumnos Online.",
    icon: "pintura.gif",
  },
  {
    id: 3,
    title: "+ 1500",
    text: "Calificaciones verificadas.",
    icon: "certificado.gif",
  },
  {
    id: 4,
    title: "",
    text: "Cursos de Pintura mas vendidos en Latinoamérica.",
    icon: "paleta-de-pintura.gif",
  },
];

const Dirigido = [
  {
    id: 1,
    texto:
      "Eres artista y aventurera del arte y te interesa aprender a pintar desde cero sin complicaciones.",
    icon: "learn.png",
  },
  {
    id: 2,
    texto:
      "Te interesa pintar una hermosa obra de arte para decorar su casa y darle un toque de exclusividad a tu hogar.",
    icon: "painting.png",
  },
  {
    id: 3,
    texto:
      "Te llama la atención la pintura pero no crees tener las habilidades.",
    icon: "palette.png",
  },
  {
    id: 4,
    texto: "Quieres aprender a pintar desde cero de manera fácil y sencilla.",
    icon: "learn-easy.png",
  },
  {
    id: 5,
    texto: "Buscas un pasatiempo que permita liberarte del estrés. ",
    icon: "painting-oil.png",
  },
  {
    id: 6,
    texto: "Quieres hacer de la pintura tu emprendimiento o actividad laboral.",
    icon: "earn-painting.png",
  },
];

const Habilidades = [
  {
    id: 1,
    text: "Mejora tu capacidad de comunicación y la manera de expresarte.",
    icon: "idea.gif",
  },
  {
    id: 2,
    text: "Pintar es un gimnasio para tu cerebro.",
    icon: "paleta-de-pintura.gif",
  },
  {
    id: 3,
    text: "Al pintar liberas endorfinas que te harán sentir mejor.",
    icon: "contento.gif",
  },
  {
    id: 4,
    text: "Mejora tu motricidad fina y gruesa, además de tu salud Mental.",
    icon: "pintura.gif",
  },
];

const Socials = [
  {
    id: 1,
    icon: "facebook-icon.png",
    text: "@Leenith",
    className: "fb-button",
    link: "https://www.facebook.com/cuadrosleenithborges",
  },
  {
    id: 2,
    icon: "instagram-icon.png",
    text: "@Leenith",
    className: "ig-button",
    link: "https://www.instagram.com/leenithborges/",
  },
  {
    id: 3,
    icon: "youtube-icon.png",
    text: "@Leenith",
    className: "yt-button",
    link: "https://www.youtube.com/channel/UCYLf1em1NKejW9-3ciN33jA",
  },
  {
    id: 4,
    icon: "telegram-icon.png",
    text: "@Leenith",
    className: "tl-button",
    link: "https://t.me/+xlUZHAV5cw9jY2Qx",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1200, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const accordionItems = [
  {
    title: "Curso 100% Online",
    content:
      "Accederás al Área exclusiva de miembros donde podrás ver todo el contenido del curso",
    icon: "online-course.png",
  },
  {
    title: "Certificado de finalización",
    content: "Al terminar tu curso podrás descargar el certificado.",
    icon: "certificate.png",
  },
  {
    title: "Cursos HD",
    content:
      "Si, los cursos de Leenith son en ALTA DEFINICIÓN, basta de cursos en baja calidad de grabación. En nuestros cursos veras todo con detalle en HD.",
    icon: "hd-videos.png",
  },
  {
    title: "Recursos descargables",
    content:
      "En cada cursos tendrás recursos que te ayudaran durante el proceso los cuales podrás descargarlos para tu facilidad.",
    icon: "download.png",
  },
];

const accordionItems2 = [
  {
    title: "Curso con acceso ilimitado",
    content:
      "Si así como lo lees, sin membresía ni mensualidades, cualquiera de mis cursos una vez que lo adquieras es tuyo de por vida.",
    icon: "infinite.png",
    width: 22,
    height: 12,
  },
  {
    title: "Acceso inmediato",
    content:
      "No tendrás que esperar nada para ver tu curso, apenas se apruebe tu pago podrás acceder inmediatamente al mismo.",
    icon: "dificulty.png",
  },
  {
    title: "Bonos adicionales",
    content:
      "Porque no nos conformamos, en cada curso te obsequiamos BONOS exclusivos que te Sorprendán.",
    icon: "sections.png",
  },
  {
    title: "Actualizaciones permanentes",
    content:
      "Comprometidos a mejorar constantemente, Leenith actualiza sus cursos periódicamente incorporando más contenido y nuevas obras. Estas actualizaciones son 100% grátis para los que ya han adquirido el curso.",
    icon: "renew.png",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const { docs: courses } = useFetch("courses");
  const [activeCourses, setActiveCourses] = useState([]);

  useEffect(() => {
    if (courses) {
      const filteredCourses = courses.filter(
        (course) => course.isActive && !course.isFromMembership
      );

      setActiveCourses(filteredCourses);
    }
  }, [courses]);

  return (
    <Container fluid id="home" className="m-0 p-0 overflow-x-hidden">
      <Row
        className="m-0 p-0 position-relative align-content-center"
        id="hero-container"
      >
        <Row className="px-5">
          <Col
            xs={{ span: 8, offset: 1 }}
            className="px-0 z-3 align-self-center"
          >
            <h1 className="text-white fw-bold hero-text">
              Llegaste al mejor lugar para aprender <br /> las técnicas mas
              novedosas de la <br /> PINTURA ARTISTICA
            </h1>
          </Col>
          <Col
            xs={{ span: 8, offset: 1 }}
            className="px-0 z-3 align-self-center"
          >
            <Button
              variant="info"
              className="rounded-pill border-0 py-2 px-4 text-white yt-button w-bold cta-btn"
              onClick={() => {
                navigate("/cursos");
              }}
            >
              Explora nuestros cursos
            </Button>
          </Col>
        </Row>
        <Carousel
          variant="light"
          indicators={false}
          interval={null}
          className="position-absolute top-0 z-1 px-0 w-100 h-100"
          id="hero-image"
        >
          <Carousel.Item className="border-0 h-100 w-100 px-0">
            <img
              className="w-100 h-100"
              src="./hero-image.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item className="border-0 h-100 w-100 px-0">
            <img
              className="w-100"
              src="./banner-oscuro.jpg"
              alt="Second slide"
            />
          </Carousel.Item>
        </Carousel>
      </Row>
      <Row className="purple-blue-gradient py-5 mx-0 px-0">
        <Col xs={12} className="d-flex flex-column">
          <h3 className="text-white fw-bold text-center mb-4">
            Los numeros hablan por nosotros
          </h3>
          <Row xs={1} sm={2} lg={4} className="px-3 justify-content-around">
            {Numeros.map((numero) => (
              <Col
                lg={3}
                key={numero.id}
                className="d-flex align-items-stretch justify-content-center"
              >
                <Card className="border-0 rounded-4 overflow-hidden my-3 mx-0 w-100">
                  <img
                    className="img-fluid align-self-center my-3 numbers-icon"
                    src={`./${numero.icon}`}
                    alt="calificacion"
                  />
                  <Card.Body>
                    <p className="fs-4 text-purple-l fw-bold mb-1 text-center d-inline-block w-100">
                      {numero.title}
                    </p>
                    <p className="text-purple-l mb-0 px-3 text-center">
                      {numero.text}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <h3 className="text-purple-l fw-bold text-center mb-4">
            A quien va dirigido?
          </h3>
          <Row xs={1} md={2} lg={3} className="px-3 justify-content-around">
            {Dirigido.map((item) => (
              <Col
                key={item.id}
                className="d-flex align-items-stretch justify-content-center"
              >
                <Card className="border-0 rounded-pill overflow-hidden my-3 mx-0 w-100">
                  <Card.Body className="d-flex bg-gray-home">
                    <img
                      className="img-fluid align-self-center"
                      width={50}
                      height={50}
                      src={`./${item.icon}`}
                      alt="calificacion"
                    />
                    <p className="mb-0 px-3 text-white">{item.texto}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="bg-purple-gradient-home d-flex justify-content-center">
        <Row id="cta-container" className="mx-0 px-3 position-relative">
          <img
            src="./effect-cta.png"
            id="effect-cta"
            alt="lb-1"
            className="position-absolute top-0"
          />
          <Col xs={12} md={6} className="mx-0 py-5">
            <div className="ratio ratio-16x9" style={{ zIndex: 15 }}>
              <iframe
                width="560"
                height="315"
                className="rounded-5"
                src="https://www.youtube.com/embed/oCuQJLx2yAw"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
          <Col md={6} className="mx-0 text-white py-5" style={{ zIndex: 15 }}>
            <h3 className="fs-1 pt-3 fw-bold">¿CURSOS ONLINE?</h3>
            <h5 className="fs-3 fw-bold mt-4 mb-3">¡ Únete a mis cursos !</h5>
            <p className="fs-5 mb-4">
              Aprende conmigo desde "0" a pintar tus propias obras de una manera
              practica, fácil y a tu propio ritmo. ¡Logra Resultados
              Espectaculares!
            </p>
            <Row>
              <Col xs={6} className="d-flex py-1 justify-content-end">
                {/* <Button
                variant="info"
                className="text-white fw-bold rounded-pill px-4"
                onClick={() => {
                  navigate("/cursos");
                }}
              >
                Ver Membresia
              </Button> */}
              </Col>
              <Col xs={6} className="d-flex py-1 justify-content-start">
                <Button
                  variant="info"
                  className="text-white fw-bold rounded-pill px-5"
                  onClick={() => {
                    navigate("/cursos");
                  }}
                >
                  Ir a los cursos
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
      <Row className="mt-5">
        <h3 className="text-center text-purple-l fw-bold fs-2">
          Sólo lo mejor para ti
        </h3>
        <Col md={{ span: 8, offset: 2 }} className="mt-2">
          <p className="text-center fw-bolder fs-5">
            La formación es una de mis pasiones. Tanto de manera “online” como
            presencial disfruto muchísimo creando y compartiendo conocimientos
            de pintura. Por eso te presento mis cursos online para que puedas
            formarte conmigo y crear obras Modernas y Elegantes.
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={{ span: 11, offset: 1 }}>
          <Row>
            <Col md={4} className="px-0">
              <Accordion>
                {accordionItems.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index} className="mt-2">
                    <Accordion.Header>
                      <div className="me-2">
                        <img
                          src={`/${item.icon}`}
                          width={24}
                          height={24}
                          alt="art-icon"
                        />
                      </div>
                      <p className="pe-4 mt-auto mb-auto">{item.title}</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.2}
                        stroke="currentColor"
                        className="plus-icon"
                        width={24}
                        height={24}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Accordion.Header>
                    <Accordion.Body className="px-5">
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
            <Col md={4} className="px-0">
              <Accordion>
                {accordionItems2.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index} className="mt-2">
                    <Accordion.Header>
                      <div className="me-2">
                        <img
                          src={`/${item.icon}`}
                          width={item.width ?? 22}
                          height={item.height ?? 22}
                          alt="art-icon"
                        />
                      </div>
                      <p className="pe-4 mt-auto mb-auto">{item.title}</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.2}
                        stroke="currentColor"
                        className="plus-icon"
                        width={24}
                        height={24}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Accordion.Header>
                    <Accordion.Body className="px-5">
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
            <Col md={4}>
              <img
                src="/login.jpg"
                className="img-fluid"
                id="course-image"
                alt="signin"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="py-5">
        <Col xs={12} className="d-flex flex-column">
          <h3 className="text-purple-l fw-bold text-center mb-4">
            Mejora tus habilidades
          </h3>
          <Row xs={1} sm={2} lg={4} className="px-3 justify-content-around">
            {Habilidades.map((habilidad) => (
              <Col
                lg={3}
                key={habilidad.id}
                className="d-flex align-items-stretch justify-content-center"
              >
                <Card className="border-0 rounded-4 overflow-hidden my-3 mx-0 w-100">
                  <img
                    className="img-fluid align-self-center my-3 numbers-icon"
                    src={`./${habilidad.icon}`}
                    alt="calificacion"
                  />
                  <Card.Body className="bg-white">
                    <p className="mb-0 px-3 text-center fw-bold">
                      {habilidad.text}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="bg-pink-gradient-home d-flex justify-content-center pt-3">
        <Row className="mx-0 px-3 position-relative" id="socials-container">
          <Col
            md={{ span: 7 }}
            className="text-white my-5 pt-2 d-flex flex-column z-1"
          >
            <h2 className="fw-bold fs-2">
              Acompañame a aprender algo nuevo todos los días
            </h2>
            <p className="fs-5">
              En mis redes comparto sin reserva las técnicas que uso con mis
              obras, y que han ayudado a mis alumnos a crecer en el mundo de la
              pintura artística.
            </p>
            <div className="d-flex flex-wrap mt-auto mb-auto">
              {Socials.map((social) => (
                <a
                  href={social.link}
                  target="_blank"
                  key={social.id}
                  className={`${social.className} rounded-pill border-0 py-2 px-3 text-white d-flex m-2 text-decoration-none`}
                  rel="noreferrer"
                >
                  <img
                    src={`./${social.icon}`}
                    width={30}
                    height={30}
                    alt="facebook icon"
                    className="img-fluid align-self-center"
                  />
                  <p className="ms-3 fw-bold mt-auto mb-auto">{social.text}</p>
                </a>
              ))}
            </div>
          </Col>
          <Col md={5} className="d-grid z-1">
            <img
              id="img-redes"
              src="./redes-leenith.png"
              alt="redes sociales"
              className="img-fluid align-self-end"
            />
          </Col>
          <img
            src="./effect-redes.png"
            id="effect-redes"
            alt="effect-redes"
            className="position-absolute z-0 mb-2"
          />
        </Row>
      </Row>
      <Row className="my-5 mx-0 px-0">
        <Col className="text-center">
          <h3 className="text-center fs-5">Cursos para ti</h3>
          <h2 className="text-purple-l fs-3 fw-bold text-center mb-4">
            Aprende tecnicas de arte
          </h2>
          <MultiCarousel responsive={responsive} infinite={true}>
            {activeCourses.map((course) => (
              <div key={course.id} className="p-3">
                <Card
                  className="border-0 rounded-4 overflow-hidden my-4 w-100 pointer"
                  onClick={() => {
                    navigate(`/cursos/${course.customUrl}`);
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={course.cover}
                    className="rounded-0 course-image"
                  />
                  <Card.Body className="bg-gray p-3">
                    <div className="text-purple-l text-capitalize d-flex mb-3">
                      <img
                        src="/dificulty.png"
                        className="me-1 image-fluid align-self-center"
                        width={16}
                        height={16}
                        alt="recomended-course"
                      />
                      <p className="mb-0">Dificultad: {course.level}</p>
                    </div>
                    <Card.Title className="text-purple-l fw-bold text-capitalize pt-2">
                      {course.name}
                    </Card.Title>
                  </Card.Body>
                  <Card.Footer className="border-0 d-flex justify-content-between overflow-hidden p-3 flex-wrap bg-gray">
                    <div className="text-purple-l text-capitalize d-flex mt-auto mb-auto">
                      <img
                        src="/time.png"
                        className="me-1 image-fluid align-self-center"
                        width={16}
                        height={16}
                        alt="recomended-course"
                      />
                      <p className="mb-0">{formatMinutes(course.duration)}</p>
                    </div>
                    <div className="text-purple-l text-capitalize d-flex mt-auto mb-auto">
                      <img
                        src="/sections.png"
                        className="me-1 image-fluid align-self-center"
                        width={16}
                        height={16}
                        alt="recomended-course"
                      />
                      <p className="mb-0">{course.sections.length} Secciones</p>
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            ))}
          </MultiCarousel>
          <Button
            variant="info"
            className="text-white rounded-pill px-3"
            onClick={() => {
              navigate("/cursos");
            }}
          >
            Ver todos los cursos
          </Button>
        </Col>
      </Row>
      <Row className="mt-5 mx-0 px-3" id="testimonios">
        <Col
          md={{ span: 8, offset: 2 }}
          className="bg-light my-5 rounded-5 text-center"
        >
          <p className="my-4">Testimonios</p>
          <h3 className="text-purple-l fw-bold fs-2 mb-5">
            Nuestros alumnos y sus comentarios
          </h3>
          <Carousel variant="dark" indicators={false} interval={null}>
            <Carousel.Item className="border-0">
              <div>
                <p>
                  "Testimonios de una alumna en donde explica su experiencia y
                  califica nuestras clases."
                </p>
                <p className="text-purple-m fw-bold">- Ana Carolina</p>
              </div>
            </Carousel.Item>
            <Carousel.Item className="border-0">
              <div>
                <p>
                  “Ya ando con todo con los live y cursos, ya me envicié,
                  gracias Leenith, súper relajante”.
                </p>
                <p className="text-purple-m fw-bold"></p>
              </div>
            </Carousel.Item>
            <Carousel.Item className="border-0">
              <div>
                <p>
                  “Segundo curso contigo Flores Modernas. Muy contenta con tus
                  clases y resultado, ¡gracias!”.
                </p>
                <p className="text-purple-m fw-bold">Mary</p>
              </div>
            </Carousel.Item>
            <Carousel.Item className="border-0">
              <div>
                <p>
                  “Leenith es una Artista y Maestra. Explica de una manera que
                  se hace entender, transmite ese amor a la pintura, contagia su
                  pasión, yo estoy encantada, eres increíble Leenith y una
                  persona muy dulce, ¡Gracias!”.
                </p>
                <p className="text-purple-m fw-bold">Zaddi</p>
              </div>
            </Carousel.Item>
            <Carousel.Item className="border-0">
              <div>
                <p>
                  “Gracias por compartir el hermoso talento que Dios te dió, te
                  mando un abrazo”.
                </p>
                <p className="text-purple-m fw-bold">Ysnah</p>
              </div>
            </Carousel.Item>
            <Carousel.Item className="border-0">
              <div>
                <p>
                  “Somos de Rosario Argentina, yo solo estoy haciendo el curso
                  de técnicas mixtas pero mi hermana hizo varios cuadros. ¡¡Sos
                  lo más!! Dios quiera un día podamos viajar a México para
                  conocerte y tomar clases presencial”.
                </p>
                <p className="text-purple-m fw-bold">Claudia</p>
              </div>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      {/* <Subscribe /> */}
      <a
        href="https://wa.me/528443551833"
        className="wp-logo p-3 bg-transparent"
        target="_blank"
        rel="noreferrer"
      >
        <img src="./logo-wp.png" alt="logo wp" id="logo-wp" />
      </a>
    </Container>
  );
};

export { Home };
