import { Col, Container, Row } from "react-bootstrap";

const PaymentsContent = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 11, offset: 1 }} className="text-content">
          <h3 className="mb-1 fw-bold">Métodos de Pago</h3>
          <h5>Leenit Borges Arte</h5>
          <p>
            Agradecemos tu visita a nuestra tienda en línea de Leenith Borges
            Arte hacer cualquier compra en Leenith Borges Arte, estás aceptando
            los siguientes términos y condiciones. Al visitar
            www.LeenithArte.com.mx quedas sujeto(a) a los lineamientos, derechos
            y restricciones aquí mencionadas. Leenith Borges Arte está
            representado por una Persona Física debidamente establecida y
            registrada ante las normas del SAT y del comercio del estado. (en
            adelante "Leenith Borges Arte") se reserva el derecho de modificar
            el presente documento en el momento y bajo las circunstancias que
            más le convengan, siendo estos cambios vigentes en el momento mismo
            de su publicación. Aceptación de términos legales: con el objetivo
            de ofrecer un ambiente seguro y agradable para nuestros clientes y
            visitantes, hemos establecido reglas y términos bajo los cuales se
            delimitan las áreas de responsabilidad y derechos, tanto de Leenith
            Borges Arte como de nuestros visitantes. Si vas a realizar una
            compra a través de www.LeenithArte.com.mx es de suma importancia
            conocer la siguiente información.
          </p>
          <p>
            Formas de Pago www.LeenithArte.com.mx y/o Leenith Borges Arte, se
            reserva el derecho de solicitar documentos oficiales a sus clientes,
            como medio de validación al proceso de adquisición de productos a
            través de su sitio en Internet.El número de orden que se asigna al
            realizar la transacción en el sitio de Internet de Leenith Borges
            Arte no implica la aceptación de la transacción. En caso de tener
            algún problema con tu orden te será comunicado por correo
            electrónico o vía telefónica. Disponibilidad de Mercancías y
            Existencias Toda nuestra mercancía está sujeta a existencias, por lo
            que el tiempo de entrega puede variar previo aviso de nuestra parte,
            o incluso podrá proceder la cancelación de la orden y la devolución
            de sus cargos en caso de no ser un producto resurtible. Precios Los
            precios publicados en el sitio de Internet de Leenith Borges Arte
            son exclusivos para este canal y pueden cambiar sin previo aviso. Si
            por alguna razón el precio se encuentra en $ 0.00 ó $ 0.01, te
            pedimos que nos llames por teléfono al número 8335 7542 ó nos envíes
            un correo hola@leenitharte.com para darte el precio correcto del
            artículo.
          </p>
          <p>
            Por ninguna razón se entenderá que éstos no tengan precio o se
            regalen. Todos los pedidos que se realicen bajo esta situación serán
            cancelados sin previo aviso. Especificaciones de los Productos Las
            características y especificaciones de los productos pueden cambiar
            sin previo aviso. Y la fotografía puede no corresponder a éstas,
            sino ser de un producto similar representado de manera ilustrativa.
            Envío de los Productos El tiempo de entrega inicia a partir de la
            confirmación del pago por parte del banco, el cual te será
            notificado por correo electrónico en un periodo máximo de 24 horas.
            Al momento de la entrega en tu domicilio, le será solicitada a la
            persona que reciba la mercancía una copia de la identificación
            oficial (credencial de elector, pasaporte, forma migratoria FM2)
            para comprobar la identidad de la persona que recibe. Leenith Borges
            Arte, a través de Fedex se compromete a entregarte la mercancía en
            el tiempo establecido y en función a la programación disponible y la
            accesibilidad del destino. En circunstancias especiales, Leenith
            Borges Arte realizará la entrega a través de compañías
            transportistas y los tiempos de entrega pueden variar, por lo que
            será recomendable revisar las políticas de envío o contactar al
            personal de venta para solicitar información más concreta. Al
            momento de la recepción del producto, se te entregará, junto con la
            mercancía, una hoja de salida de almacén y una guía. La factura será
            entregada vía correo electrónico a la dirección electrónica que nos
            hayas brindado. Empaque Toda la mercancía será empacada sin cargo
            para el cliente, en caso de realizar pedidos especiales o de un
            volumen mayor se cotiza por separado el envio y empaque de los
            productos. Al igual que en envíos internacionales, se tiene que
            realizar una cotización de envío por separado de acuerdo al país al
            que se envíe. Restricciones Si tienes alguna duda o deseas cancelar
            una compra que aún no hayas recibido, te agradeceremos nos envies un
            correo a contacto@leenitharte.com.mx o DM en instagram.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentsContent;
export { PaymentsContent };
