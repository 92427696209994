import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { TiInputCheckedOutline, TiTrash } from "react-icons/ti";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Link, useParams } from "react-router-dom";
import { Loader, ModalComponent } from "../components";
import {
  getUserOrders,
  getDocFromCollection,
  addDocInCollection,
  updateDocInCollection,
  deleteDocInCollection,
} from "../services";
import { formatNumber, validateMembership } from "../utils";
import { useFetch } from "../hooks";
import { AppContext } from "../store/AppContext";

const MemberInfo = () => {
  const { id } = useParams();
  const functions = getFunctions();
  const updateUserPassword = httpsCallable(functions, "updateUserPassword");
  const [userOrders, setUserOrders] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [isValidMembership, setIsValidMembership] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [userPass, setUserPass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reFetch, setReFetch] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { docs: courses } = useFetch("courses");
  const { docs: challenges } = useFetch("challenges");
  const [products, setProducts] = useState([]);
  const { toastData } = useContext(AppContext);
  const { showToast } = toastData;

  const handleCourseIdField = ({ target }) => {
    const id = target.value;

    setCourseId(id);
    setErrorMessage("");
  };

  const handleUserPassField = ({ target }) => {
    const pass = target.value;
    setUserPass(pass);
    setErrorMessage("");
  };

  const handleCreateOrder = async (e) => {
    setButtonDisabled(true);

    if (currentUser?.courses?.includes(courseId)) {
      setErrorMessage("El usuario ya tiene este curso.");
      setButtonDisabled(false);
      return;
    }

    try {
      const selectedProduct = products.filter(
        (prod) => prod.id === courseId
      )[0];

      let doc;
      if (selectedProduct?.sections?.length) {
        doc = await getDocFromCollection("courses", courseId);
      } else {
        doc = await getDocFromCollection("challenges", courseId);
      }

      if (!doc.price) {
        setErrorMessage("El curso no existe.");
        setButtonDisabled(false);
        return;
      }

      const newOrder = {
        addedAdmin: true,
        type: doc?.sections?.length ? "curso" : doc.isLive ? "live" : "reto",
        product: {
          id: doc.id,
          name: doc.name,
          price: doc.price,
          productId: doc?.productId ?? doc.id,
          cover: doc.cover,
        },
        user: {
          id: currentUser.id,
          email: currentUser.email,
        },
        status: "confirmed",
        createdAt: Date.now(),
      };

      await addDocInCollection("orders", newOrder);

      const document = {
        ...currentUser,
        membership: { ...currentUser.membership },
      };

      document.courses.push(doc.id);

      await updateDocInCollection("users", document.id, document);

      setModalShow(false);
      setCurrentUser(document);
      setCourseId("");
      setErrorMessage("");
      setReFetch(!reFetch);
    } catch (error) {
      setErrorMessage("Error en el servidor.");
      setCourseId("");
      setButtonDisabled(false);
    }
  };

  const handleRemoveCourse = async () => {
    setErrorMessage("");

    if (!courseId) {
      setErrorMessage("Debes seleccionar un curso.");
      return;
    }

    if (!currentUser?.courses?.includes(courseId)) {
      setErrorMessage("El usuario no tiene este curso.");
      return;
    }

    try {
      const userToUpdate = {
        ...currentUser,
        membership: { ...currentUser.membership },
        courses: currentUser.courses.filter((course) => course !== courseId),
      };

      await updateDocInCollection("users", currentUser.id, userToUpdate);

      const orderToUpdate = userOrders.filter(
        (ord) => ord.product.id === courseId
      )[0];
      orderToUpdate.status = "cancelled";

      await updateDocInCollection("orders", orderToUpdate.id, orderToUpdate);

      setCurrentUser(userToUpdate);
      setCourseId("");
      setErrorMessage("");
      setModalShow2(false);
      setReFetch(!reFetch);
    } catch (err) {
      setErrorMessage("Error en el servidor");
      setCourseId("");
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      await deleteDocInCollection("orders", id);
      setReFetch(!reFetch);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmOrder = async (order) => {
    try {
      const updatedOrder = { ...order, status: "confirmed" };
      await updateDocInCollection("orders", updatedOrder.id, updatedOrder);

      const updatedUser = {
        ...currentUser,
        membership: { ...currentUser.membership },
      };
      updatedUser.courses.push(order.product.id);

      await updateDocInCollection("users", updatedUser.id, updatedUser);
      setReFetch(!reFetch);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateUserPassword = async () => {
    const isEmpty = userPass.trim() === "";

    if (!userPass || isEmpty) {
      return setErrorMessage("El campo de la contraseña no debe estar vacio.");
    }

    try {
      const response = await updateUserPassword({
        id: currentUser.userId,
        password: userPass,
      });

      if (!response.data.success) {
        throw new Error("Contraseña insegura.");
      }

      setModalShow3(false);
      setUserPass("");
      setErrorMessage("");
      showToast("success", "Cambio de contraseña correcto.");
    } catch (err) {
      setErrorMessage("Error al intentar cambiar la contraseña.");
      showToast();
    }
  };

  const handleMembership = async (e) => {
    const adminId = JSON.parse(localStorage.getItem("userDetails"))?.id;
    try {
      if (isValidMembership) {
        const updatedUser = {
          ...currentUser,
          membership: { ...currentUser?.membership, isActive: false },
        };

        await updateDocInCollection("users", currentUser.id, updatedUser);
        setIsValidMembership(false);
        setCurrentUser(updatedUser);

        if (adminId === currentUser.id) {
          localStorage.setItem("userDetails", JSON.stringify(updatedUser));
        }

        showToast("success", "Suscripción cancelada.");
      } else {
        const membership = e?.target?.value;

        const updatedUser = {
          ...currentUser,
          membership: {
            id: `membership-${currentUser.id}`,
            isActive: true,
            name: membership,
            price: membership?.price ?? 0,
            durationInMonths: membership === "Suscripción Mensual" ? 1 : 12,
            startDate: new Date(),
            endDate: new Date(
              new Date().setMonth(
                new Date().getMonth() +
                  (membership === "Suscripción Mensual" ? 1 : 12)
              )
            ),
          },
        };

        await updateDocInCollection("users", currentUser.id, updatedUser);

        const newOrder = {
          type: "membresia",
          product: {
            id,
            name: membership,
            price: membership?.price ?? 0,
            productId: id,
          },
          user: {
            id: currentUser.id,
            email: currentUser.email,
          },
          status: "confirmed",
          createdAt: Date.now(),
        };

        await addDocInCollection("orders", newOrder);

        setIsValidMembership(true);
        setCurrentUser(updatedUser);
        if (adminId === currentUser.id) {
          localStorage.setItem("userDetails", JSON.stringify(updatedUser));
        }
        showToast("success", "Suscripción activada.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    setProducts([...courses, ...challenges]);

    const asyncFunc = async () => {
      try {
        const orders = await getUserOrders(id);
        const userDoc = await getDocFromCollection("users", id);
        const isValid = await validateMembership(id);
        setIsValidMembership(isValid);
        setCurrentUser(userDoc);
        setUserOrders(orders);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (id) asyncFunc();
  }, [id, reFetch, courses, challenges]);

  if (!currentUser || !id || loading) return <Loader />;

  return (
    <Container fluid>
      <Row className="my-5">
        <Col lg={{ span: 10, offset: 1 }}>
          <Link to="/admin/miembros">
            <Button className="py-1 px-5 rounded-pill mb-4" variant="secondary">
              Atras
            </Button>
          </Link>
          <div className="bg-purple-m py-3 ps-4 d-flex justify-content-between">
            <p className="mb-0 fw-bold text-white">Miembro</p>
            <div className="w-50 d-flex">
              {isValidMembership ? (
                <Button
                  className="w-100 apy-1 px-3 rounded-pill mx-2 bg-gray text-black border-0"
                  onClick={handleMembership}
                >
                  Cancelar Suscripción
                </Button>
              ) : (
                <Form.Select
                  aria-label="Selecciona la suscripción"
                  className="form-control rounded-pill text-capitalize"
                  id="membership"
                  name="membership"
                  onChange={handleMembership}
                >
                  <option value="">Activar Suscripción</option>
                  <option value="Suscripción Mensual">
                    Suscripción Mensual
                  </option>
                  <option value="Suscripción Anual">Suscripción Anual</option>
                </Form.Select>
              )}

              <Button
                className="w-100 py-1 px-3 rounded-pill mx-2 bg-gray text-black border-0"
                onClick={() => {
                  setErrorMessage("");
                  setUserPass("");
                  setModalShow3(true);
                }}
              >
                Cambiar Contraseña
              </Button>
            </div>
          </div>
          <Table striped className="border border-green mb-5">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Telefono</th>
                <th>Correo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currentUser.id}</td>
                <td className="text-capitalize">{currentUser.name}</td>
                <td>{currentUser.phone}</td>
                <td>{currentUser.email}</td>
              </tr>
            </tbody>
          </Table>
          <div className="bg-purple-m py-3 ps-4 d-flex justify-content-between">
            <p className="mb-0 fw-bold text-white">Cursos</p>
            <div>
              <Button
                className="py-1 px-3 rounded-pill mx-2 bg-gray text-black border-0"
                onClick={() => {
                  setButtonDisabled(false);
                  setModalShow(true);
                }}
              >
                Activar Curso
              </Button>
              <Button
                className="py-1 px-3 rounded-pill mx-2 bg-gray text-black border-0"
                onClick={() => {
                  setButtonDisabled(false);
                  setModalShow2(true);
                }}
              >
                Desactivar Curso
              </Button>
            </div>
          </div>
          <Table striped className="border border-green">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Fecha</th>
                <th>Precio</th>
                <th>Status</th>
                <th>Acciones</th>
                {/* <th>Cobro</th> */}
              </tr>
            </thead>
            <tbody>
              {userOrders.map((order) => (
                <tr key={order.id} className="py-4 align-middle">
                  <td>{order.product.id}</td>
                  <td className="text-capitalize">{order.product.name}</td>
                  {/* <td>{order.product.price}</td> */}
                  <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                  <td>{formatNumber(order.product.price)}</td>
                  <td>
                    {order.status === "cancelled" ? (
                      <span className="text-danger">Cancelado</span>
                    ) : order.status === "pending" ? (
                      <span className="text-warning">Verificando</span>
                    ) : (
                      <span className="text-success">Confirmado</span>
                    )}
                  </td>
                  <td>
                    {order.status === "cancelled" ? (
                      <TiTrash
                        size={32}
                        className="text-danger pointer"
                        onClick={() => handleDeleteOrder(order.id)}
                      />
                    ) : order.status === "pending" ? (
                      <>
                        <TiInputCheckedOutline
                          size={32}
                          className="text-success pointer"
                          onClick={() => confirmOrder(order)}
                        />
                        <TiTrash
                          size={32}
                          className="text-danger pointer"
                          onClick={() => handleDeleteOrder(order.id)}
                        />
                      </>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <ModalComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        title="Activar Curso"
      >
        <Row className="p-3">
          <Col xs={12} lg={6}>
            <div className="mb-3">
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="email"
              >
                Nombre del usuario
              </label>
              <input
                type="text"
                id="name"
                className="form-control rounded-pill"
                name="name"
                defaultValue={currentUser?.name}
                disabled
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-3">
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="name"
              >
                Curso
              </label>
              <Form.Select
                aria-label="Selecciona el curso"
                className="form-control rounded-pill text-capitalize"
                id="course"
                name="course"
                onChange={handleCourseIdField}
              >
                <option value="">Seleccionar curso</option>
                {products.map((course) => (
                  <>
                    {!currentUser?.courses?.includes(course.id) &&
                      !course?.isFromMembership && (
                        <option key={course.id} value={course.id}>
                          {course.name}
                        </option>
                      )}
                  </>
                ))}
              </Form.Select>
            </div>
          </Col>
          {errorMessage && (
            <Col xs={12}>
              <p className="text-danger">{errorMessage}</p>
            </Col>
          )}
          <Col xs={12} className="d-flex justify-content-end">
            <Button
              disabled={buttonDisabled}
              className="py-1 px-5 rounded-pill mt-4 purple-gradient border-0"
              onClick={handleCreateOrder}
            >
              Activar
            </Button>
          </Col>
        </Row>
      </ModalComponent>
      <ModalComponent
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        title="Desactivar Curso"
      >
        <Row className="p-3">
          <Col xs={12} lg={6}>
            <div className="mb-3">
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="email"
              >
                Usuario
              </label>
              <input
                type="text"
                id="name"
                className="form-control rounded-pill text-capitalize"
                name="name"
                defaultValue={currentUser?.name}
                disabled
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-3">
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="name"
              >
                Curso
              </label>
              <Form.Select
                aria-label="Selecciona el curso"
                className="form-control rounded-pill text-capitalize"
                id="course"
                name="course"
                onChange={handleCourseIdField}
              >
                <option value="">Seleccionar curso</option>
                {products.map((course) => (
                  <>
                    {currentUser?.courses?.includes(course.id) &&
                      !course?.isFromMembership && (
                        <option key={course.id} value={course.id}>
                          {course.name}
                        </option>
                      )}
                  </>
                ))}
              </Form.Select>
            </div>
          </Col>
          {errorMessage && (
            <Col xs={12}>
              <p className="text-danger">{errorMessage}</p>
            </Col>
          )}
          <Col xs={12} className="d-flex justify-content-end">
            <Button
              className="py-1 px-5 rounded-pill mt-4 purple-gradient border-0"
              onClick={handleRemoveCourse}
            >
              Desactivar
            </Button>
          </Col>
        </Row>
      </ModalComponent>
      <ModalComponent
        show={modalShow3}
        onHide={() => setModalShow3(false)}
        title="Cambiar Contraseña"
      >
        <Row className="p-3">
          <Col xs={12} lg={6}>
            <div className="mb-3">
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="email"
              >
                Miembro
              </label>
              <input
                type="text"
                id="name"
                className="form-control rounded-pill text-capitalize"
                name="name"
                defaultValue={currentUser?.name}
                disabled
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-3">
              <label
                className="form-label text-purple-l fw-bold"
                htmlFor="pass"
              >
                Contraseña nueva
              </label>
              <input
                className="form-control rounded-pill"
                id="pass"
                name="pass"
                onChange={handleUserPassField}
              />
            </div>
          </Col>
          {errorMessage && (
            <Col xs={12}>
              <p className="text-danger">{errorMessage}</p>
            </Col>
          )}
          <Col xs={12} className="d-flex justify-content-end">
            <Button
              className="py-1 px-5 rounded-pill mt-4 purple-gradient border-0"
              onClick={handleUpdateUserPassword}
            >
              Cambiar
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </Container>
  );
};
export default MemberInfo;
export { MemberInfo };
