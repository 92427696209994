import { getAuth, signOut } from "firebase/auth";
import { Col } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const logOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");
        localStorage.clear();
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <Col
      md={2}
      className="bg-purple-l text-white min-vh-100 px-0"
      id="admin-navbar"
    >
      <div className="text-center border-bottom border-success max-h-90 pr-3">
        <img
          src="/logo-admin.png"
          className="img-fluid max-h-90"
          width={220}
          height={50}
          alt=""
        />
      </div>
      <NavLink
        to="/admin"
        end
        className={({ isActive }) => (isActive ? "actived" : "")}
      >
        Inicio
      </NavLink>
      <NavLink
        to="/admin/cursos"
        className={({ isActive }) => (isActive ? "actived" : "")}
      >
        Cursos / Suscripciones
      </NavLink>
      <NavLink
        to="/admin/retos"
        className={({ isActive }) => (isActive ? "actived" : "")}
      >
        Retos / Live
      </NavLink>
      <NavLink
        to="/admin/miembros"
        className={({ isActive }) => (isActive ? "actived" : "")}
      >
        Miembros
      </NavLink>
      <NavLink
        to="/admin/ventas"
        className={({ isActive }) => (isActive ? "actived" : "")}
      >
        Ventas
      </NavLink>
      {/* <NavLink
        to="/admin/live"
        className={({ isActive }) => (isActive ? "actived" : "")}
      >
        Live
      </NavLink> */}

      <p id="log-out-btn" className="px-5" onClick={logOut}>
        Cerrar sesión
      </p>
    </Col>
  );
};

export default Navbar;
export { Navbar };
