import { Col, Container, Row } from "react-bootstrap";

const ReturnsContent = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 11, offset: 1 }} className="text-content">
          <h3 className="mb-1 fw-bold">Cambio y Devoluciones</h3>
          <h5>Leenit Borges Arte</h5>
          <p>Cancelaciones</p>
          <p>
            ¿Cuál es el procedimiento para una cancelación? Debes recordar que
            la cancelación de la orden sólo aplica antes de que Leenith Borges
            Arte te confirme el envío de la orden, una vez que Denique Lingerie
            confirme el envío de la orden no se podrá hacer cancelación de la
            misma.
          </p>
          <p>
            Contáctanos en contacto@leenitharte.com.mx para cualquier duda o
            aclaración.
          </p>
          <p>
            ¿Cuanto tarda en hacerse efectivo mi reembolso? Una vez cancelada la
            orden iniciaremos el proceso de reembolso. Deberás enviarnos los
            datos bancarios a donde debemos hacer el reembolso incluyendo nombre
            del banco, nombre del titular, número de cuenta y CLABE de la cuenta
            (18 dígitos). El reembolso se hará específicamente por la misma vía
            o método de pago que usaste en la compra. El tiempo que tarda en
            hacerse efectivo el reembolso de tu dinero depende del método de
            pago y se regirá de la siguiente forma: Otras formas de pago (SEVEN,
            SPEI, depósito bancario).
          </p>
          <p>
            Si la dirección de envío de tu mercancía y la dirección de tu
            registro de cliente en Leenith Borges Arte coinciden, la factura te
            será enviada posteriormente por mensajería. En caso contrario,
            deberás indicar allí mismo la dirección de envío de tus documentos.
            Si extravías tu factura, por disposiciones fiscales no podremos
            hacerte una nueva, sino solamente darte una carta de propiedad sin
            valor fiscal.
          </p>
          <p>Cambio físico de la mercancía</p>
          <p>
            Nuestras piezas son revisadas y seleccionadas cuidadosamente para
            que las recibas en perfectas condiciones. No se hacen cambios, ni
            devoluciones. Si el producto que te enviamos es erroneo o viene
            defectuoso, te pedimos una disculpa y nosotros te haremos llegar un
            nuevo producto sin cargo alguno. Deberás notificarlo el mismo día en
            que lo recibas.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ReturnsContent;
export { ReturnsContent };
