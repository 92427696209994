import { useState } from "react";

const useToastConfig = () => {
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState("danger");
  const [message, setMessage] = useState(
    "Oh no algo salió mal, intentalo de nuevo!"
  );

  const showToast = (bg, msg) => {
    setMode(bg);
    setShow(true);
    setMessage(msg);
  };

  return {
    show,
    setShow,
    mode,
    setMode,
    showToast,
    message,
    setMessage,
  };
};

export { useToastConfig };
