import { useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { TiDelete, TiMinus, TiPencil, TiPlus } from "react-icons/ti";

const Sections = ({
  showSectionForm,
  setShowSectionForm,
  newSection,
  setNewSection,
  showChapterForm,
  setShowChapterForm,
  newChapter,
  setNewChapter,
  addChapter,
  editChapter,
  updateChapter,
  removeChapter,
  addSection,
  editSection,
  updateSection,
  removeSection,
  sections,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isChapterEdit, setIsChapterEdit] = useState(false);

  return (
    <Row>
      <Col md={6} className="mt-4 border-end">
        <div className="min-vh-50 mb-5">
          <div className="d-flex justify-content-between align-items-center pe-5 text-purple-m">
            <p className="fw-bold mb-0 fs-5">Secciones</p>
            {showSectionForm ? (
              <TiMinus
                className="fs-4 pointer"
                onClick={() => setShowSectionForm(false)}
              />
            ) : (
              <TiPlus
                className="fs-4 pointer"
                onClick={() => setShowSectionForm(true)}
              />
            )}
          </div>
          {showSectionForm && (
            <div className="mt-4 w-75">
              <label className="form-label fw-bold mt-3" htmlFor="section-name">
                Nombre de la sección
              </label>
              <input
                type="text"
                id="section-name"
                className="form-control rounded-pill"
                name="section-name"
                value={newSection?.name}
                onChange={({ target }) =>
                  setNewSection({ ...newSection, name: target.value })
                }
                placeholder="Escribe el nombre de la sección"
              />
              <div className="d-flex justify-content-between align-items-center text-purple-m mt-4">
                <p className="fw-bold mb-0 fs-5">Capítulos</p>
                {showChapterForm ? (
                  <TiMinus
                    className="fs-4 pointer"
                    onClick={() => setShowChapterForm(false)}
                  />
                ) : (
                  <TiPlus
                    className="fs-4 pointer"
                    onClick={() => setShowChapterForm(true)}
                  />
                )}
              </div>
              <div className="mt-4">
                {newSection.chapters.map((chapter, i) => (
                  <div
                    key={chapter.videoId}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p className="mb-0">- {chapter.name}</p>
                    <span>
                      <TiPencil
                        className="pointer"
                        onClick={() => {
                          editChapter(i);
                          setIsChapterEdit(true);
                          setShowChapterForm(true);
                        }}
                      />
                      <TiDelete
                        size={20}
                        className="text-red ms-2 pointer"
                        onClick={() => removeChapter(i)}
                      />
                    </span>
                  </div>
                ))}
              </div>
              {showChapterForm && (
                <div className="ps-4 mt-4">
                  <div className="mt-4">
                    <label
                      className="form-label fw-bold"
                      htmlFor="chapter-name"
                    >
                      Nombre del capítulo
                    </label>
                    <input
                      type="text"
                      id="chapter-name"
                      className="form-control rounded-pill"
                      name="chapter-name"
                      value={newChapter?.name}
                      onChange={({ target }) =>
                        setNewChapter({
                          ...newChapter,
                          name: target.value,
                        })
                      }
                      placeholder="Escribe el nombre del capítulo"
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      className="form-label fw-bold"
                      htmlFor="chapter-duration"
                    >
                      Duración del capítulo
                    </label>
                    <input
                      type="number"
                      id="chapter-duration"
                      className="form-control rounded-pill"
                      name="chapter-duration"
                      value={newChapter?.duration}
                      onChange={({ target }) =>
                        setNewChapter({
                          ...newChapter,
                          duration: parseFloat(target.value),
                        })
                      }
                      placeholder="Escribe la duración del capítulo"
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      className="form-label fw-bold"
                      htmlFor="chapter-video-id"
                    >
                      Id del video
                    </label>
                    <input
                      type="text"
                      id="chapter-video-id"
                      className="form-control rounded-pill"
                      name="chapter-video-id"
                      value={newChapter?.videoId}
                      onChange={({ target }) =>
                        setNewChapter({
                          ...newChapter,
                          videoId: target.value,
                        })
                      }
                      placeholder="Escribe el id del video"
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      className="form-label fw-bold"
                      htmlFor="chapter-video-app-id"
                    >
                      Id del video para App Móvil
                    </label>
                    <input
                      type="text"
                      id="chapter-video-app-id"
                      className="form-control rounded-pill"
                      name="chapter-video-app-id"
                      value={newChapter?.videoAppId}
                      onChange={({ target }) =>
                        setNewChapter({
                          ...newChapter,
                          videoAppId: target.value,
                        })
                      }
                      placeholder="Escribe el id del video par la App Móvil"
                    />
                  </div>
                  {newChapter.name &&
                    newChapter.duration &&
                    newChapter.videoId &&
                    newChapter.videoAppId && (
                      <Button
                        className="bg-purple-m rounded-pill fw-bold px-3 border-none mt-4 float-end"
                        onClick={() => {
                          isChapterEdit ? updateChapter() : addChapter();
                          setIsChapterEdit(false);
                        }}
                      >
                        {isChapterEdit ? "Actualizar" : "Agregar"} Capítulo
                      </Button>
                    )}
                </div>
              )}
            </div>
          )}
        </div>
        {newSection.name && newSection.chapters.length > 0 && (
          <Button
            className="bg-purple-m rounded-pill fw-bold px-3 border-none mt-5"
            onClick={() => {
              isEdit ? updateSection() : addSection();
              setIsEdit(false);
            }}
          >
            {isEdit ? "Actualizar" : "Agregar"} Sección
          </Button>
        )}
      </Col>
      <Col md={6} className="mt-4">
        <Accordion>
          {sections.map((section, i) => (
            <Accordion.Item key={section.name} eventKey={section.name}>
              <Accordion.Header>
                <div>
                  <p className="mb-0 fw-bold text-capitalize">
                    {section.name}
                    <span className="ms-5">
                      <TiPencil
                        className="pointer"
                        onClick={() => {
                          editSection(i);
                          setIsEdit(true);
                        }}
                      />
                      <TiDelete
                        size={20}
                        className="text-red ms-2 pointer"
                        onClick={() => removeSection(i)}
                      />
                    </span>
                  </p>
                  <p className="mb-0 mt-2">
                    Capítulos: {section.chapters.length}
                  </p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {section.chapters.map((chapter) => (
                  <div
                    key={chapter.name}
                    className="d-flex align-items-center justify-content-between my-2 px-5 py-4 bg-gray rounded-3"
                  >
                    <div className="fw-bold text-capitalize">
                      {chapter.name}
                    </div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>
    </Row>
  );
};

export default Sections;
export { Sections };
