import { Outlet } from "react-router-dom";
import { CustomToast, Footer, Header } from "../components";
import { useToastConfig, useScrollTop } from "../hooks";
import { AppContext } from "../store/AppContext";
import { CartProvider } from "../store/CartContext";

const App = () => {
  const config = {
    toastData: useToastConfig(),
  };
  useScrollTop();

  return (
    <AppContext.Provider value={config}>
      <CartProvider>
        <main className="container-fluid px-0">
          <Header />
          <Outlet />
          <CustomToast />
          <Footer />
        </main>
      </CartProvider>
    </AppContext.Provider>
  );
};

export default App;
export { App };
