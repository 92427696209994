import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Loader } from "../components";
import { useFetch } from "../hooks";
import { updateDocInCollection } from "../services";
import { useState } from "react";

const CoursesList = () => {
  const { docs: courses, isLoading } = useFetch("courses");
  const { docs: users } = useFetch("users");
  const [loading, setLoading] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [selectdCourse, setSelectedCourse] = useState("");
  const [usersCourse, setUsersCourse] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const updateStatus = async (course) => {
    try {
      setLoading(true);

      const updatedCourse = {
        ...course,
        isActive: !course.isActive,
      };

      await updateDocInCollection("courses", course.id, updatedCourse);
      course.isActive = !course.isActive;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleMemberTable = ({ id, name }) => {
    getUsersWithCourse(id);
    setSelectedCourse(name);
    setShowMembers(true);
  };

  const getUsersWithCourse = (id) => {
    const filteredUsers = users.filter((user) => user.courses.includes(id));
    setUsersCourse(filteredUsers);
    setUsersToDisplay(filteredUsers);
  };

  const handleSearch = ({ target }) => {
    const { value } = target;

    const filteredUsers = usersCourse.filter(
      (user) =>
        user.name.toLowerCase().includes(value.toLowerCase()) ||
        user.email.toLowerCase().includes(value.toLowerCase())
    );

    setUsersToDisplay(filteredUsers);
  };

  if (isLoading) return <Loader />;

  return (
    <Container fluid>
      <Row className="my-4">
        <Col md={showMembers ? 8 : { span: 10, offset: 1 }}>
          <div className="d-flex justify-content-end mb-4">
            <Link to="curso">
              <Button className="bg-purple-m border-none rounded-pill px-3 fw-bold">
                Agregar Curso
              </Button>
            </Link>
          </div>
          <p className="bg-purple-m text-white py-3 ps-4 mb-0 fw-bold">
            Lista de Cursos
          </p>
          <Table striped className="border border-green">
            <thead>
              <tr>
                <th>#No</th>
                <th>Nombre</th>
                <th>Status</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                courses
                  .sort((a, b) => Number(a?.position) - Number(b?.position))
                  .map((course) => (
                    <tr key={course.id} className="py-4 align-middle">
                      <td># {course?.position}</td>
                      <td className="text-capitalize">{course.name}</td>
                      <td>
                        <Button
                          className="py-1 px-3 rounded-pill"
                          variant={`${course.isActive ? "success" : "danger"}`}
                          onClick={() => updateStatus(course)}
                        >
                          {course.isActive ? "Activo" : "Inactivo"}
                        </Button>
                      </td>
                      <td>
                        <Link to={`curso?id=${course.id}`}>
                          <Button
                            className="py-1 px-5 rounded-pill"
                            variant="secondary"
                          >
                            Editar
                          </Button>
                        </Link>
                        <Button
                          className="py-1 px-3 ms-2 rounded-pill"
                          variant="secondary"
                          onClick={() => handleMemberTable(course)}
                        >
                          Ver Miembros
                        </Button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </Col>
        {showMembers && (
          <Col md={4}>
            <div className="d-flex justify-content-end mb-4">
              <div className="d-flex align-items-end justify-content-end">
                <label className="form-label fw-bold me-3" htmlFor="search">
                  Buscar:
                </label>
                <input
                  type="text"
                  id="search"
                  className="form-control rounded-pill"
                  name="search"
                  onChange={handleSearch}
                  placeholder="Buscar por nombre o correo"
                />
              </div>
              <Button
                className="bg-purple-m border-none rounded-pill px-3 fw-bold mx-2"
                onClick={() => setShowMembers(false)}
              >
                X
              </Button>
            </div>
            <p className="bg-purple-m text-white py-3 ps-4 mb-0 fw-bold text-capitalize">
              Miembros de {selectdCourse}
            </p>
            <Table striped className="border border-green">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Correo</th>
                </tr>
              </thead>
              <tbody>
                {usersToDisplay.map((user) => (
                  <tr key={user.id} className="py-4 align-middle">
                    <td className="text-capitalize">{user.name}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default CoursesList;
export { CoursesList };
