import ReactPlayer from "react-player";

const Player = ({ videoId }) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        controls
        width="100%"
        height="100%"
        url={`https://player.vimeo.com/video/${videoId}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
      />
    </div>
  );
};

export default Player;
export { Player };
