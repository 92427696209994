import { createContext, useState } from "react";

export const CartContext = createContext({
  items: [],
  setItems: () => {},
});

export const CartProvider = ({ children }) => {
  const [items, setItems] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );

  window.addEventListener("storage", (e) => {
    if (localStorage.getItem("cart") === JSON.stringify(items)) {
      return;
    }
    setItems(JSON.parse(localStorage.getItem("cart")) || []);
  });

  return (
    <CartContext.Provider
      value={{
        items,
        setItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
