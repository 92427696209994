import { Col, Container, Row } from "react-bootstrap";

const BillingsContent = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 11, offset: 1 }} className="text-content">
          <h3 className="mb-1 fw-bold">Facturación</h3>
          <h5>Leenit Borges Arte</h5>
          <p>
            Si deseas facturar, envía un correo a facturacion@leenitharte.com.mx
            con tus datos fiscales y con tu # de orden* y listo, te enviaremos
            tu factura tan pronto nuestro sistema la procese.
          </p>
          <p>
            (Tu número de orden es el número de pedido que llega a tu correo al
            hacer la compra)
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default BillingsContent;
export { BillingsContent };
