import { TbShoppingCartX } from "react-icons/tb";
import { useCart } from "../../hooks";
import { Link } from "react-router-dom";

export const AddToCartButton = ({ item }) => {
  const { addItem, removeItem, checkItemInCheckout } = useCart();

  return (
    <div className="w-100 d-flex justify-content-between flex-sm-wrap">
      {!checkItemInCheckout(item.id) ? (
        <button
          className="btn rounded-pill purple-gradient border-0 text-white fw-bold px-5 pointer text-decoration-none py-2"
          onClick={() => {
            addItem(item);
          }}
        >
          Añadir al Carrito
        </button>
      ) : (
        <>
          <Link to="/carrito">
            <button className="btn rounded-pill purple-gradient border-0 text-white fw-bold px-5 pointer text-decoration-none py-2">
              Comprar
            </button>
          </Link>
          <TbShoppingCartX
            size={32}
            className="text-purple-l pointer flex-shrink-0 mt-1 ms-3"
            onClick={() => removeItem(item.id)}
          />
        </>
      )}
    </div>
  );
};
