import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";

// Get collection data
const getCollectionFromDB = async (schema) => {
  try {
    // Get collection reference
    const reference = collection(db, schema);

    // Get documents
    const { docs } = await getDocs(reference);

    return docs.map((document) => {
      return { ...document.data(), id: document.id };
    });
  } catch (err) {
    throw new Error(err);
  }
};

// Get a document by id
const getDocFromCollection = async (schema, id) => {
  try {
    // Get document reference
    const reference = doc(db, schema, id);

    // Get document
    const document = await getDoc(reference);
    return { ...document.data(), id: document.id };
  } catch (err) {
    throw new Error(err);
  }
};

// Get a document by name
const getDocFromCollectionByName = async (schema, name) => {
  try {
    // Get documents reference
    const docs = await getCollectionFromDB(schema);

    // Get document
    const document = docs.filter((d) => d.customUrl === name)[0];
    return document;
  } catch (err) {
    throw new Error(err);
  }
};

// Add a new document
const addDocInCollection = async (schema, document) => {
  try {
    // Get collection reference
    const reference = collection(db, schema);

    // Add document
    const response = await addDoc(reference, document);
    return response.id;
  } catch (err) {
    throw new Error(err);
  }
};

// Update a document
const updateDocInCollection = async (schema, id, document) => {
  try {
    // Get document reference
    const reference = doc(db, schema, id);

    // Update document
    const response = await updateDoc(reference, document);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

// Delete a document
const deleteDocInCollection = async (schema, id) => {
  try {
    // Get document reference
    const reference = doc(db, schema, id);

    // Delete document
    const response = await deleteDoc(reference);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

// Get user details
const getUserDetails = async (id) => {
  try {
    // Get users collection reference
    const users = collection(db, "users");

    const q = query(users, where("userId", "==", id));

    const querySnapshot = await getDocs(q);

    let userDetails = {};

    querySnapshot.forEach((doc) => {
      userDetails = { ...doc.data(), id: doc.id };
    });

    return userDetails;
  } catch (err) {
    throw new Error(err);
  }
};

// Get user courses
const getUserCourses = async (id) => {
  try {
    // Get users collection reference
    const users = collection(db, "users");

    const q = query(users, where("id", "==", id));

    const querySnapshot = await getDocs(q);

    const userCourses = [];

    querySnapshot.forEach((doc) => {
      userCourses.push(...doc.data()?.courses);
    });

    return userCourses;
  } catch (err) {
    throw new Error(err);
  }
};

// Get user orders
const getUserOrders = async (id) => {
  try {
    // Get orders collection reference
    const orders = collection(db, "orders");

    const q = query(orders, where("user.id", "==", id));

    const querySnapshot = await getDocs(q);

    const userOrders = [];

    querySnapshot.forEach((doc) => {
      userOrders.push({ ...doc.data(), id: doc.id });
    });

    return userOrders;
  } catch (err) {
    throw new Error(err);
  }
};

export {
  getCollectionFromDB,
  getDocFromCollection,
  getDocFromCollectionByName,
  addDocInCollection,
  updateDocInCollection,
  deleteDocInCollection,
  getUserDetails,
  getUserCourses,
  getUserOrders,
};
